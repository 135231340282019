import { Box, Flex, Divider, Grid } from '@chakra-ui/react'
import { useState } from 'react'

import TabbedDisplay from 'modules/TabbedDisplay'

import { TableauEmbed } from 'components'
import { registerTableauPage } from 'components/Embed/tableauSearchIndex'

import { BioriskPages } from 'config/apps/Biorisk/Custom/Biorisk/BioriskPages'
import { getMediaDefaultWidget } from 'config/common/baseModel/Media'
import { getPresentationDefaultWidget } from 'config/common/baseModel/Presentations'
import { getPressReleasesDefaultWidget } from 'config/common/baseModel/PressReleases'

import AlertsSection from './AlertSection'
import ReportSection from './ReportSection'

registerTableauPage({
  app: 'biorisk',
  path: '',
  tableauPath: '/Biorisk_Platform_NEW/Frontpage',
})
const TableauSection = () => {
  return (
    <Box>
      <TableauEmbed path={`/Biorisk_Platform_NEW/Frontpage`} height={'120vh'} />
    </Box>
  )
}

const PandemicDashboard = () => {
  const [showMore, setShowMore] = useState(false)
  const [rowHeight, setRowHeight] = useState('500px')

  return (
    <Flex flexDir='column' p='1rem' mt='0rem'>
      <Grid gridTemplateColumns={'3fr 2fr'} gridTemplateRows={rowHeight}>
        <Box w='100%' display={'flex'}>
          <AlertsSection />
        </Box>
        <ReportSection
          showMore={showMore}
          handleShowMore={() => setShowMore(!showMore)}
          heightCB={(h: string) => setRowHeight(h)}
        />
      </Grid>

      <Divider my={4} />

      <TabbedDisplay
        variant='line'
        tabListWrapper={null}
        tabs={[
          {
            heading: 'Outbreak Tracker',
            body: <TableauSection />,
          },
          {
            heading: 'Media',
            body: getMediaDefaultWidget({
              title: 'Media',
              view: BioriskPages['VariantsMedia'].views[0],
            }).body,
          },
          {
            heading: 'Press Releases',
            body: getPressReleasesDefaultWidget({
              view: BioriskPages['VariantsPressReleases'].views[0],
            }).body,
          },
          {
            heading: 'Scientific Papers',
            body: getPresentationDefaultWidget({
              title: 'Scientific Papers',
              view: BioriskPages['VariantsScientificPapers'].views[0],
              model: BioriskPages['VariantsScientificPapers'].model,
            }).body,
          },
        ]}
      />
    </Flex>
  )
}

export default PandemicDashboard
