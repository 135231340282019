import { Box, useTheme } from '@chakra-ui/react'
import { memo, useEffect, useMemo, useState } from 'react'
import {
  HeaderGroup,
  TableInstance,
  TableState,
  useColumnOrder,
  useExpanded,
  useFlexLayout,
  useGlobalFilter,
  useGroupBy,
  usePagination,
  useResizeColumns,
  useSortBy,
  useTable,
} from 'react-table'
import { useSticky } from 'react-table-sticky'
import styled from 'styled-components'
import useDeepCompareEffect from 'use-deep-compare-effect'

import { defaultState, getTableStateFromModel } from 'config/common/model/state'

import { parentColumnNames } from 'utils/grouping'
import { relationGetDisplayValue } from 'utils/relational'

import { useAdvancedFilters } from './AdvancedSearch/useAdvancedFilters'
import Pagination from './Pagination'
import TableAccessory from './TableAccessory'
import TableBody from './TableBody'
import TableHeader from './TableHeader'
import TableLayoutBox from './provider/TableLayoutBox'
import { TableParentPropsContext } from './provider/TableParentProps'
import { TableUseTablePropsContext } from './provider/TableUseTableProps'
import { TableProps } from './types'
import { useParentChildRows } from './useParentChildRows'
import { useTableState } from './useTableState'

const Styles = styled.div`
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .cell:first-child,
  .columnCell:first-child {
    position: sticky !important;
    top: auto;
    left: 0;
    z-index: 10;
    box-shadow: 1px 0px 0 0 rgba(00, 00, 00, 0.03);
  }

  .clickable:hover {
    background: #f9f8f8;
    cursor: pointer;
  }
  .clickable:hover > div {
    background: #f9f8f8;
    cursor: pointer;
  }

  .cell {
    position: relative;
    border-right: solid 1px #edeef0;
    background-color: white;
  }

  .resizer {
    display: inline-block;
    width: 10px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(50%);
    z-index: 1;
    ${'' /* prevents from scrolling while dragging on touch devices */}
    touch-action:none;
  }
`

const defaultTextFilterTypes = (
  rows: any[],
  ids: string[],
  filterValue: any
) => {
  return rows.filter((row) => {
    return ids.some((id) => {
      // Handle relation value
      const rowValue = relationGetDisplayValue(row.values[id])
      return String(rowValue)
        .toLowerCase()
        .includes(String(filterValue).toLowerCase())
    })
  })
}

const defaultParentRowFn = (row: any) => {
  return parentColumnNames.every(
    (columnName) => !row?.values[columnName]?.toLowerCase().includes('parent')
  )
}

export const defaultColumn = {
  minWidth: 30,
  width: 150,
  maxWidth: 1300,
}

export function Table<D extends Object>(props: TableProps<D>) {
  const theme = useTheme()

  const {
    data,
    columns,
    onRowClick,
    isLoading,
    model,
    extraUseTableOptions,
    onStateChange,
    state: controlledState,
    total,
    callback,
    goToPageCallback,
  } = props

  const extendedUseTable = useTable as (...args: any[]) => TableInstance<D>

  // We need this to get column resize working
  const [staticColumnsData, setStaticColumnsData] = useState(columns)

  // When the column data change, update static column data
  // This is used on view change
  useEffect(() => {
    if (JSON.stringify(staticColumnsData) !== JSON.stringify(columns)) {
      setStaticColumnsData(columns)
    }
  }, [staticColumnsData, columns])

  const calculatedState: TableState<any> = useMemo(
    () => ({
      ...defaultState,
      ...getTableStateFromModel(model),
      ...controlledState,
    }),
    [model, controlledState]
  )
  const useTableData = extendedUseTable(
    {
      columns: staticColumnsData,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      initialState: calculatedState,
      groupByFn: model.grouping?.groupByFn,
      paginateExpandedRows: false,
      globalFilter: defaultTextFilterTypes,
      parentRowFn: defaultParentRowFn,
      pageCount: Math.ceil((total ?? 1) / (calculatedState?.pageSize ?? 1)),
      ...extraUseTableOptions,
    },
    useAdvancedFilters,
    useGlobalFilter,
    useColumnOrder,
    useGroupBy,
    useSortBy,
    useExpanded,
    useParentChildRows,
    usePagination,
    useFlexLayout,
    useResizeColumns,
    useSticky,
    useTableState
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    rows,
    visibleColumns,
    previousPage,
    nextPage,
    prepareRow,
    canPreviousPage,
    pageCount,
    preGlobalFilteredRows,
    canNextPage,
    state,
    setPageSize,
    setState: setReactTableState,
    setAdvancedFilters,
    gotoPage,
  } = useTableData

  const { pageIndex, pageSize, groupBy } = state

  callback && callback(setAdvancedFilters)

  goToPageCallback && goToPageCallback(gotoPage)

  useDeepCompareEffect(() => {
    // We need to set the react-table internal state since this component isn't recreated on state change
    setReactTableState(calculatedState)
  }, [calculatedState])

  useDeepCompareEffect(() => {
    if (!state.columnResizing.isResizingColumn) {
      // if (!_.isEqual(state, calculatedState)) {
      onStateChange && onStateChange(state)
      // }
    }
  }, [state])

  // useDeepCompareEffect(() => {
  //   if (!_.isEqual(state, calculatedState)) {
  //     console.log('Diff state', state, calculatedState)
  //     // onStateChange && onStateChange(calculatedState)
  //     // calculatedState && setReactTableState(calculatedState)
  //   }
  // }, [calculatedState])

  // We make a shallow copy of each object here since this value is mutable from react-table.
  // If we use it directly, react memo will always think it's the same value.
  let headerGroupsCopy: HeaderGroup<D>[] = headerGroups.map((x) => ({
    ...x,
  }))

  return (
    <TableParentPropsContext.Provider value={{ parentProps: props }}>
      <TableUseTablePropsContext.Provider value={{ useTableData }}>
        <TableAccessory useTableData={useTableData} calculatedState={state} />
        <Box
          border='1px solid'
          borderColor='gray3'
          bg='gray5'
          display='flex'
          flex={1}
          overflow='hidden'
          flexDirection='column'
          borderRadius='8px'
        >
          <Styles>
            <TableLayoutBox
              width='100%'
              height='100%'
              overflowX={groupBy.length > 0 ? 'hidden' : 'scroll'}
              overflowY='scroll'
            >
              <Box
                {...getTableProps()}
                minWidth='100% !important'
                display='table'
                fontWeight={500}
              >
                <TableHeader
                  headerGroups={headerGroupsCopy}
                  isLoading={false}
                />
                <TableBody
                  page={page}
                  isLoading={isLoading}
                  visibleColumns={visibleColumns}
                  getTableBodyProps={getTableBodyProps}
                  onRowClick={onRowClick}
                  prepareRow={prepareRow}
                />
                {isLoading === false && preGlobalFilteredRows.length === 0 && (
                  <Box>
                    <td
                      colSpan={columns.length}
                      style={{ padding: theme.sizes[3] }}
                    >
                      <Box fontSize='sm' py={3} color='gray.700'>
                        No results found. Please try changing the filters.
                      </Box>
                    </td>
                  </Box>
                )}
              </Box>
            </TableLayoutBox>
            <Pagination<D>
              showAll={groupBy.length > 0}
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              page={page}
              isLoading={isLoading}
              previousPage={previousPage}
              nextPage={nextPage}
              pageIndex={pageIndex}
              pageCount={pageCount}
              pageSize={pageSize}
              setPageSize={setPageSize}
              total={total ?? rows.length}
            />
          </Styles>
        </Box>
      </TableUseTablePropsContext.Provider>
    </TableParentPropsContext.Provider>
  )
}

export default memo(Table)
