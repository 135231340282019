import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import useAxios from 'api/useAxios'

import { appsListUnion } from 'config/apps'

export type SummaryDataType = { [key: string]: number }

export default function useTreatmentSummaryData(app: appsListUnion) {
  const axios = useAxios()
  return useQuery<SummaryDataType[], AxiosError>(
    ['useTreatmentSummaryData', app],
    async () => {
      const appRequests = [
        axios(
          `/idtreatments360/summary/treatment_candidates_added_in_past_week/`
        ),
        axios(`/idtreatments360/summary/treatment_trials_added_in_past_week/`),
        axios(
          `/idtreatments360/summary/treatment_presentations_added_in_past_week/`
        ),
      ]
      const response = await Promise.all(appRequests)
      return response.map((value) => value.data)
    }
  )
}
