import { INavigationMenu } from 'interfaces/navigationMenu.interface'

export type EpidemiologySlugs =
  | ''
  | 'by-age-group'
  | 'overview-risk'
  | 'protection-vaccinations'
  | 'variants'
  | 'latest-news'
  | 'impact'
  | 'reports'
  | 'protected-population'
  | 'initial-and-boosters'
  | 'long-covid'
  | 'publications'

const EpidemiologyMenu: Array<INavigationMenu<EpidemiologySlugs>[]> = [
  [
    {
      title: 'Overview',
      slug: '',
      description: '',
      createdAt: new Date(2022, 0, 25),
    },
    { title: 'Reports', slug: 'reports', createdAt: new Date(2022, 12, 6) },
    {
      title: 'Long COVID',
      slug: 'long-covid',
      createdAt: new Date(2023, 5, 22),
    },
    {
      title: 'Protection & Vaccinations',
      slug: 'protection-vaccinations',
      createdAt: new Date(2022, 1, 7),
      children: [
        {
          title: 'Protected Population',
          slug: 'protected-population',
          createdAt: new Date(2021, 11, 14),
        },
        {
          title: 'Initial & Boosters',
          slug: 'initial-and-boosters',
          createdAt: new Date(2021, 11, 1),
          newUntil: new Date(2021, 11, 14),
        },
      ],
    },
    {
      title: 'Variants',
      description: '',
      slug: 'variants',
      createdAt: new Date(2022, 1, 7),
      children: [
        {
          title: 'Latest News',
          slug: 'latest-news',
          createdAt: new Date(2022, 1, 7),
        },
        {
          title: 'Impact of Variants',
          slug: 'impact',
          createdAt: new Date(2022, 1, 7),
        },
      ],
    },
    {
      title: 'Track By Age Group',
      slug: 'by-age-group',
      createdAt: new Date(2022, 2, 24),
    },
  ],
]

export { EpidemiologyMenu }
