import GenericTable from 'modules/Tables'

import { IModel } from 'interfaces/model.interface'
import {
  INavigationPages,
  ISingleNavigationPage,
} from 'interfaces/navigationPage.interface'

import { SortOrders } from 'enums/SortOrders'

import {
  InfluenzaDealsPriceModel,
  InfluenzaDealsSupplyModel,
  InfluenzaImmunisationScheduleModel,
  InfluenzaTendersModel,
} from 'config/apps/Influenza/Commercial/VaccinesTherapeutics/InfluenzaCommericalModels'

import { InfluenzaCommercialSlugs } from './InfluenzaCommercialMenu'
import warningMessages from './InfluenzaWarningMessages'

type InfluenzaNavigationPage<TModel extends IModel<any>> =
  ISingleNavigationPage<InfluenzaCommercialSlugs, TModel>

const InfluenzaCountriesImmunisationSchedule: InfluenzaNavigationPage<
  typeof InfluenzaImmunisationScheduleModel
> = {
  key: 'InfluenzaCountriesImmunisationSchedule',
  path: ['immunisation-schedules'],
  model: InfluenzaImmunisationScheduleModel,
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableName: 'front_end_export',
      airtableBase: 'respiratory360',
    },
  ],
}

const InfluenzaDealsSupply: InfluenzaNavigationPage<
  typeof InfluenzaDealsSupplyModel
> = {
  key: 'InfluenzaDealsSupply',
  path: ['supply-deals', 'table'],
  model: {
    ...InfluenzaDealsSupplyModel,
    name: 'Deals',
    warningMessage: warningMessages.supplyDeals,
    defaultSortObject: [
      {
        id: 'dateStart',
        sortOrder: SortOrders.DESC,
      },
      {
        id: 'recipients',
        sortOrder: SortOrders.ASC,
      },
    ],
  },
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableName: 'flu_commercial_supply_deals',
      airtableBase: 'lzdb',
    },
  ],
}
const InfluenzaDealsPrice: InfluenzaNavigationPage<
  typeof InfluenzaDealsPriceModel
> = {
  key: 'InfluenzaDealsPrice',
  path: ['supply-deals', 'pricing-table'],
  model: { ...InfluenzaDealsPriceModel, name: 'Pricing Table' },
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableName: 'flu_pricing_deals',
      airtableBase: 'lzdb',
    },
  ],
}

const ModernaInfluenzaTenders: InfluenzaNavigationPage<
  typeof InfluenzaTendersModel
> = {
  key: 'InfluenzaDealsProduction',
  path: ['tenders', 'table'],
  model: InfluenzaTendersModel,
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableName: 'flu_tender',
      airtableBase: 'lzdb',
    },
  ],
}

export const InfluenzaCommercialPages: INavigationPages<InfluenzaCommercialSlugs> =
  {
    InfluenzaCountriesImmunisationSchedule,
    InfluenzaDealsSupply,
    InfluenzaDealsPrice,
    ModernaInfluenzaTenders,
  }
