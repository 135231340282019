import { Save, Search } from '@carbon/icons-react'
import {
  Box,
  Button,
  HStack,
  Icon,
  InputGroup,
  InputLeftElement,
  Skeleton,
  Text,
} from '@chakra-ui/react'
import { UseQueryResult } from '@tanstack/react-query'
import _ from 'lodash'
import { memo, useState, useCallback, useMemo, useContext } from 'react'
import { TableInstance, TableState, useAsyncDebounce } from 'react-table'

import { AuthContext } from 'contexts'

import { Input } from 'components'

import { UserView } from 'api/types'

import useTracking from 'tracking/useTracking'

import ColumnButton from './Toolbar/ColumnButton'
import TableExport from './Toolbar/ExportButton'
import Filter from './Toolbar/FilterButton'
import DeleteUserViewButton from './Toolbar/UserViews/DeleteUserViewButton'
import RenameUserViewButton from './Toolbar/UserViews/RenameUserViewButton'
import { useTableParentProps } from './provider/TableParentProps'
import { serializeState } from './useUserViews'

const GlobalSearchFilter = memo(
  ({
    globalFilter,
    setGlobalFilter,
  }: {
    setGlobalFilter: TableInstance<any>['setGlobalFilter']
    globalFilter: TableState<any>['globalFilter']
  }) => {
    const [tracking] = useTracking()
    const [value, setValue] = useState(globalFilter)

    const onChange = useAsyncDebounce((value) => {
      setGlobalFilter(value || undefined)
    }, 200)

    return (
      <InputGroup>
        <InputLeftElement
          height='100%'
          pr='10px'
          pl='16px'
          children={<Icon as={Search} boxSize='18px' height='100%' />}
        />
        <Input
          w='200px'
          type='text'
          py={2}
          px={7}
          pl={10}
          backgroundColor='white'
          fontSize='12px'
          value={value || ''}
          placeholder='Search'
          onChange={(e: any) => {
            setValue(e.target.value)
            onChange(e.target.value)
          }}
          height='32px'
          rounded='4px'
          _placeholder={{
            color: 'black',
          }}
          borderColor='gray3'
          onBlur={() => value && tracking.tableSearch({ searchValue: value })}
        />
      </InputGroup>
    )
  }
)

const TableAccessory = ({
  useTableData,
  calculatedState,
}: {
  useTableData: TableInstance<any>
  calculatedState: TableState<any>
}) => {
  const { userInfo } = useContext(AuthContext)
  const { parentProps } = useTableParentProps()
  const [tracking] = useTracking()

  const {
    model,
    searchable,
    searchField = '',
    exportName,
    isLoading,
    data,
    userViewConfig,
    useUserViewsReturn,
    tableName,
  } = parentProps

  const {
    userViewQuery: { data: userViews },
    updateUserView,
    deleteUserView,
  } = userViewConfig ?? {
    userViewQuery: { data: undefined } as UseQueryResult<UserView[], any>,
    createUserView: () => {},
    updateUserView: () => {},
    deleteUserView: () => {},
  }

  const {
    setGlobalFilter,
    state: { advancedFilters },
    setAdvancedFilters,
    allColumns,
    setColumnOrder,
    visibleColumns,
    toggleHideColumn,
  } = useTableData

  // We make a shallow copy of each object here since this value is mutable from react-table.
  // If we use it directly, react memo will always think it's the same value.
  const allColumnCopy = allColumns.map((x) => ({ ...x }))

  const onSaveCurrentUserView = useCallback(
    (id: number) => {
      const currentUserView = serializeState(calculatedState)

      updateUserView(id, { view: currentUserView })
    },
    [calculatedState, updateUserView]
  )

  const stateIsDirty = useMemo(
    () =>
      !!userViews &&
      useUserViewsReturn?.stateIsReady &&
      useUserViewsReturn?.userViewSelected &&
      !_.isEqual(
        serializeState(calculatedState),
        userViews[useUserViewsReturn?.selectedUserViewIndex ?? 0]?.view ?? {}
      ),
    [userViews, calculatedState, useUserViewsReturn]
  )

  const selectedUserViewData = useMemo(
    () =>
      // We need to check the length since it takes an update for the selected index to change after a delete operation
      userViews &&
      userViews.length > (useUserViewsReturn?.selectedUserViewIndex ?? 0) &&
      userViews[useUserViewsReturn?.selectedUserViewIndex ?? 0],
    [useUserViewsReturn?.selectedUserViewIndex, userViews]
  )

  return (
    <Box
      pt={2}
      pb={6}
      position='relative'
      display='flex'
      alignItems='center'
      color='legacy-primary.500'
    >
      <Box>
        {stateIsDirty && (
          <Text fontSize='12px' color='error'>
            You have unsaved changes
          </Text>
        )}
        {useUserViewsReturn?.userViewSelected && selectedUserViewData && (
          <HStack pt={stateIsDirty ? '16px' : '4px'}>
            <RenameUserViewButton
              key={selectedUserViewData.name}
              currentViewName={selectedUserViewData.name}
              onRenameUserView={(newString) => {
                if (newString !== '') {
                  updateUserView(selectedUserViewData.id, { name: newString })
                }
              }}
            />
            <DeleteUserViewButton
              onDeleteUserView={() => {
                tracking.deleteUserView({
                  userViewId: selectedUserViewData.id,
                  userViewName: selectedUserViewData.name,
                })
                deleteUserView(selectedUserViewData.id)
              }}
            />
            {stateIsDirty && (
              <Button
                variant='clean'
                onClick={() => {
                  tracking.updateUserView({
                    userViewId: selectedUserViewData.id,
                    userViewName: selectedUserViewData.name,
                  })
                  onSaveCurrentUserView(selectedUserViewData.id)
                }}
              >
                <Save size={16} />
                <Box mr={1} />
                <Text fontSize='12px'>Save Changes</Text>
              </Button>
            )}
          </HStack>
        )}
      </Box>

      <HStack spacing='24px' ml='auto'>
        <Box>
          <Filter
            model={model}
            data={data}
            advancedFilters={advancedFilters}
            setAdvancedFilters={setAdvancedFilters}
          />
        </Box>
        <ColumnButton
          rtAllColumns={allColumnCopy}
          toggleHideColumn={toggleHideColumn}
          setColumnOrder={setColumnOrder}
        />
        {(!model.exportDisabled || userInfo?.is_staff) && (
          <TableExport
            state={calculatedState}
            columns={visibleColumns}
            exportName={exportName}
          />
        )}
        {searchable &&
          searchField !== '' &&
          (isLoading ? (
            <Skeleton
              width='200px'
              height='32px'
              style={{ borderRadius: '0px' }}
            />
          ) : (
            <GlobalSearchFilter
              globalFilter={calculatedState.globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
          ))}
      </HStack>
    </Box>
  )
}

export default TableAccessory
