import { Search } from '@carbon/icons-react'
import { Icon, Input, InputLeftElement } from '@chakra-ui/react'

import useTracking from 'tracking/useTracking'

type ReportSearchInputPropTypes = {
  showCategory: boolean
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onBlur: () => void
}
export const ReportSearchInput = ({
  showCategory,
  onChange,
  onBlur,
}: ReportSearchInputPropTypes) => {
  const [tracking] = useTracking()
  return (
    <>
      <InputLeftElement
        pointerEvents='none'
        children={
          <Icon
            as={Search}
            mt='auto'
            width='21px'
            height='21px'
            ml={showCategory ? '460px' : 6}
          />
        }
      />
      <Input
        w='full'
        border='1px solid'
        borderColor='gray3'
        backgroundColor='gray5'
        rounded='none'
        borderEndRadius='8px'
        onClick={() =>
          tracking.filterReports({
            filter: 'byInputWords',
          })
        }
        placeholder='What are you looking for?'
        color='black'
        height='61px'
        pl='54px'
        fontWeight={600}
        fontSize='17px'
        onChange={onChange}
        onBlur={onBlur}
        _focus={{
          borderColor: 'gray3',
          boxShadow: 'none',
        }}
        _placeholder={{
          color: 'gray2',
        }}
      />
    </>
  )
}
