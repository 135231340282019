import { Add } from '@carbon/icons-react'
import { Box, Text, Button, ButtonProps, useDisclosure } from '@chakra-ui/react'

import AddUserViewModal from './AddUserViewModal'

type PropTypes = {
  defaultViewName: string
  onCreateNewUserView: (title: string) => void
} & ButtonProps

export default function AddUserViewButton({
  defaultViewName,
  onCreateNewUserView,
  ...buttonProps
}: PropTypes) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Button variant='clean' onClick={onOpen} {...buttonProps}>
        <Box mr={1}>
          <Add size={16} color='#0B0B0B' />
        </Box>
        <Text fontSize='12px'>Add View</Text>
      </Button>
      <AddUserViewModal
        defaultViewName={defaultViewName}
        isOpen={isOpen}
        onClose={onClose}
        onCreateNewUserView={onCreateNewUserView}
      />
    </>
  )
}
