import { Flex, Text } from '@chakra-ui/react'
import { AreaChart, Area, Tooltip, ResponsiveContainer } from 'recharts'

import formatNumber from 'utils/formatNumber'

export interface AreaLineChartItemType {
  name: string
  value: number
}

const CustomTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    const dateLabel = payload[0].payload.name
    return (
      <Flex
        className='custom-tooltip'
        flexDirection='row'
        bg='white'
        p='8px'
        border='2px solid'
        borderColor='gray.100'
        fontSize='sm'
        borderRadius='8px'
      >
        <Text color='gray.700'>
          {new Date(dateLabel).toLocaleString('en-us', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
          })}
          :
        </Text>
        <Text color='gray.900' ml='0.5rem' fontWeight='bold'>
          {formatNumber(payload?.[0]?.value ?? 0)}
        </Text>
      </Flex>
    )
  }

  return null
}

export const AreaLineChart = ({ data }: { data: AreaLineChartItemType[] }) => {
  return (
    <ResponsiveContainer width='100%' height='100%'>
      <AreaChart
        width={200}
        height={60}
        data={data}
        margin={{
          top: 5,
          right: 0,
          left: 0,
          bottom: 5,
        }}
      >
        <Tooltip content={<CustomTooltip />} />
        <defs>
          <linearGradient id='colorValue' x1='0' y1='0' x2='0' y2='1'>
            <stop offset='5%' stopColor='#0147ba' stopOpacity={0.1} />
            <stop offset='95%' stopColor='#FFFFFF' stopOpacity={0.1} />
          </linearGradient>
        </defs>
        <Area
          type='monotone'
          dataKey='value'
          stroke='#0147ba'
          fill='url(#colorValue)'
        />
      </AreaChart>
    </ResponsiveContainer>
  )
}
