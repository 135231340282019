import { TreatmentStudyDataMetaModel } from 'config/common/baseModel/TreatmentStudyDataMeta'

import { compose, renameColumn, whiteListColumn } from 'utils/overrideModel'

export const resultsColumnsWhitelist = compose<
  typeof TreatmentStudyDataMetaModel
>(
  whiteListColumn([
    'name',
    'linkAfTrUniqueTreatmentNameToBnf',
    'afParentOrChild',
    // START OF CHILD
    'govId',
    'candidateList',
    'trialAcronym',
    'resultOverall',
    'afApprovalStatus',
    'treatmentType',
    'clinicalTrialNctUrl',
    'clinicalTrialTitle',
    'clinicalTrialPhase',
    'recruitmentStatus',
    'primaryCompletionDate',
    'inpatientOutpatient',
    'stageOfInterventionNew',
    'patientPopulation',
    'patientCharacteristics',
    'meanOrMedianAge',
    'numberOfParticipantsTotal',
    'allocationRatio',
    'interventionNumberOfParticipants',
    'controlNumberOfParticipants',
    'routeOfAdministration',
    'dose',
    'interimOrFullResults',
    'publicationType',
    'presentations',
    'articleLink',
    'media',
    'linkPress',
    'primaryOutcome',
    'primaryOutcomeSpecific',
    'resultSummary',
    'resultStat',
    'interventionRelatedAe',
    'rosRating',
    'interimResults',
    // END OF CHILD
  ]),
  renameColumn('presentations', 'Papers'),
  renameColumn('rosRating', 'Risk of Bias'),
  renameColumn('interventionRelatedAe', 'Adverse Events')
)
