import TabbedDisplay from 'modules/TabbedDisplay'

import FullReportPage from 'routes/common/FullReportPage'
import Dashboard from 'routes/dashboard'
import WidgetComponentArticle from 'routes/dashboard/WidgetComponents/WidgetArticle'

import { TableauEmbed } from 'components'

import { ISingleCustomPage } from 'interfaces/navigationPage.interface'

import { getMediaDefaultWidget } from 'config/common/baseModel/Media'
import { getPressReleasesDefaultWidget } from 'config/common/baseModel/PressReleases'

import { RSVSlugs } from './RSVMenu'
import { RSVPages } from './RSVPages'

const DashboardPage: ISingleCustomPage<RSVSlugs> = {
  path: '',
  component: () => (
    <Dashboard
      widgetTabs={[
        getMediaDefaultWidget({ view: RSVPages['RSVNewsMedia'].views[0] }),
        getPressReleasesDefaultWidget({
          view: RSVPages['RSVNewsPressReleases'].views[0],
        }),
        {
          heading: 'Candidate Papers',
          body: (
            <WidgetComponentArticle
              model={RSVPages['RSVCandidatePapers'].model}
              view={RSVPages['RSVCandidatePapers'].views[0]}
              url='doiUrl'
              title='title'
              date='publicationDate'
            />
          ),
        },
      ]}
    />
  ),
}

const CandidatesPipeline: ISingleCustomPage<RSVSlugs> = {
  path: ['candidates', 'pipeline'],
  component: () => (
    <TableauEmbed
      path='/RSVCandidatePipeline/VaccineCandidatePipeline'
      height='85vh'
    />
  ),
}

const ProgressTracker: ISingleCustomPage<RSVSlugs> = {
  path: ['candidates', 'progress-tracker'],
  component: () => (
    <TabbedDisplay
      tabs={[
        {
          heading: 'Vaccines',
          body: (
            <TableauEmbed path='/WIPRSVprogresstracker/VAXOverallProgressbyPhase' />
          ),
        },
        {
          heading: 'Non-Vaccines',
          body: (
            <TableauEmbed path='/WIPRSVprogresstracker/TRXOverallProgressbyPhase' />
          ),
        },
      ]}
    />
  ),
}

const Reports: ISingleCustomPage<RSVSlugs> = {
  path: 'reports',
  component: () => <FullReportPage slugOverwrites={['deep-dive']} />,
}

const RecentTrialsMap: ISingleCustomPage<RSVSlugs> = {
  path: ['trials', 'recent-trials-map'],
  component: () => (
    <TableauEmbed
      path='/Clinical_trials_COVID_Flu_RSV/Clinicaltrials_RSV_1'
      height='270vh'
    />
  ),
}
const ApprovalsMapDashboard: ISingleCustomPage<RSVSlugs> = {
  path: ['approvals', 'map'],
  component: () => <TableauEmbed path='/RSVapprovalsmap/IDA360Approvals' />,
}

export const RSVCustomPages: Record<string, ISingleCustomPage<RSVSlugs>> = {
  DashboardPage,
  CandidatesPipeline,
  ProgressTracker,
  Reports,
  ApprovalsMapDashboard,
  RecentTrialsMap,
}
