import { Box } from '@chakra-ui/react'

import FullReportPage from 'routes/common/FullReportPage'
import Dashboard from 'routes/dashboard'

import {
  Panel,
  PanelBody,
  PanelHeader,
  PanelTitle,
  TableauEmbed,
} from 'components'

import { ISingleCustomPage } from 'interfaces/navigationPage.interface'

import { getMediaDefaultWidget } from 'config/common/baseModel/Media'
import { getPressReleasesDefaultWidget } from 'config/common/baseModel/PressReleases'
import { getTweetsDefaultWidget } from 'config/common/baseModel/Tweets'

import { CardioAntithromboticsSlugs } from './CardioAntithromboticsMenu'
import { CardioAntithromboticsPages } from './CardioAntithromboticsPages'

const DashboardPage: ISingleCustomPage<CardioAntithromboticsSlugs> = {
  path: '',
  component: () => (
    <Dashboard
      widgetTabs={[
        getMediaDefaultWidget({
          view: CardioAntithromboticsPages['CardioAntithromboticsNews']
            .views[0],
        }),
        getTweetsDefaultWidget({
          view: CardioAntithromboticsPages['CardioAntithromboticsTweets']
            .views[0],
        }),
        getPressReleasesDefaultWidget({
          view: CardioAntithromboticsPages['CardioAntithromboticsPressReleases']
            .views[0],
        }),
      ]}
    />
  ),
}
const CandidatePipeline: ISingleCustomPage<CardioAntithromboticsSlugs> = {
  path: ['candidates', 'pipeline'],
  component: () => <TableauEmbed path='/ATCandidateOverview/Pipeline' />,
}

const ClinicalTrialsTimeline: ISingleCustomPage<CardioAntithromboticsSlugs> = {
  path: ['clinical-trials', 'timeline'],
  component: () => <TableauEmbed path='/OngoingATClinicalTrials/Pipeline' />,
}

const ClinicalTrialsInhibitor: ISingleCustomPage<CardioAntithromboticsSlugs> = {
  path: ['clinical-trials', 'fxi-and-fxii-inhibitor-timeline'],
  component: () => (
    <TableauEmbed path='/FactorXIInhibitorTrialsTimeline/Pipeline' />
  ),
}

const GlobalRevenuePage: ISingleCustomPage<CardioAntithromboticsSlugs> = {
  path: 'global-revenue',
  component: () => (
    <Panel overflow='hidden' height='100%'>
      <PanelHeader>
        <Box display='flex' flexDirection='row' alignItems='center'>
          <PanelTitle>Global Revenue</PanelTitle>
        </Box>
      </PanelHeader>
      <PanelBody>
        <TableauEmbed
          path='/ATRevenuedraft/RevenueDashboardwTargetfilter'
          height={'calc(100vh)'}
        />
      </PanelBody>
    </Panel>
  ),
}

const ProgressByPhase: ISingleCustomPage<CardioAntithromboticsSlugs> = {
  path: ['candidates', 'progress-by-phase'],
  component: () => (
    <Panel overflow='hidden' height='100%'>
      <PanelHeader>
        <Box display='flex' flexDirection='row' alignItems='center'>
          <PanelTitle>Progress Tracker</PanelTitle>
        </Box>
      </PanelHeader>
      <PanelBody>
        <TableauEmbed
          path='/FrontendAntithromboticsprogresstracker/OverallProgressbyPhase'
          height={'calc(100vh)'}
        />
      </PanelBody>
    </Panel>
  ),
}

const Reports: ISingleCustomPage<CardioAntithromboticsSlugs> = {
  path: 'reports',
  component: () => <FullReportPage showCategory={false} />,
}

const SafetyOverview: ISingleCustomPage<CardioAntithromboticsSlugs> = {
  path: 'safety-overview',
  component: () => (
    <TableauEmbed path='/AntithromboticsSafetyvis/Frequencymeasures' />
  ),
}
export const CardioAntithromboticsCustomPages: Record<
  string,
  ISingleCustomPage<CardioAntithromboticsSlugs>
> = {
  GlobalRevenuePage,
  CandidatePipeline,
  Reports,
  ProgressByPhase,
  DashboardPage,
  ClinicalTrialsInhibitor,
  ClinicalTrialsTimeline,
  SafetyOverview,
}
