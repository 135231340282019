import { toRelativeUrl } from '@okta/okta-auth-js'
import { useOktaAuth } from '@okta/okta-react'
import { useContext, useEffect } from 'react'

import { AuthContext } from 'contexts'

import { LoadingAnimation } from 'components'

export type AirfinitySecureRoutePropTypes = {
  children: React.ReactNode
}
/**
 * An adaptation of Okta's SecureRoute that wraps the children to make it work with react-router v6
 * We also wait for userInfo to be loaded and render a spinner while waiting
 */
const AirfinitySecureRoute = ({ children }: AirfinitySecureRoutePropTypes) => {
  const { oktaAuth, authState, _onAuthRequired } = useOktaAuth()
  const { userInfo } = useContext(AuthContext)

  useEffect(() => {
    if (!authState) {
      return
    }

    if (!authState?.isAuthenticated) {
      const originalUri = toRelativeUrl(
        window.location.href,
        window.location.origin
      )
      oktaAuth.setOriginalUri(originalUri)
      if (
        _onAuthRequired &&
        process.env.REACT_APP_VERCEL_ENV &&
        process.env.REACT_APP_VERCEL_ENV === 'production'
      ) {
        _onAuthRequired(oktaAuth)
      } else {
        oktaAuth.signInWithRedirect()
      }
    }
  }, [oktaAuth, authState, authState?.isAuthenticated, _onAuthRequired])

  if (!authState || !authState?.isAuthenticated || !userInfo) {
    return <LoadingAnimation />
  }

  return <>{children}</>
}

export default AirfinitySecureRoute
