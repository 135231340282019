import { Warning } from '@carbon/icons-react'
import { Box, HStack, Icon, Text, useToast } from '@chakra-ui/react'
import { useOktaAuth } from '@okta/okta-react'
import { SIDEBAR_WIDTH } from 'constants/misc'
import React, { useContext, useEffect, useMemo } from 'react'
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useParams,
  useSearchParams,
} from 'react-router-dom'

import ChatWidget from 'modules/ChatWidget'
import GlobalSearchOverlay from 'modules/GlobalSearchOverlay'
import Navigation from 'modules/Navigation'
import CurrentAppNavLinks from 'modules/Navigation/CurrentAppNavLinks'

import { AuthContext } from 'contexts'
import {
  MainBodyContainerConsumer,
  MainBodyContainerProvider,
} from 'contexts/MainBodyContainer'

import ErrorBoundary from 'components/error-boundary'

import usePermissionCheck from 'api/usePermissionCheck'

import { EntityComponentPropsTypes } from 'interfaces/entity.interface'
import { IModel } from 'interfaces/model.interface'
import { IApp } from 'interfaces/navigationApp.interface'
import {
  ISingleNavigationPage,
  NavigationPageComponentProps,
} from 'interfaces/navigationPage.interface'

import useTracking from 'tracking/useTracking'

import { AccessGroups } from 'enums/AccessGroups'

import { apps, appsListUnion } from 'config/apps'

import { resolveModel } from 'utils/model'
import resolvePath from 'utils/resolvePath'

import BaseEntityDetail from './entities/BaseEntityDetail'
import { useAppParams, useAppRoute } from './utils'

const AppRoutes = React.memo(() => {
  const { appListUserCanAccess } = useContext(AuthContext)
  const selectedApp = useAppRoute()

  const currentApp = appListUserCanAccess.find(
    (app) => app.slug === selectedApp
  )!

  const pageRoutes = useMemo(() => {
    const customPages = Object.values(currentApp?.customPages ?? {}).map(
      (currentCustomPage) => {
        const currentPath = currentCustomPage.path
        const currentComponent = currentCustomPage.component
        return (
          <Route
            key={resolvePath([currentPath])}
            path={resolvePath([currentPath])}
            element={<>{React.createElement(currentComponent)}</>}
          />
        )
      }
    )

    const standardPages = Object.values(currentApp.pages).map((currentPage) => {
      const currentComponent = currentPage.component
      const currentPath = currentPage.path
      const views = currentPage.views
      const model = currentPage.model

      const noPrint = model.noPrint

      // We handle this for scenarios where we don't render a page normally
      // But we still need on the page object so that the webworker processes it
      if (currentPath === null) {
        return []
      }

      const componentProps: NavigationPageComponentProps<any> & {
        key: any
      } = {
        // We need this so that the component re-renders on path change
        key: resolvePath([currentApp.slug, currentPath]),
        app: currentApp,
        views,
        model,
        page: currentPage,
      }

      return [
        <Route
          key={resolvePath([currentApp.slug, currentPath])}
          path={resolvePath([currentPath])}
          element={
            <Box className={noPrint === true ? 'no-print' : ''}>
              {React.createElement(currentComponent, componentProps)}
            </Box>
          }
        />,
        <Route
          key={resolvePath([currentApp.slug, currentPath, 'id'])}
          path={resolvePath([currentPath, ':id'])}
          element={
            <EntityPageHandler
              currentApp={currentApp}
              currentPage={currentPage}
            />
          }
        />,
      ]
    })

    return standardPages.concat(customPages)
  }, [currentApp])

  return (
    <ErrorBoundary>
      <Routes>
        {pageRoutes}
        <Route path='*' element={<RedirectToSelectedApp />} />
      </Routes>
    </ErrorBoundary>
  )
})

type EntityPageHandlerPropTypes = {
  currentApp: IApp<any>
  currentPage: ISingleNavigationPage<any, IModel<any, any>>
}

const EntityPageHandler = ({
  currentApp,
  currentPage,
}: EntityPageHandlerPropTypes) => {
  const [queryParams] = useSearchParams()
  const passedView = queryParams.get('v')
  const params = useParams()

  const entityComponent: React.FC<
    React.PropsWithChildren<EntityComponentPropsTypes>
  > = currentPage?.entity?.component ?? BaseEntityDetail

  const customView: string =
    passedView && !Array.isArray(passedView)
      ? passedView
      : currentPage.views[0].airtableName

  // Try to find the model based on the passed view
  const inferredPage = Object.values(currentApp.pages).find((page) => {
    const basePageModel = page.model
    return page.views.find((view) => {
      const model: IModel<any> = resolveModel(basePageModel, view)

      return (
        // It has to have the same view & model endpoint since view name could be duplicate
        view.airtableName === customView &&
        model.endpoint === currentPage.model.endpoint
      )
    })
  })

  const inferredModel = inferredPage?.model

  // Use that, otherwise we use the default model of the page
  const model = inferredModel ?? currentPage.model

  const viewData = {
    airtableName: inferredPage?.views[0].airtableName,
    airtableBase: inferredPage?.views[0].airtableBase,
  }

  return React.createElement(entityComponent, {
    app: currentApp.slug,
    page: currentPage,
    model,
    entityId: params.id ?? '',
    viewData: viewData,
  })
}

const RedirectToSelectedApp = () => {
  const app = useAppRoute()
  return <Navigate to={`/${app}`} replace />
}

const Home = () => {
  const { authState } = useOktaAuth()
  const [tracking] = useTracking()
  const { hash } = useLocation()
  const toast = useToast()

  const { userInfo, userCanAccessApp } = useContext(AuthContext)

  const appParams = useAppParams()
  const { app, pageSlug, subPageSlug } = appParams

  const tab = decodeURIComponent(hash.replace(/#/g, ''))

  useEffect(() => {
    tracking.visitPage({ app, page: pageSlug, subPage: subPageSlug, tab })
    // excluding tracking from dep array because tracking listens to the path
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app, pageSlug, subPageSlug, tab])

  const selectedApp = app as appsListUnion
  const selectedAppObject = useMemo(() => apps[selectedApp], [selectedApp])

  // T&C Check (Calls this API to check for permission)
  usePermissionCheck(selectedApp, {
    enabled:
      authState?.isAuthenticated &&
      !userInfo?.groups.includes(AccessGroups.Diagnostics) &&
      userCanAccessApp(selectedAppObject),
  })

  // The selected app isn't permitted
  if (!userCanAccessApp(selectedAppObject)) {
    toast({
      duration: 3000,
      position: 'bottom',
      title: 'Title',
      render: () => (
        <HStack color='white' p={3} bg='red.400'>
          <Icon m='0 5px 2px 0' boxSize='20px' as={Warning} />
          <Text>
            You do not have access to the requested page. Redirected to home.
          </Text>
        </HStack>
      ),
    })
    return <Navigate to={`/`} replace />
  }

  return (
    <Box
      display='flex'
      width='100%'
      flexDirection='column'
      flex={1}
      overflow='hidden'
      color='legacy-primary.800'
    >
      <Navigation />
      <GlobalSearchOverlay>
        <Box display='flex' width='100%' flex={1} overflow='hidden'>
          <CurrentAppNavLinks appParams={appParams} />
          <MainBodyContainerProvider>
            <MainBodyContainerConsumer>
              {({ mainBodyRef }) => (
                <Box
                  ref={mainBodyRef}
                  display='flex'
                  width={`calc(100% - ${SIDEBAR_WIDTH})`}
                  flex={1}
                  px={6}
                  py={3}
                  overflow='auto'
                  flexDirection='column'
                  background='white'
                  position='relative'
                >
                  <Box>
                    <AppRoutes />
                  </Box>
                </Box>
              )}
            </MainBodyContainerConsumer>
          </MainBodyContainerProvider>
        </Box>
        <ChatWidget />
      </GlobalSearchOverlay>
    </Box>
  )
}

export default Home
