import { ArrowLeft, Search } from '@carbon/icons-react'
import { Box, IconButton, Image, Text } from '@chakra-ui/react'
import { NAV_HEIGHT, SIDEBAR_WIDTH } from 'constants/misc'
import { useLocation } from 'react-router-dom'

import AppsSelectorModal from 'modules/AppsSelector/AppsSelectorModal'

import { useAppParams } from 'routes/utils'

import { useGlobalSearch } from 'contexts/GlobalSearch'

import AppletSelector from './AppletSelector'
import { Logo } from './Logo'
import RightMenu from './RightMenu'

type PropTypes = {
  showSearchBar?: boolean
  onBackButton?: () => void
}

const Navigation = ({ showSearchBar = true, onBackButton }: PropTypes) => {
  const appParams = useAppParams()

  const location = useLocation()

  const isHome = location.pathname === '/'

  const { overlayShown, reset, setOverlayShown } = useGlobalSearch()

  return (
    <Box
      height={NAV_HEIGHT}
      minHeight={NAV_HEIGHT}
      bg='black'
      borderBottom='4px solid'
      borderColor='yellow.500'
      color='white'
      display='flex'
      alignItems='center'
    >
      <Box
        bg={isHome ? 'black' : 'white'}
        h={`50px`}
        borderRight='1px solid'
        borderColor={isHome ? 'transparent ' : 'gray.200'}
        display='flex'
        alignItems={'center'}
      >
        <Box width={'100px'} ml='60px' mt='12px'>
          <Logo color={isHome ? 'white' : 'black'} />
        </Box>
        <Text mx='1rem' fontSize={'2xl'}>
          &
        </Text>
        <Image
          height='30px'
          mt='8px'
          src='https://www.cslseqirus.us/-/media/shared/logos/seqirus/cslseqirus_rgb_highres_m01.png'
        />
        <Box mx='2rem'>
          <Text mx='1rem' fontSize={'lg'}>
            Pandemic Response Solutions
          </Text>
        </Box>
      </Box>
      <Box display='flex' alignItems='center'>
        {onBackButton ? (
          <Box
            display='flex'
            alignItems='center'
            onClick={onBackButton}
            cursor='pointer'
            role='group'
          >
            <Box
              rounded='full'
              width='36px'
              height='36px'
              display='flex'
              mr={1}
              alignItems='center'
              transition='all .25s'
              justifyContent='center'
              _groupHover={{
                bg: 'legacy-primary.600',
              }}
            >
              <ArrowLeft size={20} />
            </Box>
            <Box textTransform='uppercase' fontWeight={600} fontSize='sm'>
              Back
            </Box>
          </Box>
        ) : (
          <Box minWidth={SIDEBAR_WIDTH}>{!isHome && <AppsSelectorModal />}</Box>
        )}
        {!isHome && !onBackButton && <AppletSelector appParams={appParams} />}
      </Box>

      <Box
        ml='auto'
        fontSize='sm'
        color='white'
        display='flex'
        alignItems='center'
        height='100%'
      >
        {!isHome && showSearchBar && (
          <IconButton
            icon={<Search size={20} />}
            aria-label='Search'
            data-cy='global-search-button'
            variant='ghost'
            color='white'
            _hover={{
              bg: 'none',
            }}
            _focus={{
              boxShadow: 'none',
            }}
            _active={{
              bg: 'none',
            }}
            onClick={() => (!overlayShown ? setOverlayShown(true) : reset())}
          />
        )}
        <RightMenu />
      </Box>
    </Box>
  )
}

export default Navigation
