import amplitude from 'amplitude-js'
import mixpanel from 'mixpanel-browser'

interface BasicTrackingTypes {
  app?: string
  page?: string
  subPage?: string
  tab?: string
}

export const initTracking = () => {
  if (process.env.NODE_ENV !== 'production') return
  amplitude.getInstance().init('cc06fdca864be7bb8126a10b4dcfc8ee', undefined, {
    apiEndpoint: `api.airfinity.com/amplitude/`,
    headers: {
      'Cross-Origin-Resource-Policy': '', // https://github.com/amplitude/Amplitude-JavaScript/pull/489
    },
  })

  mixpanel.init('25bc439aebb2b528bbfb6ac87a47d4e8', {
    api_host: 'https://mixpanel-proxy.airfinity.com/',
  })
}

const logEvent = (event: string, data: any) => {
  amplitude.getInstance().logEvent(event, data)
  if (process.env.NODE_ENV !== 'production') return
  mixpanel.track(event, data)
}

interface IdentifyUserType {
  userId: string | undefined
  name: string | undefined
  groups: string | undefined
  isEmailVerified: boolean | undefined
  client?: string | undefined
  title?: string | undefined
}
export const identifyUser = ({
  userId,
  name,
  groups,
  isEmailVerified,
  client,
  title,
}: IdentifyUserType) => {
  if (process.env.NODE_ENV !== 'production') return
  amplitude.getInstance().setUserId(userId || '')
  amplitude
    .getInstance()
    .setUserProperties({ name, groups, isEmailVerified, client, title })
  mixpanel.identify(userId || '')
  mixpanel.people.set({ name, groups, isEmailVerified, client, title })
}

export const visitPage = ({ app, page, subPage, tab }: BasicTrackingTypes) => {
  logEvent('visit page', {
    app,
    page,
    subPage,
    tab,
  })
}
interface BasePageOpenPageTypes extends BasicTrackingTypes {
  selectedApp: string
}
export const basePageOpenPage = ({
  selectedApp,
  page,
}: BasePageOpenPageTypes) => {
  logEvent('open index page', { selectedApp, page })
}

interface ChangeAppType {
  fromApp: string
  fromPage?: string
  toApp?: string
}
export const changeApp = ({ fromApp, fromPage, toApp }: ChangeAppType) => {
  logEvent('change app', {
    fromApp,
    fromPage,
    toApp,
  })
}

export const openAppPage = ({ app, page }: BasicTrackingTypes) => {
  logEvent('open page', {
    app,
    page: page || 'dashboard',
  })
}

export const openAppSubPage = ({ app, page, subPage }: BasicTrackingTypes) => {
  logEvent('open sub page', {
    app,
    page,
    subPage,
  })
}
export const openSettings = ({ app, page, subPage }: BasicTrackingTypes) => {
  logEvent('open settings page', {
    app,
    page,
    subPage,
  })
}
export const openTutorial = ({ app, page, subPage }: BasicTrackingTypes) => {
  logEvent('open tutorial page', {
    app,
    page,
    subPage,
  })
}
export const openBookmarks = ({ app, page, subPage }: BasicTrackingTypes) => {
  logEvent('open bookmarks page', {
    app,
    page,
    subPage,
  })
}
interface SearchType extends BasicTrackingTypes {
  searchValue: string
}
export const globalSearch = ({
  app,
  page,
  subPage,
  searchValue,
}: SearchType) => {
  logEvent('global search', {
    app,
    page,
    subPage,
    searchValue,
  })
}
export const dashboardSearch = ({ searchValue }: SearchType) => {
  logEvent('dashboard search', {
    searchValue,
  })
}

export const openPageAnalytics = ({
  app,
  page,
  subPage,
}: BasicTrackingTypes) => {
  logEvent('open analytics', {
    app,
    page,
    subPage,
  })
}
interface LoadMoreItemsType extends BasicTrackingTypes {
  section: string
}
export const loadMoreItems = ({ app, section }: LoadMoreItemsType) => {
  logEvent('main page load more', {
    app,
    section,
  })
}
interface ReportType extends BasicTrackingTypes {
  report: string
  type?: 'report' | 'highlight' | 'summary'
}
export const openReport = ({
  app,
  page,
  subPage,
  report,
  type = 'report',
}: ReportType) => {
  logEvent('open report', {
    app,
    page,
    subPage,
    report,
    type,
  })
}
interface UpSellReportType extends BasicTrackingTypes {
  report: string
  type?: 'report' | 'highlight' | 'summary'
}
export const upSellReport = ({
  app,
  page,
  subPage,
  report,
  type = 'report',
}: UpSellReportType) => {
  logEvent('open report', {
    app,
    page,
    subPage,
    report,
    type,
  })
}
export const downloadReport = ({
  app,
  page,
  subPage,
  report,
  type = 'report',
}: ReportType) => {
  logEvent('download report', {
    app,
    page,
    subPage,
    report,
    type,
  })
}
export const createBookmark = ({ app, page, subPage, report }: ReportType) => {
  logEvent('create a bookmark', {
    app,
    page,
    subPage,
    report,
  })
}
export const removeBookmark = ({ app, page, subPage, report }: ReportType) => {
  logEvent('remove bookmark', {
    app,
    page,
    subPage,
    report,
  })
}
interface FilterReportsType extends BasicTrackingTypes {
  filter: string
}
export const filterReports = ({
  app,
  page,
  subPage,
  filter,
}: FilterReportsType) => {
  logEvent('filter reports', {
    app,
    page,
    subPage,
    filter,
  })
}
export const searchReports = ({
  app,
  page,
  subPage,
  searchValue,
}: SearchType) => {
  logEvent('reports search', {
    app,
    page,
    subPage,
    searchValue,
  })
}
interface DownloadCsvType extends BasicTrackingTypes {
  file: string
}
export const downloadCsv = ({ app, page, subPage, file }: DownloadCsvType) => {
  logEvent('download csv', {
    app,
    page,
    subPage,
    file,
  })
}

export const createNewUserView = ({
  app,
  subPage,
  page,
}: BasicTrackingTypes) => {
  logEvent('create user view', {
    app,
    page,
    subPage,
  })
}
interface DeleteUserViewType extends BasicTrackingTypes {
  userViewId: number
  userViewName: string
}
export const deleteUserView = ({
  app,
  page,
  subPage,
  userViewId,
  userViewName,
}: DeleteUserViewType) => {
  logEvent('delete user view', {
    app,
    page,
    subPage,
    userViewId,
    userViewName,
  })
}
interface UpdateUserViewType extends BasicTrackingTypes {
  userViewId: number
  userViewName: string
}
export const updateUserView = ({
  app,
  page,
  subPage,
  userViewId,
  userViewName,
}: UpdateUserViewType) => {
  logEvent('update userView', {
    app,
    page,
    subPage,
    userViewId,
    userViewName,
  })
}
interface SelectDefaultViewType extends BasicTrackingTypes {
  view?: string
}
export const selectDefaultView = ({
  app,
  page,
  subPage,
  view,
}: SelectDefaultViewType) => {
  logEvent('select default view', {
    app,
    page,
    subPage,
    view,
  })
}
interface TableSearchTypes extends BasicTrackingTypes {
  searchValue: string | number
}

export const tableSearch = ({
  app,
  page,
  subPage,
  searchValue,
}: TableSearchTypes) => {
  logEvent('table search', {
    app,
    page,
    subPage,
    searchValue,
  })
}
interface TableAdvanceFilterSearch extends BasicTrackingTypes {
  column: string
  type: string
  filterValue: string | number
}
export const tableAdvanceFilter = ({
  app,
  page,
  subPage,
  column,
  type,
  filterValue,
}: TableAdvanceFilterSearch) => {
  logEvent('table advance filter', {
    app,
    page,
    subPage,
    column,
    type,
    filterValue,
  })
}

interface TableColumnSort extends BasicTrackingTypes {
  column: string
  sortIndex: number
}
export const tableColumnSort = ({
  app,
  page,
  subPage,
  column,
  sortIndex,
}: TableColumnSort) => {
  logEvent('table column sort', {
    app,
    page,
    subPage,
    column,
    sortIndex,
  })
}

interface TableColumnHide extends BasicTrackingTypes {
  column: string
  isVisible?: boolean
}
export const tableColumnHide = ({
  app,
  page,
  subPage,
  column,
  isVisible,
}: TableColumnHide) => {
  logEvent('table column hide', {
    app,
    page,
    subPage,
    column,
    isVisible,
  })
}

interface TablePaginate extends BasicTrackingTypes {
  action: 'PREV' | 'NEXT'
  pageIndex: number
}
export const tablePaginate = ({
  app,
  page,
  subPage,
  action,
  pageIndex,
}: TablePaginate) => {
  logEvent('table paginate', {
    app,
    page,
    subPage,
    action,
    pageIndex,
  })
}
interface MediaSelectionType extends BasicTrackingTypes {
  topic: string
  date: string
  section: string
  source: string
  title: string
  link: string
}
export const mediaSelection = ({
  app,
  page,
  subPage,
  section,
  topic,
  date,
  source,
  title,
  link,
}: MediaSelectionType) => {
  logEvent('media click', {
    app,
    page,
    subPage,
    section,
    topic,
    date,
    source,
    title,
    link,
  })
}

export const appsModalTrigger = ({
  app,
  page,
  subPage,
}: BasicTrackingTypes) => {
  logEvent('open navigation menu', {
    app,
    page,
    subPage,
  })
}

export const chatWidgetTrigger = ({
  app,
  page,
  subPage,
}: BasicTrackingTypes) => {
  logEvent('open ask an analyst', {
    app,
    page,
    subPage,
  })
}

export const sendAnalystQuestion = ({
  app,
  page,
  subPage,
}: BasicTrackingTypes) => {
  logEvent('ask an analyst', {
    app,
    page,
    subPage,
  })
}

export const viewTableau = ({ app, page, subPage }: BasicTrackingTypes) => {
  logEvent('view tableau', {
    app,
    page,
    subPage,
  })
}

export const viewRShinyApp = ({ app, page, subPage }: BasicTrackingTypes) => {
  logEvent('view RShiny app', {
    app,
    page,
    subPage,
  })
}

interface UserOriginTypes {
  app?: string
  page?: string
  subPage?: string
  origin?: string
}

export const userOrigin = ({ app, page, subPage, origin }: UserOriginTypes) => {
  logEvent('user origin', {
    app,
    page,
    subPage,
    origin,
  })
}
