import TabbedDisplay from 'modules/TabbedDisplay'

import { TableauEmbed } from 'components'

import { ISingleCustomPage } from 'interfaces/navigationPage.interface'

import { InfluenzaEpidemiologySlugs } from './InfluenzaEpidemiologyMenu'

const OverallCasesDashboard: ISingleCustomPage<InfluenzaEpidemiologySlugs> = {
  path: '',
  component: () => (
    <TableauEmbed path='/FRONTENDRSVdashboards_16959027749760/InfluenzaAverageepidFlucasedashboard' />
  ),
}
const StrainCirculationDashboard: ISingleCustomPage<InfluenzaEpidemiologySlugs> =
  {
    path: 'strain-circulation',
    component: () => (
      <TabbedDisplay
        tabs={[
          {
            heading: 'Distribution Map',
            body: (
              <TableauEmbed path='/FRONTENDnewInfluenzadashboards/DistributionMap' />
            ),
          },
          {
            heading: 'Global Time-Series',
            body: (
              <TableauEmbed path='/FRONTENDnewInfluenzadashboards/GlobalTimeseries' />
            ),
          },
        ]}
      />
    ),
  }
const USSeverityDashboard: ISingleCustomPage<InfluenzaEpidemiologySlugs> = {
  path: 'us-severity',
  component: () => (
    <TabbedDisplay
      tabs={[
        {
          heading: 'ILI',
          body: () => (
            <TableauEmbed path='/FRONTENDnewInfluenzadashboards/ILI' />
          ),
        },
        {
          heading: 'Hospitalisations',
          body: () => (
            <TableauEmbed path='/FRONTENDnewInfluenzadashboards/Hospitalisations' />
          ),
        },
      ]}
    />
  ),
}

const VaccineCoverage: ISingleCustomPage<InfluenzaEpidemiologySlugs> = {
  path: 'vaccine-coverage',
  component: () => (
    <TableauEmbed path='/FluVaxforEpid/InfluenzaVaccineCoverage' />
  ),
}

export const InfluenzaEpidemiologyCustomPages: Record<
  string,
  ISingleCustomPage<InfluenzaEpidemiologySlugs>
> = {
  OverallCasesDashboard,
  StrainCirculationDashboard,
  USSeverityDashboard,
  VaccineCoverage,
}
