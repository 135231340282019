import { Flex, Grid, Text } from '@chakra-ui/react'

import { SummaryContainer, SummaryType } from 'components/SummaryCards'

import useDashboardSummaryData from 'api/vaccines/useDashboardSummaryData'

import ChartContainer from './SummaryItem'

const DashboardSummaryComponent = () => {
  const { data, isLoading, isSuccess, isError } = useDashboardSummaryData()
  if (isError)
    return (
      <Flex
        h='200px'
        px={3}
        w='100%'
        justifyContent='center'
        alignItems='center'
      >
        <Text color='gray.700' fontSize='lg'>
          Something went wrong, we couldn't load the summary data
        </Text>
      </Flex>
    )

  return (
    <Flex h='200px' px={3}>
      <Grid
        templateColumns='repeat(3, 1fr)'
        gap={6}
        my={3}
        w='100%'
        overflowX='scroll'
      >
        <SummaryContainer
          title={'New Candidates'}
          data={data?.[0]}
          isLoaded={!isLoading && isSuccess}
          type={SummaryType.NUMBER}
          timeFrame='week'
          formatNumbers={(number) => `${Math.round(number)}`}
        />
        <SummaryContainer
          title={'New Clinical Trials'}
          data={data?.[1]}
          isLoaded={!isLoading && isSuccess}
          type={SummaryType.NUMBER}
          timeFrame='week'
          formatNumbers={(number) => `${Math.round(number)}`}
        />
        <ChartContainer
          title={'Scientific Papers'}
          data={data?.[2]}
          isLoaded={!isLoading && isSuccess}
        />
      </Grid>
    </Flex>
  )
}

export default DashboardSummaryComponent
