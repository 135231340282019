import { Box } from '@chakra-ui/react'

import TabbedDisplay from 'modules/TabbedDisplay'

import DashboardSummaryComponent from 'routes/apps/vaccines/SummaryComponent'
import FullReportPage from 'routes/common/FullReportPage'
import Dashboard from 'routes/dashboard'

import { Panel, PanelBody, TableauEmbed } from 'components'

import { ISingleCustomPage } from 'interfaces/navigationPage.interface'

import { getMediaDefaultWidget } from 'config/common/baseModel/Media'
import { getPresentationDefaultWidget } from 'config/common/baseModel/Presentations'
import { getPressReleasesDefaultWidget } from 'config/common/baseModel/PressReleases'

import CombinationVaccinesReport from './CombinationVaccinesReports'
import { VaccinesSlugs } from './VaccinesMenu'
import {
  SAEPapersPages,
  VaccinesPages,
  VaccinesSafetyPages,
} from './VaccinesPages'

const LatestNewsPage: ISingleCustomPage<VaccinesSlugs> = {
  path: '',
  component: () => {
    return (
      <>
        <DashboardSummaryComponent />
        <Dashboard
          widgetTabs={[
            getMediaDefaultWidget({ view: VaccinesPages['Media'].views[0] }),
            getPresentationDefaultWidget({
              view: VaccinesPages['CandidatePapers'].views[0],
            }),
            getPressReleasesDefaultWidget({
              view: VaccinesPages['PressReleases'].views[0],
            }),
          ]}
        />
      </>
    )
  },
}

const CandidatesPipeline: ISingleCustomPage<VaccinesSlugs> = {
  path: ['candidates', 'pipeline'],
  component: () => (
    <TableauEmbed
      path='/Vaccinesetslides/COVID-vaccinespipeline_1'
      height='130vh'
    />
  ),
}

const EfficacyPage: ISingleCustomPage<VaccinesSlugs> = {
  path: 'vaccine-performance',
  component: () => (
    <Box p={3} overflow='scroll'>
      <Box display='flex' pos='relative'>
        <Panel overflow='hidden' zIndex='1'>
          <PanelBody p={0}>
            <iframe
              id='embed1'
              title='Dashboard'
              src='https://airfinity.shinyapps.io/efficacyMetaAnalysis/'
              style={{
                width: '1px',
                minWidth: '100%',
                height: 'calc(100vh - 125px  - 0.75rem)',
                background: 'white',
              }}
              sandbox='allow-same-origin allow-forms allow-scripts allow-downloads allow-popups allow-popups-to-escape-sandbox allow-top-navigation-by-user-activation'
              frameBorder='0'
            />
          </PanelBody>
        </Panel>
      </Box>
    </Box>
  ),
}

const Reports: ISingleCustomPage<VaccinesSlugs> = {
  path: ['reports', 'standard'],
  component: () => <FullReportPage showAppFilter />,
}
const CustomReports: ISingleCustomPage<VaccinesSlugs> = {
  path: ['reports', 'combination-vaccines'],
  component: () => <CombinationVaccinesReport />,
}

const VaccinesSafetyChildren: ISingleCustomPage<VaccinesSlugs> = {
  path: ['safety', 'real-world'],
  component: () => (
    <TabbedDisplay
      tabs={[
        {
          heading: 'Adverse Events Frequency',
          body: <TableauEmbed path='/Safety/Safety' />,
        },
        ...Object.values(VaccinesSafetyPages),
      ]}
    />
  ),
}
const VaccinesSafetyAdverseEventsFrequency: ISingleCustomPage<VaccinesSlugs> = {
  path: ['safety', 'trials-adverse-events-frequency'],
  component: () => (
    <TableauEmbed path='/WHOvaccinesafetyv5/SideeffectsfollowingWHOapprovedvaccinesFinalv6' />
  ),
}

const SeriousAdverseEvents: ISingleCustomPage<VaccinesSlugs> = {
  path: ['safety', 'serious-adverse-events'],
  component: () => (
    <TabbedDisplay
      tabs={[
        {
          heading: 'SAE by Population Group',
          body: <TableauEmbed path='/SAEbypopulationdashboard/Dashboard1' />,
        },
        {
          heading: 'Papers',
          body: Object.values(SAEPapersPages)[0],
        },
      ]}
    />
  ),
}

const ApprovalsOverviewDashboard: ISingleCustomPage<VaccinesSlugs> = {
  path: ['approvals', 'total-per-country-vaccine'],
  component: () => (
    <TableauEmbed path='/updated_vacc_approvals/Approvals_overview' />
  ),
}
const ApprovalsDurationTimeline: ISingleCustomPage<VaccinesSlugs> = {
  path: ['approvals', 'duration-timeline'],
  component: () => <TableauEmbed path='/Approvals/Timeline' />,
}

const VaccinesApprovalThisWeek: ISingleCustomPage<VaccinesSlugs> = {
  path: ['approvals', 'this-week'],
  component: () => <TableauEmbed path='/Approvals_Weekly_Updates/Dashboard1' />,
}

const BoosterCampaigns: ISingleCustomPage<VaccinesSlugs> = {
  path: ['booster-campaigns'],
  component: () => (
    <TabbedDisplay
      tabs={[
        {
          heading: 'XBB Regulatory Decisions',
          body: (
            <TableauEmbed
              path='/XBBvaccineregulatorydecisions/XBBapprovals'
              height='150vh'
            />
          ),
        },
        {
          heading: 'Vaccination Campaigns Around the World',
          body: <TableauEmbed path='/AutumnWinterPolicy/Sheet2' />,
        },
      ]}
    />
  ),
}

const RecentTrialsMap: ISingleCustomPage<VaccinesSlugs> = {
  path: ['trials', 'recent-trials-map'],
  component: () => (
    <TableauEmbed
      path='/Clinical_trials_COVID_Flu_RSV/Clinicaltrials_COVID'
      height='140vh'
    />
  ),
}
const LandscapeDashboard: ISingleCustomPage<VaccinesSlugs> = {
  path: ['trials', 'landscape'],
  component: () => (
    <TableauEmbed path='/COVIDtrialsfinal/Timetotal' height='140vh' />
  ),
}

const EfficacyAgainstVariants: ISingleCustomPage<VaccinesSlugs> = {
  path: ['results', 'performance-against-variants'],
  component: () => (
    <TableauEmbed path='/Variant-specificVE-old/Variant-specificVE' />
  ),
}

const HeterologousVsHomologousPerformance: ISingleCustomPage<VaccinesSlugs> = {
  path: ['results', 'heterologous-vs-homologous-performance'],
  component: () => (
    <TableauEmbed path='/Mixedschedulesfinal/resultsprimeFE2' height='220vh' />
  ),
}
const BivalentmRnaEffectivenessDashboard: ISingleCustomPage<VaccinesSlugs> = {
  path: ['results', 'bivalent-mrna-effectiveness'],
  component: () => (
    <TableauEmbed
      path='/BivalentmRNAVaccineEffectiveness/Dashboardfinal'
      height='130vh'
    />
  ),
}

const ProgressTrackerDashboard: ISingleCustomPage<VaccinesSlugs> = {
  path: ['candidates', 'progress-tracker'],
  component: () => (
    <TabbedDisplay
      tabs={[
        {
          heading: 'Overall Progress',
          body: (
            <TableauEmbed
              path='/frontendcovidvaccinesprogresstracker/OverallProgressbyPhase_1'
              height='80vh'
            />
          ),
        },
        {
          heading: 'Metric Breakdown',
          body: (
            <TableauEmbed
              path='/frontendcovidvaccinesprogresstracker/ProgressbyMetric'
              height='80vh'
            />
          ),
        },
      ]}
    />
  ),
}

export const VaccinesCustomPages: Record<
  string,
  ISingleCustomPage<VaccinesSlugs>
> = {
  LatestNewsPage,
  CandidatesPipeline,
  Reports,
  CustomReports,
  VaccinesSafetyChildren,
  VaccinesSafetyAdverseEventsFrequency,
  VaccinesApprovalThisWeek,
  EfficacyAgainstVariants,
  EfficacyPage,
  SeriousAdverseEvents,
  RecentTrialsMap,
  HeterologousVsHomologousPerformance,
  ProgressTrackerDashboard,
  BoosterCampaigns,
  LandscapeDashboard,
  ApprovalsOverviewDashboard,
  ApprovalsDurationTimeline,
  BivalentmRnaEffectivenessDashboard,
}
