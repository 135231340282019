import { IApp } from 'interfaces/navigationApp.interface'

import { AccessGroups } from 'enums/AccessGroups'

import { RSVCommercialCustomPages } from './RSVMarketProductionCustomPages'
import {
  RSVCommercialMenu,
  RSVCommercialSlugs,
} from './RSVMarketProductionMenu'
import { RSVCommercialPages } from './RSVMarketProductionPages'

const RSVCommercialApp: IApp<RSVCommercialSlugs> = {
  name: 'Vaccines',
  slug: 'rsv-commercial',
  accessGroups: [AccessGroups.RSVCommercial],
  endpoint: 'rsv-market-and-production',
  menu: RSVCommercialMenu,
  pages: RSVCommercialPages,
  customPages: RSVCommercialCustomPages,
  createdAt: new Date(2022, 5, 21),
}

export default RSVCommercialApp
