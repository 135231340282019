import { TrashCan } from '@carbon/icons-react'
import { Box, Button, ButtonProps, Text, useDisclosure } from '@chakra-ui/react'

import DeleteUserViewModal from './DeleteUserViewModal'

type PropTypes = {
  onDeleteUserView: () => void
} & ButtonProps

export default function DeleteUserViewButton({
  onDeleteUserView,
  ...buttonProps
}: PropTypes) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Button variant='clean' onClick={onOpen} {...buttonProps}>
        <TrashCan size={16} />
        <Box mr={1} />
        <Text fontSize='12px'>Delete</Text>
      </Button>
      <DeleteUserViewModal
        isOpen={isOpen}
        onClose={onClose}
        onDeleteUserView={onDeleteUserView}
      />
    </>
  )
}
