import { Box, Flex, HStack, Text, Tooltip, VStack } from '@chakra-ui/react'
import { Skeleton } from '@chakra-ui/react'

import { AreaLineChart, AreaLineChartItemType } from 'components'

import { SummaryDataType } from 'api/vaccines/useDashboardSummaryData'

import formatNumber from 'utils/formatNumber'

const RankingItemBarDesign = ({ formatNumbers, item, data }: any) => {
  const BallSizeRatio = (item[1] / data[0][1]) * 50
  return (
    <VStack px='5px' py='5px' maxW='90px' position='relative'>
      <Box
        pos='absolute'
        w={'30px'}
        h={BallSizeRatio + 'px'}
        bg='blue.50'
        border='1px solid'
        borderColor='blue.200'
        borderRadius='5px'
        bottom='30px'
        left='50%'
        transform='translate(-50%, 0)'
      />
      <Box mb='0.5rem'>
        <Text
          fontSize='14px'
          opacity='0.99'
          zIndex='1'
          fontWeight='bold'
          color='green.500'
        >
          {formatNumbers(item?.[1])}{' '}
        </Text>
      </Box>
      <Tooltip label={item?.[0]} aria-label='A tooltip'>
        <Text
          opacity='0.99'
          fontWeight='normal'
          whiteSpace='nowrap'
          textTransform='capitalize'
        >
          {item?.[0].split(' ')[0]}
        </Text>
      </Tooltip>
    </VStack>
  )
}

const RankingContainer = ({ title, isLoaded, data, formatNumbers }: any) => {
  return (
    <Box overflow='hidden'>
      <HStack justifyContent='space-between' pb='1rem' px='1rem'>
        <Text fontWeight='semibold'>{title}</Text>
        <Flex
          color='green'
          fontWeight='bold'
          fontSize='sm'
          textAlign='end'
          flexDir='row'
          alignItems='center'
        >
          <Text color='gray.600' mr='0.5rem' whiteSpace='nowrap' ml='1rem'>
            In the past week
          </Text>
        </Flex>
      </HStack>
      <Box h='70px' fontSize='14px'>
        <Skeleton isLoaded={isLoaded} w='100%' height='100%'>
          <HStack
            alignItems='flex-end'
            height='100%'
            justifyContent='space-around'
          >
            {data?.map((item: any, i: number) => (
              <RankingItemBarDesign
                key={i}
                formatNumbers={formatNumbers}
                item={item}
                data={data}
              />
            ))}
          </HStack>
        </Skeleton>
      </Box>
    </Box>
  )
}

export enum SummaryType {
  LINE,
  RANK,
  NUMBER,
}

export const GraphContainer = ({ title, isLoaded, data }: any) => {
  const { increment, chartData } = data
  return (
    <>
      <HStack justifyContent='space-between' pb='1rem' px='1rem'>
        <Text fontWeight='semibold'>{title}</Text>
        <Flex
          color='green'
          fontWeight='bold'
          fontSize='sm'
          textAlign='end'
          flexDir='row'
          alignItems='center'
        >
          <Text color='gray.600' mr='0.5rem' whiteSpace='nowrap' ml='1rem'>
            In the past week
          </Text>
          <Skeleton w='fit-content' height='20px' isLoaded={isLoaded}>
            +{formatNumber(increment)}{' '}
          </Skeleton>
        </Flex>
      </HStack>
      <Box h='70px'>
        <Skeleton isLoaded={isLoaded} w='100%' height='100%'>
          <AreaLineChart data={chartData} />
        </Skeleton>
      </Box>
    </>
  )
}
export const NumberContainer = ({ title, isLoaded, data, timeFrame }: any) => {
  return (
    <Box overflow='hidden' h='130px'>
      <HStack justifyContent='space-between' pb='1rem' px='1rem'>
        <Text fontWeight='semibold'>{title}</Text>
        <Flex
          color='green'
          fontWeight='bold'
          fontSize='sm'
          textAlign='end'
          flexDir='row'
          alignItems='center'
        >
          <Text color='gray.600' mr='0.5rem' whiteSpace='nowrap' ml='1rem'>
            In the past {timeFrame}
          </Text>
        </Flex>
      </HStack>
      <Flex w='100%' justifyContent='center'>
        <Skeleton w='fit-content' height='20px' isLoaded={isLoaded}>
          <Text fontSize='3xl' fontWeight='bold' color='green.500'>
            +{formatNumber(data)}{' '}
          </Text>
        </Skeleton>
      </Flex>
    </Box>
  )
}

function getChartStructData(data: any) {
  const weeksKeys = Object.keys(data || {}).sort() || []
  const totalWeeks = weeksKeys.length
  const lastWeek = data?.[weeksKeys[totalWeeks - 2]] ?? 0
  const actualWeek = data?.[weeksKeys[totalWeeks - 1]] ?? 0
  const increment = actualWeek - lastWeek
  const chartData = weeksKeys.reduce(
    (acc: AreaLineChartItemType[], weekData: string, index: number) => {
      return [...acc, { name: weekData, value: data?.[weekData] ?? 0 }]
    },
    []
  )
  return { increment, chartData }
}

export const SummaryContainer = ({
  title,
  data,
  isLoaded,
  type,
  formatNumbers,
  timeFrame = 'week',
}: {
  title: string
  data?: SummaryDataType
  isLoaded: boolean
  type: SummaryType
  formatNumbers?: (x: number) => number | string
  timeFrame?: string
}) => {
  const ContainerType = {
    [SummaryType.LINE]: (
      <GraphContainer
        isLoaded={isLoaded}
        data={getChartStructData(data)}
        title={title}
      />
    ),
    [SummaryType.RANK]: (
      <RankingContainer
        isLoaded={isLoaded}
        data={data}
        title={title}
        formatNumbers={formatNumbers}
      />
    ),
    [SummaryType.NUMBER]: (
      <NumberContainer
        isLoaded={isLoaded}
        data={data}
        title={title}
        formatNumbers={formatNumbers}
        timeFrame={timeFrame}
      />
    ),
  }[type]

  return (
    <Box
      pt='1rem'
      border='2px solid'
      borderColor='gray.100'
      bg='white'
      borderRadius='0.5rem'
    >
      {ContainerType}
    </Box>
  )
}
