import { INavigationMenu } from 'interfaces/navigationMenu.interface'

export type Covid19CommercialVaccinesSlugs =
  | ''
  | 'deals'
  | 'deliveries'
  | 'price'
  | 'revenue'
  | 'by-vaccine'
  | 'forecasts'
  | 'country-dashboard'
  | 'donations'
  | 'vaccine-donations'
  | 'covax'
  | 'company-stated-production'
  | 'supply-agreements'
  | 'supply-by-deal'
  | 'by-country'
  | 'demand'
  | 'vaccinations-manufacturer'
  | 'vaccination-campaigns'
  | 'reports'
  | 'forecast-report'
  | 'reported-revenues'
  | 'production'

const Covid19CommercialVaccinesMenu: Array<
  INavigationMenu<Covid19CommercialVaccinesSlugs>[]
> = [
  [
    {
      title: 'Dashboard',
      slug: '',
      description:
        'Latest time series data and forecasts on vaccine production by manufacturers and by country.',
      createdAt: new Date(2021, 5, 8),
    },
    {
      title: 'Reports',
      slug: 'reports',
      createdAt: new Date(2022, 3, 13),
    },
  ],
  [
    {
      title: 'Production',
      slug: 'production',
      description: '',
      createdAt: new Date(2023, 8, 15),
      children: [
        {
          title: 'Company Stated Production',
          slug: 'company-stated-production',
          createdAt: new Date(2023, 8, 15),
        },
      ],
    },
    {
      title: 'Deliveries',
      slug: 'deliveries',
      description:
        'See how many doses have been produced by each manufacturer, country and its forecasts.',
      createdAt: new Date(2021, 7, 9),
      children: [
        {
          title: 'By Vaccine',
          slug: 'by-vaccine',
          createdAt: new Date(2021, 7, 9),
        },
        {
          title: 'Deliveries by Country',
          slug: 'by-country',
          createdAt: new Date(2022, 1, 7),
        },
      ],
    },
    {
      title: 'Demand',
      slug: 'demand',
      description: 'Usage of vaccines over time.',
      createdAt: new Date(2022, 3, 16),
      children: [
        {
          title: 'Vaccinations by Manufacturer',
          slug: 'vaccinations-manufacturer',
          createdAt: new Date(2022, 2, 16),
        },
        {
          title: 'Vaccination campaigns around the world',
          slug: 'vaccination-campaigns',
          createdAt: new Date(2023, 10, 19),
        },
      ],
    },
    {
      title: 'Deals',
      slug: 'deals',
      description:
        'List of all the company production and supply deals, facilities and prices.',
      createdAt: new Date(2021, 5, 8),
      children: [
        {
          title: 'Price',
          slug: 'price',
          createdAt: new Date(2021, 5, 8),
        },
        {
          title: 'Supply Agreements',
          slug: 'supply-agreements',
          createdAt: new Date(2022, 5, 22),
        },
        {
          title: 'Supply By Deal ',
          slug: 'supply-by-deal',
          createdAt: new Date(2022, 1, 7),
        },
      ],
    },
    {
      title: 'Revenue',
      slug: 'revenue',
      createdAt: new Date(2023, 2, 9),
      children: [
        {
          title: 'Reported revenues',
          slug: 'reported-revenues',
          createdAt: new Date(2023, 5, 11),
        },
        {
          title: 'Forecast Report',
          slug: 'forecast-report',
          createdAt: new Date(2023, 2, 9),
        },
      ],
    },
    {
      title: 'Donations',
      slug: 'donations',
      description:
        'Interactive dashboard covering COVID-19 vaccine donations globally.',
      createdAt: new Date(2021, 9, 14),
      children: [
        {
          title: 'Vaccine Donations',
          slug: 'vaccine-donations',
          createdAt: new Date(2021, 10, 8),
        },
        {
          title: 'COVAX',
          slug: 'covax',
          createdAt: new Date(2021, 9, 14),
        },
      ],
    },
  ],
]

export { Covid19CommercialVaccinesMenu }
