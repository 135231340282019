import { UseInfiniteQueryResult } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import _ from 'lodash'
import { useCallback, useMemo, useRef, useState } from 'react'
import { TableState, SortingRule } from 'react-table'
import useDeepCompareEffect from 'use-deep-compare-effect'

import {
  usePreviousUserView,
  usePreviousUserViewMutation,
} from 'modules/Tables/localStorageApi'

import { useAppRoute } from 'routes/utils'

import useUserViews, {
  UserViewConfig,
  UseUserViewsReturn,
} from 'components/Table/useUserViews'

import { IResponseBase, Paginated } from 'api/types'
import useUserViewCreateMutation from 'api/useUserViewCreateMutation'
import useUserViewData from 'api/useUserViewData'
import useUserViewDeleteMutation from 'api/useUserViewDeleteMutation'
import useUserViewUpdateMutation from 'api/useUserViewUpdateMutation'

import { IModel } from 'interfaces/model.interface'
import {
  IBaseView,
  ISingleNavigationPage,
} from 'interfaces/navigationPage.interface'

import { defaultState, getTableStateFromModel } from 'config/common/model/state'

import { getCustomSortOrderFromModel, resolveModel } from 'utils/model'

import { SortRule, TableState as WebworkerTableState } from './types'
import { useCollectionDataRaw } from './useCollectionDataNew'

type PropTypes = {
  // Page Identifier
  page: ISingleNavigationPage<any, any>
  view: IBaseView<any, any>
}

export interface useTableReturn {
  collectionDataQuery: UseInfiniteQueryResult<
    Paginated<IResponseBase[]>,
    AxiosError<any>
  >
  tableState: TableState
  setTableState: React.Dispatch<React.SetStateAction<TableState<{}>>>
  onStateChange: (newState: TableState) => void
  userViewConfig: UserViewConfig
  useUserViewsReturn: UseUserViewsReturn
}

export default function useTableData({
  page,
  view,
}: PropTypes): useTableReturn {
  /** Get Various Variables */
  const app = useAppRoute()

  // Model on page, before modification/override from views
  const pageModel = useMemo(() => page.model, [page])

  // Current model that we should use for all calculation
  const model: IModel<any> = useMemo(
    () => resolveModel(pageModel, view),
    [view, pageModel]
  )

  const initialState = useMemo(
    () => ({
      ...defaultState,
      ...getTableStateFromModel(model),
    }),
    [model]
  )

  /** All the various states */
  const [tableState, setTableState] = useState<TableState>(initialState)
  /** Flag to indicate whether state changes has finished */
  const [stateIsReady, setStateIsReady] = useState<boolean>(true)

  // This is the value returned from the hook
  // We delay value change until the data is back to reduce unneccesary renders
  const debouncedTableState = useRef<TableState>(tableState)

  // ========================================================
  // ===================== User Views =======================
  // ========================================================

  const { data: previousUserViewsData } = usePreviousUserView()
  const { mutate: mutatePreviousUserView } = usePreviousUserViewMutation()

  const userViewQuery = useUserViewData(app, page.key, initialState)
  const { mutate: createUserView } = useUserViewCreateMutation(app, page.key)
  const { mutate: updateUserView } = useUserViewUpdateMutation(app, page.key)
  const { mutate: deleteUserView } = useUserViewDeleteMutation(app, page.key)

  useDeepCompareEffect(() => {
    setStateIsReady(false)
  }, [view])

  const onUserViewIndexChange = useCallback(
    (index: number | null) => {
      mutatePreviousUserView({
        ...previousUserViewsData,
        [app]: {
          ...(previousUserViewsData ? previousUserViewsData[app] : {}),
          [page.key]: index,
        },
      })
      setStateIsReady(false)
    },
    [previousUserViewsData, mutatePreviousUserView, app, page]
  )

  const initialSelectedUserViewId = useMemo(
    () => _.get(previousUserViewsData, `${app}.${page.key}`) ?? null,
    [previousUserViewsData, app, page]
  )

  const userViewConfig: UserViewConfig = useMemo(
    () => ({
      onUserViewIndexChange,
      initialSelectedUserViewId,
      userViewQuery,
      createUserView: (userView) => createUserView({ userView }),
      updateUserView: (id, userView) => updateUserView({ id, userView }),
      deleteUserView: (id) => deleteUserView({ id }),
    }),
    [
      onUserViewIndexChange,
      initialSelectedUserViewId,
      userViewQuery,
      createUserView,
      updateUserView,
      deleteUserView,
    ]
  )

  const setState = useCallback(
    (selectedViewState: TableState) => {
      if (setTableState) {
        setTableState({ ...defaultState, ...selectedViewState })
        setStateIsReady(true)
      }
    },
    [setTableState]
  )

  const useUserViewsReturn = useUserViews({
    userViewConfig,
    setState,
    stateIsReady,
  })

  // ========================================================
  // ===================== Table Data =======================
  // ========================================================

  // Get the column keys
  const tableColumns = useMemo(
    () => model.schema.columns.map((x) => x.key),
    [model]
  )

  // Map the state to whatever state the webworker needs
  const mappedTableState: WebworkerTableState = mapStateFromTableState(
    tableState,
    tableColumns
  )

  // Get the data from webworker (or custom function if passed)
  const collectionDataQuery = useCollectionDataRaw(
    app,
    model.endpoint,
    {
      view: view.airtableName,
      airtableBase: view.airtableBase,
    },
    mappedTableState,
    {
      paginate: tableState.groupBy.length > 0 ? false : true,
      customSortOrder: getCustomSortOrderFromModel(model),
      grouped: !!tableState.groupBy && tableState.groupBy.length > 0,
      loadIncrementally: model.loadIncrementally,
      initialSort: mapStateFromTableStateSortBy(initialState.sortBy),
      key: page.key,
    },
    {
      customAPIFunction: model.customAPIFunction,
    },
    {
      onSettled: () => {
        debouncedTableState.current = tableState
      },
      keepPreviousData: true,
    }
  ) as UseInfiniteQueryResult<Paginated<IResponseBase[]>, AxiosError<any>>

  const onStateChange = useCallback(
    (newState: TableState) => {
      if (!_.isEqual(newState, tableState)) {
        setTableState(newState)
        setStateIsReady(true)
      }
    },
    [setTableState, tableState]
  )

  return {
    tableState,
    setTableState,
    onStateChange,
    collectionDataQuery,
    userViewConfig,
    useUserViewsReturn,
  }
}

export const mapStateFromTableState = (
  tableState: TableState,
  tableColumns: string[]
): WebworkerTableState => {
  return {
    sortBy: mapStateFromTableStateSortBy(tableState.sortBy),
    pageIndex: tableState.pageIndex,
    perPage: tableState.pageSize,
    advancedFilters: tableState.advancedFilters,
    schema: tableColumns,
    globalFilter: {
      searchString: tableState.globalFilter,
      columns: tableColumns,
    },
    extraState: {
      hiddenColumns: tableState.hiddenColumns,
      columnOrder: tableState.columnOrder,
      columnResizing: tableState.columnResizing,
    },
  }
}

const mapStateFromTableStateSortBy = (
  sortBy: SortingRule<{}>[]
): SortRule[] => {
  return sortBy?.map((rule) => ({
    id: rule.id,
    orderBy: rule.desc ? 'desc' : 'asc',
  }))
}
