import { ChevronDown } from '@carbon/icons-react'
import { Box, BoxProps, Text } from '@chakra-ui/react'
import { useContext } from 'react'

import { ExpandableContext } from './Expandable/ExpandableContainer'

interface PropTypes extends BoxProps {
  isActive?: boolean
  children?: React.ReactNode
  isDropdown?: boolean
  isDropdownChild?: boolean
  onClick?: () => void
  isFirst?: boolean
  isLast?: boolean
}

export default function PrimaryLink({
  isActive,
  children,
  isDropdown,
  isDropdownChild,
  onClick,
  isFirst,
  isLast,
}: PropTypes) {
  const { isOpen } = useContext(ExpandableContext)
  return (
    <Box
      width='100%'
      py={3}
      borderBottom={'1px solid'}
      borderColor='gray.200'
      cursor='pointer'
      marginTop={!isFirst && isActive ? '-1px' : 0}
      marginBottom={isActive ? '-.5px' : 0}
      borderTopRadius={isFirst ? '3px' : '0'}
      borderBottomRadius={
        isLast && isDropdownChild && isActive
          ? '3px'
          : !isDropdown && isLast
          ? '3px'
          : '0'
      }
      alignItems='center'
      fontSize='13px'
      overflowWrap='break-word'
      lineHeight='none'
      fontWeight={isActive ? 600 : 400}
      px={0}
      marginRight='10px'
      transition='all ease 0.1s'
      onClick={onClick}
      {...(isActive && {
        bg: 'yellow.500',
      })}
    >
      <Box
        display='flex'
        ml={isDropdownChild ? 8 : 4}
        lineHeight='18px'
        overflow='hidden'
      >
        <Text overflow='hidden' textOverflow='ellipsis'>
          {children}
        </Text>
        <Box
          display='flex'
          mr={4}
          alignItems='center'
          justifyContent='end'
          flex={1}
        >
          {isDropdown && (
            <Box
              transition='all 0.1s ease'
              transform={isOpen === true ? 'rotate(180deg)' : 'rotate(0)'}
            >
              <ChevronDown size={16} />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}
