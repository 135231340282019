import { IModel } from 'interfaces/model.interface'
import { ValueType } from 'interfaces/valueType.interface'

export type IDTreatments360RevenueData =
  | 'id'
  | 'organisations'
  | 'financialYear'
  | 'candidates'
  | 'quarter'
  | 'valueUsd'
  | 'areas'
  | 'diseases'
  | 'rssArticles'
  | 'dateStart'
  | 'dateEnd'
  | 'pathogenTypes'
  | 'designationA'
  | 'designationB'
  | 'sources'

const allowedAirtables = [
  'id_all',
  'rsv_commercial_revenue',
  'rsv_commercial_revenue_vaccines',
  'rsv_commercial_revenue_treatments',
] as const

export const IDPremiumRevenueModel: IModel<
  Record<IDTreatments360RevenueData, string>,
  typeof allowedAirtables
> = {
  allowedAirtables,
  displayKey: 'organisations',
  name: 'Revenue',
  entityName: 'Revenue',
  searchField: 'organisations',
  endpoint: 'revenue',
  defaultSortKey: 'organisations',
  schema: {
    columns: [
      {
        key: 'pathogenTypes',
        label: 'Pathogen Types',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'organisations',
        label: 'Company',
        type: ValueType.MULTI,
        width: 180,
      },
      {
        key: 'financialYear',
        label: 'Financial Year',
        type: ValueType.SINGLE,
        width: 120,
      },
      {
        key: 'quarter',
        label: 'Quarter',
        type: ValueType.SINGLE,
        width: 80,
      },
      {
        key: 'dateStart',
        label: 'Start',
        type: ValueType.DATE,
        width: 120,
      },
      {
        key: 'dateEnd',
        label: 'End',
        type: ValueType.DATE,
        width: 120,
      },
      {
        key: 'candidates',
        label: 'Candidate',
        type: ValueType.MULTI,
        width: 180,
      },
      {
        key: 'diseases',
        label: 'Diseases',
        type: ValueType.MULTI,
        width: 250,
      },
      {
        key: 'valueUsd',
        label: 'Revenue (USD)',
        type: ValueType.NUMBER,
        width: 150,
      },
      {
        key: 'areas',
        label: 'Location',
        type: ValueType.TEXT,
        width: 180,
      },
      {
        key: 'rssArticles',
        label: 'Source',
        type: ValueType.URL,
        width: 300,
      },
      {
        key: 'designationA',
        label: 'Vaccine or Treatment',
        type: ValueType.TEXT,
        width: 180,
      },
      {
        key: 'designationB',
        label: 'Category',
        type: ValueType.TEXT,
        width: 180,
      },
      {
        key: 'sources',
        label: 'Sources',
        type: ValueType.URL,
        width: 350,
      },
    ],
  },
}
