import { UseMutationOptions, useMutation } from '@tanstack/react-query'
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios'

import useAxios from './useAxios'

export default function useUpSellReport(
  extraProps?: UseMutationOptions<AxiosResponse<any>, AxiosError<any>, string>
) {
  const axios = useAxios()

  return useMutation<AxiosResponse<any>, AxiosError<any>, string>(
    (areOfInterest) => upSellReport(axios, areOfInterest),
    extraProps
  )
}

export const upSellReport = async (
  axios: AxiosInstance,
  areOfInterest: string
) => {
  return await axios.post(`/upgrade-request/`, {
    area_of_interest: areOfInterest,
  })
}
