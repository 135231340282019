import GenericTable from 'modules/Tables'

import { IModel } from 'interfaces/model.interface'
import {
  INavigationPages,
  ISingleNavigationPage,
} from 'interfaces/navigationPage.interface'

import { BioriskSlugs } from './BioriskMenu'
import {
  BioRiskMediaModel,
  BioRiskScientificPapersModel,
  BioRiskPressReleasesModel,
  OutbreakOverviewTrackerModel,
  BioriskDiseaseInformationModel,
} from './BioriskModels'

type PandemicNavigationPage<TModel extends IModel<any>> = ISingleNavigationPage<
  BioriskSlugs,
  TModel
>

export const VariantsScientificPapers: PandemicNavigationPage<
  typeof BioRiskScientificPapersModel
> = {
  key: 'SARSCoV2Variants',
  path: ['news', 'publications'],
  component: GenericTable,
  model: {
    ...BioRiskScientificPapersModel,
    endpoint: 'publication',
    name: 'Publications',
    excludeGeneratedCharts: true,
  },
  views: [
    {
      name: 'Default',
      airtableName: 'API Front End Export',
      airtableBase: 'idoutbreaks',
    },
  ],
}

const VariantsMedia: PandemicNavigationPage<typeof BioRiskMediaModel> = {
  key: 'Media',
  model: {
    ...BioRiskMediaModel,
    excludeGeneratedCharts: true,
    hideTableHeader: true,
    name: 'News Reports',
  },
  path: ['news', 'media'],
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableName: 'API Front End Export',
      airtableBase: 'idoutbreaks',
    },
  ],
}

const VariantsPressReleases: PandemicNavigationPage<
  typeof BioRiskPressReleasesModel
> = {
  key: 'PressReleases',
  model: {
    ...BioRiskPressReleasesModel,
    hideTableHeader: true,
    excludeGeneratedCharts: true,
  },
  component: GenericTable,
  path: ['news', 'press-releases'],
  views: [
    {
      name: 'Default',
      airtableName: 'API Front End Export',
      airtableBase: 'idoutbreaks',
    },
  ],
}
const OutbreakProgression: PandemicNavigationPage<
  typeof OutbreakOverviewTrackerModel
> = {
  key: 'Outbreak Progression',
  model: {
    ...OutbreakOverviewTrackerModel,
    hideTableHeader: true,
    excludeGeneratedCharts: true,
  },
  component: GenericTable,
  path: ['outbreak-progression'],
  views: [
    {
      name: 'Latest cases and deaths',
      airtableName: 'time_series_outbreak_tracker',
      airtableBase: 'lzdb',
    },
  ],
}

const DiseaseInformation: PandemicNavigationPage<
  typeof BioriskDiseaseInformationModel
> = {
  key: 'DiseaseInformation',
  model: {
    ...BioriskDiseaseInformationModel,
  },
  component: GenericTable,
  path: 'disease-information',
  views: [
    {
      name: 'Default',
      airtableName: 'disease_information',
      airtableBase: 'lzdb',
    },
  ],
}

export const BioriskPages: INavigationPages<BioriskSlugs> = {
  DiseaseInformation,
  VariantsScientificPapers,
  VariantsMedia,
  VariantsPressReleases,
  OutbreakProgression,
}
