import { Box, BoxProps } from '@chakra-ui/react'
import { CSSProperties, useCallback, useMemo, useRef } from 'react'
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
  WindowScroller,
} from 'react-virtualized'
import { MeasuredCellParent } from 'react-virtualized/dist/es/CellMeasurer'
import _ from 'underscore'

import { useMainBodyContainer } from 'contexts/MainBodyContainer'

import { InlineLoading } from 'components'

import useReports from 'api/cms/reports/useReports'

import { IApp } from 'interfaces/navigationApp.interface'

import ReportCard from './ReportCard'

type PropTypes = {
  app: IApp<any>
  extraKey?: string
}

const DashboardReports = ({
  app,
  extraKey = 'updates',
  ...rest
}: PropTypes & BoxProps) => {
  const reportsRes = useReports(app.slug, [extraKey])

  const isLoading = reportsRes.some((res) => res.isLoading)
  const isFetching = reportsRes.some((res) => res.isFetching)

  const data = useMemo(
    () =>
      reportsRes
        ?.map((reports) => reports?.data)
        .flat()
        .filter((report) => !!report)
        .filter((report) => !!report?.report),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading]
  )

  const { mainBodyRef } = useMainBodyContainer()

  const reports = useMemo(
    () =>
      !!data
        ? _.sortBy(data, function (o: any) {
            return o.date
          }).reverse()
        : [],
    [data]
  )

  const cellMeasurerCacheRef = useRef(
    new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 200,
    })
  )
  const cellMeasurerCache = cellMeasurerCacheRef.current

  const rowRenderer = useCallback(
    ({
      index,
      key,
      parent,
      style,
      className,
    }: {
      index: number
      key: string
      parent: MeasuredCellParent
      style: CSSProperties
      className?: string
    }) => {
      const report = reports[index]

      return (
        <CellMeasurer
          cache={cellMeasurerCache}
          columnIndex={0}
          key={key}
          rowIndex={index}
          parent={parent}
        >
          {({ measure, registerChild }) => {
            if (!report) return null
            return (
              <Box
                style={style}
                className={className}
                onLoad={measure}
                // @ts-ignore
                ref={registerChild}
              >
                <ReportCard onResize={measure} app={app} report={report} />
              </Box>
            )
          }}
        </CellMeasurer>
      )
    },
    [app, cellMeasurerCache, reports]
  )

  return (
    <Box minWidth='600px' {...rest}>
      {isLoading || isFetching ? (
        <InlineLoading my={8} />
      ) : reports && reports.length > 0 ? (
        <AutoSizer disableHeight>
          {({ width }) =>
            mainBodyRef?.current ? (
              <WindowScroller scrollElement={mainBodyRef.current}>
                {({ height, isScrolling, onChildScroll, scrollTop }) => {
                  return (
                    <List
                      overscanColumnCount={1}
                      overscanRowCount={1}
                      autoHeight
                      height={height || 0}
                      isScrolling={isScrolling}
                      onScroll={onChildScroll}
                      rowCount={reports.length}
                      deferredMeasurementCache={cellMeasurerCache}
                      rowHeight={cellMeasurerCache.rowHeight}
                      rowRenderer={rowRenderer}
                      scrollTop={scrollTop}
                      width={width}
                    />
                  )
                }}
              </WindowScroller>
            ) : null
          }
        </AutoSizer>
      ) : (
        <Box p={8} textAlign='center'>
          No updates posted yet.
        </Box>
      )}
    </Box>
  )
}

export default DashboardReports
