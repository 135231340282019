import { HStack, Link, Box, Text } from '@chakra-ui/react'
import { NAV_HEIGHT } from 'constants/misc'
import { Fragment, useContext } from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'
import { useMatch } from 'react-router-dom'

import { AppParams } from 'routes/utils'

import { AuthContext } from 'contexts'

import { useAppletGroupFromApplet } from 'config/appletGroups'
import { useAppletFromApp } from 'config/applets'
import { apps, appsListUnion } from 'config/apps'

const AppletSelector = ({ appParams }: { appParams: AppParams }) => {
  const route = useMatch('/:app/:page')
  const selectedAppSlug = appParams.app as appsListUnion

  const { appletsUserCanSee } = useContext(AuthContext)

  const selectedApp = apps[selectedAppSlug]
  const selectedApplet = useAppletFromApp(selectedAppSlug)
  const selectedAppletGroup = useAppletGroupFromApplet(selectedApplet?.key!)

  if (!selectedAppletGroup?.showApplets && !selectedApp?.globalReports) {
    return null
  }

  return (
    <HStack ml={10} height={NAV_HEIGHT} alignItems='center'>
      {selectedAppletGroup?.showApplets &&
        selectedAppletGroup?.applets.map((appletKey) => {
          const applet = appletsUserCanSee[appletKey]

          return (
            <Fragment key={appletKey}>
              {applet?.key === selectedApplet?.key ? (
                <Box
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                  fontWeight={600}
                  fontSize='14px'
                  lineHeight='16px'
                  bg='yellow.500'
                  height='100%'
                  color='black'
                  px={5}
                >
                  <Text>{applet?.name}</Text>
                </Box>
              ) : (
                <Link
                  _hover={{ textDecor: 'none' }}
                  as={ReactRouterLink}
                  to={`/${
                    applet?.apps.find(
                      (app) =>
                        apps[app].commonIdentifier ===
                        selectedApp.commonIdentifier
                    ) ?? applet?.apps[0]
                  }`}
                  px={5}
                  height='100%'
                  fontWeight={500}
                  fontSize='14px'
                  lineHeight='16px'
                  alignItems='center'
                  display='flex'
                  justifyContent='center'
                >
                  {applet?.name}
                </Link>
              )}
            </Fragment>
          )
        })}
      {selectedApp.globalReports && (
        <Box h={'100%'} padding='10px 0 10px 10px'>
          <Link
            _hover={{ textDecor: 'none' }}
            as={ReactRouterLink}
            to={`/${selectedApp.slug}/reports`}
            px={5}
            height='100%'
            fontWeight={500}
            fontSize='14px'
            lineHeight='16px'
            alignItems='center'
            display='flex'
            justifyContent='center'
            color={route?.params.page === 'reports' ? 'yellow.500' : 'white'}
            borderLeft='2px solid white'
          >
            Reports
          </Link>
        </Box>
      )}
    </HStack>
  )
}

export default AppletSelector
