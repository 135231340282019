import FullReportPage from 'routes/common/FullReportPage'

import { TableauEmbed } from 'components'

import { ISingleCustomPage } from 'interfaces/navigationPage.interface'

import { RSVCommercialSlugs } from './RSVMarketProductionMenu'

const AbsoluteCases: ISingleCustomPage<RSVCommercialSlugs> = {
  path: ['demand', 'forecast'],
  component: () => (
    <TableauEmbed path='/RSVdemandplotsUpdated/GuideRSVvaccinedemandforecast' />
  ),
}

const Reports: ISingleCustomPage<RSVCommercialSlugs> = {
  path: '',
  component: () => <FullReportPage />,
}

const Revenue: ISingleCustomPage<RSVCommercialSlugs> = {
  path: ['revenue', 'revenue-dashboard'],
  component: () => <TableauEmbed path='/RSVRevenue/Revenue' />,
}

const Price: ISingleCustomPage<RSVCommercialSlugs> = {
  path: ['supply-price', 'price-dashboard'],
  component: () => <TableauEmbed path='/RSVPricing/RSVPricingDashboard' />,
}

export const RSVCommercialCustomPages: Record<
  string,
  ISingleCustomPage<RSVCommercialSlugs>
> = {
  AbsoluteCases,
  Reports,
  Price,
  Revenue,
}
