import { IModel } from 'interfaces/model.interface'
import { ValueType } from 'interfaces/valueType.interface'

export type TendersData =
  | 'tenderNoticeId'
  | 'title'
  | 'description'
  | 'postingDate'
  | 'deadline'
  | 'buyer'
  | 'buyerAddress'
  | 'email'
  | 'website'
  | 'documents'
  | 'areaName'

const allowedAirtables = ['flu_tender'] as const

export const TendersModel: IModel<
  Record<TendersData, string>,
  typeof allowedAirtables
> = {
  allowedAirtables,
  displayKey: 'tenderNoticeId',
  name: 'Tenders',
  entityName: 'Tenders',
  searchField: 'title',
  endpoint: 'tender',
  detailViewType: 'Generic',
  schema: {
    columns: [
      {
        key: 'tenderNoticeId',
        label: 'Tender ID',
        type: ValueType.TEXT,
        width: 200,
      },
      {
        key: 'title',
        label: 'Title',
        type: ValueType.TEXT,
        width: 300,
      },
      {
        key: 'description',
        label: 'Description',
        type: ValueType.TEXT,
        width: 300,
      },
      {
        key: 'postingDate',
        label: 'Date Posted',
        type: ValueType.DATE,
        width: 150,
      },
      {
        key: 'deadline',
        label: 'Deadline Date',
        type: ValueType.DATE,
        width: 150,
      },
      {
        key: 'buyer',
        label: 'Buyer Organisation',
        type: ValueType.TEXT,
        width: 150,
      },
      {
        key: 'buyerAddress',
        label: 'Buyer Address',
        type: ValueType.TEXT,
        width: 190,
      },
      {
        key: 'email',
        label: 'Email Associated',
        type: ValueType.TEXT,
        width: 190,
      },
      {
        key: 'website',
        label: 'Website',
        type: ValueType.URL,
        width: 181,
      },
      {
        key: 'documents',
        label: 'Documents',
        type: ValueType.URL,
        width: 181,
      },
      {
        key: 'areaName',
        label: 'Country',
        type: ValueType.TEXT,
        width: 180,
      },
    ],
  },
}
