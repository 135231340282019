import { Download, Launch } from '@carbon/icons-react'
import {
  Box,
  CloseButton,
  Flex,
  Heading,
  HStack,
  Icon,
  Text,
} from '@chakra-ui/react'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'

import { DownloadPdfReport, ViewPdfReport } from 'components'

import { Report } from 'api/types'

import useTracking from 'tracking/useTracking'

import { formatUtc } from 'utils/formatDate'

import UpSellSection from './UpSell'

interface ModalPropTypes {
  report: Report
  handleClose: () => void
}

const Modal = ({ handleClose, report }: ModalPropTypes) => {
  const [tracking] = useTracking()

  const title = report.title
  const content = report.content
  const url = report.report
  const date = formatUtc(new Date(report.date), 'dd MMMM yyyy')

  return (
    <>
      <Box position='fixed' w='full' overflow='hidden'>
        <Box bg='gray4' px={8} py={5}>
          <CloseButton
            onClick={handleClose}
            position='absolute'
            right={5}
            top={3}
            _hover={{ bg: 'none' }}
          />
          <Box maxW='2xl' mx='auto'>
            <Text variant='body3-bold' mb={1.5}>
              Report
            </Text>
            <Heading variant='h3'>{title}</Heading>
            <Text variant='body' mt={2}>
              {date}
            </Text>
          </Box>
        </Box>
        {url ? (
          <HStack
            px={8}
            gap={4}
            py={4}
            borderWidth='1px 0px 1px 0px'
            borderColor='gray3'
            bg='white'
          >
            <DownloadPdfReport
              triggerComponent={({ handleClick }) => (
                <Flex
                  onClick={handleClick}
                  cursor='pointer'
                  alignItems='center'
                  gap={2}
                >
                  <Box
                    border='1px solid'
                    w='22px'
                    height='22px'
                    textAlign='center'
                    borderRadius='full'
                  >
                    <Icon w='14px' as={Download} />
                  </Box>
                  <Text variant='body3'>Download PDF</Text>
                </Flex>
              )}
              url={url}
              title={title}
              onDownload={() => {
                tracking.downloadReport({
                  report: title,
                })
              }}
            />
            <ViewPdfReport
              triggerComponent={({ handleClick }) => (
                <Flex
                  cursor='pointer'
                  onClick={handleClick}
                  alignItems='center'
                  gap={2}
                >
                  <Box
                    border='1px solid'
                    w='22px'
                    height='22px'
                    textAlign='center'
                    borderRadius='full'
                  >
                    <Icon w='12px' as={Launch} />
                  </Box>
                  <Text variant='body3'>Open PDF</Text>
                </Flex>
              )}
              url={url}
              onView={() => {
                tracking.openReport({
                  report: title,
                })
              }}
            />
          </HStack>
        ) : (
          <HStack
            px={8}
            gap={4}
            py={4}
            borderWidth='1px 0px 1px 0px'
            borderColor='gray3'
            bg='white'
          >
            <UpSellSection areaOfInterest={report.apps} title={title} />
          </HStack>
        )}
      </Box>
      <Box marginTop='210px' overflow='scroll' px={8} py={6}>
        <ReactMarkdown rehypePlugins={[rehypeRaw]}>{content}</ReactMarkdown>
        <ViewPdfReport
          triggerComponent={({ handleClick }) => (
            <Text
              cursor='pointer'
              display='inline-block'
              fontSize='13px'
              lineHeight='20px'
              letterSpacing='0.01em'
              textDecoration='underline'
              color='data6'
              fontWeight={500}
              mt={5}
              onClick={handleClick}
            >
              Read More
            </Text>
          )}
          url={url}
          onView={() => {
            tracking.openReport({
              report: title,
            })
          }}
        />
      </Box>
    </>
  )
}

export default Modal
