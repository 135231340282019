import TabbedDisplay from 'modules/TabbedDisplay'

import DashboardSummaryComponent from 'routes/apps/treatments/SummaryComponent'
import FullReportPage from 'routes/common/FullReportPage'
import Dashboard from 'routes/dashboard'

import { TableauEmbed } from 'components'

import { ISingleCustomPage } from 'interfaces/navigationPage.interface'

import { getMediaDefaultWidget } from 'config/common/baseModel/Media'
import { getPresentationDefaultWidget } from 'config/common/baseModel/Presentations'
import { getPressReleasesDefaultWidget } from 'config/common/baseModel/PressReleases'

import { TreatmentsSlugs } from './TreatmentsMenu'
import { TreatmentsPages } from './TreatmentsPages'

const LatestNewsPage: ISingleCustomPage<TreatmentsSlugs> = {
  path: '',
  component: () => {
    return (
      <>
        <DashboardSummaryComponent />
        <Dashboard
          widgetTabs={[
            getMediaDefaultWidget({ view: TreatmentsPages['Media'].views[0] }),
            getPresentationDefaultWidget({
              view: TreatmentsPages['CandidatePapers'].views[0],
              model: TreatmentsPages.CandidatePapers.model,
            }),
            getPressReleasesDefaultWidget({
              view: TreatmentsPages['PressReleases'].views[0],
            }),
          ]}
        />
      </>
    )
  },
}

const CandidatesPipeline: ISingleCustomPage<TreatmentsSlugs> = {
  path: ['candidates', 'pipeline'],
  component: () => (
    <TableauEmbed
      path='/NEWTxCandidateOverview/NEWCOVID-19TreatmentsOverview'
      height='150vh'
    />
  ),
}

const DeepDive: ISingleCustomPage<TreatmentsSlugs> = {
  path: ['reports', 'full-reports'],
  component: () => <FullReportPage title='Full Reports' />,
}

const VariantTreatmentImpact: ISingleCustomPage<TreatmentsSlugs> = {
  path: ['results', 'variant-impact'],
  component: () => (
    <TabbedDisplay
      tabs={[
        {
          heading: 'Overview',
          body: <TableauEmbed path='/Variantvaccineheatmap/Dashboard1' />,
        },
        {
          heading: 'Degree of Impact',
          body: <TableauEmbed path='/Variant_Treatmentimpact/Dashboard1' />,
        },
      ]}
    />
  ),
}

const RecentTrialsMap: ISingleCustomPage<TreatmentsSlugs> = {
  path: ['trials', 'recent-trials-map'],
  component: () => (
    <TableauEmbed
      path='/Clinical_trials_COVID_Flu_RSV/Clinicaltrials_treatments'
      height='170vh'
    />
  ),
}

const RegulatoryDashboard: ISingleCustomPage<TreatmentsSlugs> = {
  path: ['approvals', 'dashboard'],
  component: () => (
    <TableauEmbed path='/TxS360/RegulatoryDashboard' height='120vh' />
  ),
}

const ProgressTrackerDashboard: ISingleCustomPage<TreatmentsSlugs> = {
  path: ['candidates', 'progress-tracker'],
  component: () => (
    <TabbedDisplay
      tabs={[
        {
          heading: 'Overall Progress',
          body: (
            <TableauEmbed
              path='/frontendcovidtxprogresstracker/OverallProgressbyPhase2'
              height='120vh'
            />
          ),
        },
        {
          heading: 'Metric Breakdown',
          body: (
            <TableauEmbed
              path='/frontendcovidtxprogresstracker/ProgressbyMetric'
              height='120vh'
            />
          ),
        },
      ]}
    />
  ),
}

export const TreatmentsCustomPages: Record<
  string,
  ISingleCustomPage<TreatmentsSlugs>
> = {
  LatestNewsPage,
  CandidatesPipeline,
  DeepDive,
  VariantTreatmentImpact,
  RecentTrialsMap,
  RegulatoryDashboard,
  ProgressTrackerDashboard,
}
