import { ChevronDown } from '@carbon/icons-react'
import {
  Menu,
  MenuButton,
  Button,
  MenuList,
  MenuItem,
  Text,
} from '@chakra-ui/react'
import { Link as ReactRouterLink } from 'react-router-dom'

import { AppParams } from 'routes/utils'

import { useAppletFromApp } from 'config/applets'
import { apps as APPS, appsListUnion } from 'config/apps'

type PropTypes = {
  appParams: AppParams
}

const FlavourSelector = ({ appParams }: PropTypes) => {
  const selectedAppSlug = appParams.app as appsListUnion
  const selectedApplet = useAppletFromApp(selectedAppSlug)
  const selectedApp = APPS[selectedAppSlug]

  if (selectedApplet?.apps.length === 1) {
    return (
      <Text
        marginLeft={5}
        textOverflow='ellipsis'
        overflow='hidden'
        color='black'
        py='10px'
        lineHeight='18px'
        fontWeight='500'
        fontSize='13px'
        whiteSpace='nowrap'
        mr='auto'
      >
        {selectedApp.name}
      </Text>
    )
  }

  return (
    <Menu autoSelect={false} offset={[0, -2]}>
      <MenuButton
        as={Button}
        textAlign='left'
        rightIcon={<ChevronDown size={16} />}
        borderColor='gray.200'
        color='black'
        _hover={{
          bg: 'none',
        }}
        rounded='sm'
        width='192px'
        borderRadius='4px'
        px={4}
        py='10px'
        height='38px'
        _focus={{
          boxShadow: 'none',
        }}
        fontWeight={400}
        fontSize='13px'
      >
        <Text
          textOverflow='ellipsis'
          overflow='hidden'
          color='black'
          lineHeight='18px'
        >
          {selectedApp.name}
        </Text>
      </MenuButton>
      <MenuList borderTopRadius='none' p={0} minWidth='192px'>
        {selectedApplet?.apps.map((appSlug, i) => (
          <MenuItem
            _hover={{
              bg: 'none',
            }}
            as={ReactRouterLink}
            to={`/${appSlug}`}
            p={0}
            key={appSlug}
            borderBottom={
              selectedApplet.apps.length - 1 === i ? 'none' : '1px solid'
            }
            borderColor='gray.200'
          >
            <Text
              fontSize='13px'
              px={4}
              py='10px'
              lineHeight='18px'
              overflow='hidden'
              textOverflow='ellipsis'
              whiteSpace='nowrap'
              {...(selectedAppSlug === appSlug && {
                fontWeight: 'semibold',
              })}
            >
              {APPS[appSlug].name}
            </Text>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  )
}

export default FlavourSelector
