import { useOktaAuth } from '@okta/okta-react'
import { useLayoutEffect } from 'react'

const Logout = () => {
  const { authState, oktaAuth } = useOktaAuth()

  useLayoutEffect(() => {
    if (authState && authState.isAuthenticated) {
      oktaAuth.signOut()
    } else {
      window.location.replace('/login')
    }
  }, [authState, oktaAuth])

  return null
}

export default Logout
