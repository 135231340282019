import { Box } from '@chakra-ui/react'
import { useOktaAuth } from '@okta/okta-react'
import OktaSignIn from '@okta/okta-signin-widget'
import '@okta/okta-signin-widget/css/okta-sign-in.min.css'
import { useCallback, useEffect } from 'react'
import { Navigate } from 'react-router-dom'

import config from 'config/okta'

const Login = ({
  onSuccess,
}: {
  onSuccess: (res: any) => Promise<void> | undefined
}) => {
  useEffect(() => {
    const { pkce, issuer, clientId, redirectUri, scopes, useClassicEngine } =
      config.oidc
    const widget = new OktaSignIn({
      baseUrl: issuer?.split('/oauth2')[0],
      clientId,
      redirectUri,
      logo: 'https://www.airfinity.com/img/logo/black.png',
      i18n: {
        en: {
          'primaryauth.title': 'Sign in',
        },
      },
      authParams: {
        pkce,
        issuer,
        display: 'page',
        responseMode: pkce ? 'query' : 'fragment',
        scopes,
      },
      useClassicEngine,
    })

    widget.renderEl({ el: '#sign-in-widget' }, onSuccess, (err) => {
      throw err
    })

    return () => widget.remove()
    // This ensures the widget render call is only called once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Box
      display='flex'
      alignItems='center'
      height='100vh'
      justifyContent='center'
      sx={{
        '#okta-sign-in.auth-container.main-container': {
          borderRadius: '16px',
          boxShadow: '-2px 2px 15px rgba(0, 0, 0, 0.05)',
        },
        '#okta-sign-in.auth-container .button-primary': {
          background: '#FFED00',
          border: 'none',
          boxShadow: 'none',
          color: 'black',
          fontWeight: 'bold',
          borderRadius: '8px',
        },
        '.beacon-container': {
          display: 'none',
        },
        '#okta-sign-in.auth-container .okta-sign-in-header': {
          borderBottom: 'none',
          paddingBottom: 0,
        },
        '#okta-sign-in .auth-org-logo': {
          maxHeight: '75px',
        },
        '#okta-sign-in .auth-content': {
          padding: ' 15px 42px 30px',
        },
        '#okta-sign-in .focused-input, #okta-sign-in .link.help:focus, #okta-sign-in input[type=radio]:focus+label, #okta-sign-in input[type=text]:focus':
          {
            boxShadow: 'none',
          },
        '#okta-sign-in.auth-container h3': {
          color: '#1F1F1F',
        },
        '#okta-sign-in .focused-input, #okta-sign-in .link.help:focus': {
          boxShadow: 'none',
        },
        '#okta-sign-inth-container input[type=submit]:focus': {
          boxShadow: 'none',
          borderColor: 'transparent',
        },
        '#okta-sign-in.auth-container .button-primary:focus': {
          backgroundColor: '#FFED00',
          color: 'black',
        },
        '#okta-sign-in.auth-container .button-primary:hover': {
          backgroundColor: '#FFED00',
          color: 'black',
        },
        '#okta-sign-in.auth-container .button-primary:active': {
          backgroundColor: '#FFED00',
          color: 'black',
        },
        '#okta-sign-in.auth-container .button.button-primary.link-button-disabled':
          {
            opacity: 0.4,
            backgroundColor: '#FFED00',
            color: 'black',
          },
        '#okta-sign-in.auth-container .button-primary:active, #okta-sign-in.auth-container .button-primary:focus, #okta-sign-in.auth-container .button-primary:hover':
          {
            backgroundColor: '#FFED00',
            backgroundImage: '#FFED00',
            color: 'black',
          },
      }}
    >
      <div id='sign-in-widget' />
    </Box>
  )
}

const LoginWrapper = () => {
  const { oktaAuth, authState } = useOktaAuth()

  const onSuccess = useCallback(
    (res: any) => {
      if (res.status === 'SUCCESS') {
        return oktaAuth.signInWithRedirect({
          sessionToken: res.tokens.accessToken,
        })
      }
    },
    [oktaAuth]
  )

  return authState?.isAuthenticated ? (
    <Navigate to={{ pathname: '/' }} replace />
  ) : (
    <Login onSuccess={onSuccess} />
  )
}
export default LoginWrapper
