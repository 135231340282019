import { Download } from '@carbon/icons-react'
import { Box, Flex, Spinner } from '@chakra-ui/react'
import { memo } from 'react'

import { DownloadPdfReport, ViewPdfReport } from 'components'

import { Report } from 'api/types'

import useTracking from 'tracking/useTracking'

function ReportViewOrDownload({ report }: { report: Report }) {
  const [tracking] = useTracking()
  if (!report) {
    return null
  }

  return (
    <Box borderBottomRadius='8px' bg='gray.50'>
      <Flex justifyContent='space-between' px={5} alignItems='center'>
        <DownloadPdfReport
          url={report.report}
          title={report.title}
          onDownload={() => {
            tracking.downloadReport({
              report: report.title,
            })
          }}
          triggerComponent={({ isLoading, disabled, handleClick }) => (
            <Flex
              fontWeight={500}
              fontSize='sm'
              lineHeight='none'
              alignItems='center'
              {...(isLoading
                ? {
                    textDecor: 'none',
                    cursor: 'default',
                    _hover: { textDecor: 'none' },
                  }
                : {})}
            >
              <Box
                py={4}
                borderTop='1px solid'
                borderColor='gray.100'
                display='flex'
                alignItems='center'
              >
                <Box
                  width='32px'
                  height='32px'
                  mr={2}
                  display='flex'
                  bg='white'
                  rounded='full'
                  shadow='sm'
                  alignItems='center'
                  justifyContent='center'
                  as='button'
                  onClick={handleClick}
                  disabled={disabled}
                >
                  <Download size={20} />
                </Box>
                {report.title}
              </Box>
              {isLoading && <Spinner ml={2} />}
            </Flex>
          )}
        />
        <ViewPdfReport
          url={report.report}
          onView={() => {
            tracking.openReport({
              report: report.title,
            })
          }}
        />
      </Flex>
    </Box>
  )
}

export default memo(ReportViewOrDownload)
