import { INavigationMenu } from 'interfaces/navigationMenu.interface'

export type PadSlugs =
  | ''
  | 'reports'
  | 'candidates'
  | 'table'
  | 'pipeline'
  | 'target-analyser'
  | 'publications'
  | 'trials'
  | 'all-data'
  | 'recent-trials-map'
  | 'regulatory'
  | 'countries'
  | 'treatments'
  | 'overview'
  | 'funding-efficacy'

const PadMenu: Array<INavigationMenu<PadSlugs>[]> = [
  [
    {
      title: 'Latest Information',
      slug: '',
      description: '',
      createdAt: new Date(2022, 6, 8),
    },
    {
      title: 'Reports',
      slug: 'reports',
      createdAt: new Date(2022, 6, 8),
    },
  ],
  [
    {
      title: 'Candidates',
      slug: 'candidates',
      description: '',
      createdAt: new Date(2022, 6, 8),
      children: [
        {
          title: 'Table',
          slug: 'table',
          createdAt: new Date(2022, 6, 8),
        },
        {
          title: 'Pipeline',
          slug: 'pipeline',
          createdAt: new Date(2022, 6, 8),
        },
        {
          title: 'Target Analyser',
          slug: 'target-analyser',
          createdAt: new Date(2022, 6, 8),
        },
      ],
    },
    {
      title: 'Trials',
      slug: 'trials',
      description: '',
      createdAt: new Date(2022, 6, 8),
      children: [
        {
          title: 'All Data',
          slug: 'all-data',
          createdAt: new Date(2022, 6, 8),
        },
        {
          title: 'Recent Trials Map',
          slug: 'recent-trials-map',
          createdAt: new Date(2022, 6, 8),
        },
      ],
    },
    {
      title: 'Regulatory',
      slug: 'regulatory',
      description: '',
      createdAt: new Date(2022, 6, 8),
      children: [
        {
          title: 'Overview',
          slug: 'overview',
          createdAt: new Date(2022, 6, 8),
        },
        {
          title: 'By Treatment',
          slug: 'treatments',
          createdAt: new Date(2022, 6, 8),
        },
        {
          title: 'By Country',
          slug: 'countries',
          createdAt: new Date(2022, 6, 8),
        },
      ],
    },
    {
      title: 'Funding Efficacy',
      slug: 'funding-efficacy',
      createdAt: new Date(2022, 6, 8),
    },
    {
      title: 'Publications',
      slug: 'publications',
      description: '',
      createdAt: new Date(2022, 6, 8),
    },
  ],
]

export { PadMenu }
