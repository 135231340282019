import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
} from '@tanstack/react-query'
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios'
import _ from 'lodash'

import { IBaseView } from 'interfaces/navigationPage.interface'

import { apps, appsListUnion } from 'config/apps'

// import { relationGetDisplayValue } from 'utils/relational'
import { queryBuilder, recursiveCamelCase } from './helper'
import {
  IBaseResponse,
  ICleanResponse,
  IResponseBase,
  Paginated,
} from './types'
import useAxios from './useAxios'

export type FetchCollectionQueries = Partial<{
  airtableBase: IBaseView<any>['airtableBase']
  view: string | null
  offset: number | null
  sort: string | null
  limit: number | null
  fields: string[] | null
  ids: string[] | null
}>

function getNextPageParam<TResponse extends IBaseResponse<any>>(
  lastPage: Paginated<TResponse[]>,
  allPages: Paginated<TResponse[]>[]
) {
  const totalRowsFetched = allPages.reduce(
    (acc, val) => acc + val.results.length,
    0
  )
  const totalOverall = lastPage.count

  if (totalRowsFetched === totalOverall) {
    return false
  }

  const offset = totalRowsFetched

  return offset
}

export default function useCollectionData(
  app: appsListUnion,
  collection: string,
  queries: FetchCollectionQueries,
  extraProps?: UseInfiniteQueryOptions<any, any>
): UseInfiniteQueryResult<Paginated<ICleanResponse<any>[]>, AxiosError<any>> {
  const axios = useAxios()

  const query = useInfiniteQuery<Paginated<ICleanResponse<any>[]>, AxiosError>(
    ['collectionData', app, collection, queries],
    ({ pageParam: offset }) => {
      return fetchCollection(axios, app, collection, { ...queries, offset })
    },
    {
      getNextPageParam,
      ...extraProps,
    }
  )
  return query
}

export function useCollectionDataRaw(
  app: appsListUnion,
  collection: string,
  queries: Omit<FetchCollectionQueries, 'offset'>,
  extraProps?: UseInfiniteQueryOptions<any, any>,
  page?: any
) {
  const axios = useAxios()
  const query = useInfiniteQuery<Paginated<IResponseBase<any>[]>, AxiosError>(
    ['collectionDataRaw', app, collection, queries],
    ({ pageParam: offset }) => {
      return fetchCollectionRaw(axios, app, collection, { ...queries, offset })
    },
    {
      getNextPageParam,
      ...extraProps,
    }
  )
  return query
}

export const fetchCollectionRaw = async (
  axios: AxiosInstance,
  app: appsListUnion,
  collection: string,
  queries: FetchCollectionQueries,
  endpoint?: string
): Promise<Paginated<IResponseBase<any>[]>> => {
  const appEndpoint = endpoint || apps[app].endpoint
  const { airtableBase, ...queryStringObj } = queries

  let res: AxiosResponse<any>[]

  if (queries.ids) {
    // Handle if ids are too much (need to split them)
    const idChunks = _.chunk(queries.ids, 250)

    res = await Promise.all(
      idChunks.map((idList) => {
        const query = queryBuilder({ ...queryStringObj, ids: idList })
        return axios(
          `${airtableBase ?? 'covid'}/${appEndpoint}/${collection}/?${query}`
        )
      })
    )
  } else {
    const query = queryBuilder(queryStringObj)
    res = [
      await axios(
        `${airtableBase ?? 'covid'}/${appEndpoint}/${collection}/?${query}`
      ),
    ]
  }

  let remappedData: Paginated<IResponseBase<any>[]>
  if (queries.limit) {
    const data = res.map((x) => x.data.results).flat()
    remappedData = {
      count: res.map((x) => x.data.count).reduce((acc, val) => acc + val, 0),
      results: data.map((value: any, index: number) => {
        const id = value.id || index.toString()
        return {
          id: id,
          ...value,
        }
      }),
    }
  } else {
    const data = res.map((x) => x.data).flat()
    remappedData = {
      count: data.length,
      results: data.map((value: any, index: number) => {
        const id = value.id || index.toString()
        return {
          id: id,
          ...value,
        }
      }),
    }
  }

  return recursiveCamelCase(remappedData)
}

// Map relational data so we don't have to deal with it everywhere
export const fetchCollection = async (
  axios: AxiosInstance,
  app: appsListUnion,
  collection: string,
  queries: FetchCollectionQueries,
  endpoint?: string
): Promise<Paginated<ICleanResponse<any>[]>> => {
  const data = await fetchCollectionRaw(
    axios,
    app,
    collection,
    queries,
    endpoint
  )
  const res = {
    count: data.count,
    results: data.results.map((row) =>
      Object.fromEntries(Object.entries(row).map(([key, val]) => [key, val]))
    ) as ICleanResponse[],
  }
  return res
}
