import { Box, Text, Flex } from '@chakra-ui/layout'
import _ from 'lodash'

import AlertCard from 'modules/Alerts'

import { useAppRoute } from 'routes/utils'

import { InlineLoading } from 'components'

import { useCountryAlerts } from 'api/cms/alerts/useCountryAlerts'

import { formatDate } from 'utils/formatDate'

const AlertsSection = () => {
  const selectedApp = useAppRoute()

  const { data: alerts, isFetched, isError } = useCountryAlerts(selectedApp)

  if (isError) {
    return (
      <Flex w='100%' alignItems='center' justifyContent='center'>
        Sorry, something went wrong loading the alerts
      </Flex>
    )
  }
  if (!alerts && isFetched) {
    return (
      <Flex w='100%' alignItems='center' justifyContent='center'>
        No alerts at the moment
      </Flex>
    )
  }
  if (!alerts) {
    return (
      <Flex w='100%' alignItems='center' justifyContent='center'>
        <InlineLoading />
      </Flex>
    )
  }

  const groupedAlerts = _.groupBy(alerts, (alert) => alert.date_published)

  return (
    <Flex w='100%' h='auto' flexDir={'column'}>
      <Flex alignItems='flex-end' mb='0.75rem'>
        <Text fontSize={['lg', 'lg', 'xl']} mr='0.5rem' fontWeight={'500'}>
          Alerts
        </Text>
      </Flex>
      <Box overflowY='auto'>
        {Object.keys(groupedAlerts).map((date) => {
          return (
            <Box mb='3'>
              <Text fontSize='sm' color='legacy-primary.400' w='fit-content'>
                {formatDate(new Date(date))}
                <Box h='1px' bg='legacy-primary.200' mb='2' mr='-1rem' />
              </Text>
              {groupedAlerts[date].map((alert, index) => {
                return <AlertCard key={index} alert={alert} />
              })}
            </Box>
          )
        })}
      </Box>
    </Flex>
  )
}

export default AlertsSection
