import { Box, Flex, HStack, Text } from '@chakra-ui/react'
import { Skeleton } from '@chakra-ui/react'

import { AreaLineChart, AreaLineChartItemType } from 'components'

import { SummaryDataType } from 'api/vaccines/useDashboardSummaryData'

import formatNumber from 'utils/formatNumber'

export const ChartContainer = ({
  title,
  data,
  isLoaded,
}: {
  title: string
  data?: SummaryDataType
  isLoaded: boolean
}) => {
  const weeksKeys = Object.keys(data || {}).sort() || []
  const totalWeeks = weeksKeys.length
  const lastWeek = data?.[weeksKeys[totalWeeks - 2]] ?? 0
  const actualWeek = data?.[weeksKeys[totalWeeks - 1]] ?? 0
  const increment = actualWeek - lastWeek
  const chartData = weeksKeys.reduce(
    (acc: AreaLineChartItemType[], weekData: string, index: number) => {
      return [...acc, { name: weekData, value: data?.[weekData] ?? 0 }]
    },
    []
  )
  return (
    <Box
      pt='1rem'
      border='2px solid'
      borderColor='gray.100'
      bg='white'
      borderRadius='0.5rem'
    >
      <Box overflow='hidden' h='130px'>
        <HStack justifyContent='space-between' pb='1rem' px='1rem'>
          <Text color='legacy-primary.500' fontWeight='semibold'>
            {title}
          </Text>
          <Flex
            color='green'
            fontWeight='bold'
            fontSize='sm'
            textAlign='end'
            flexDir='row'
            alignItems='center'
          >
            <Text color='gray.600' mr='0.5rem' whiteSpace='nowrap' ml='1rem'>
              In the past week
            </Text>
            <Skeleton w='fit-content' height='20px' isLoaded={isLoaded}>
              +{formatNumber(increment)}{' '}
            </Skeleton>
          </Flex>
        </HStack>
        <Box h='70px'>
          <Skeleton isLoaded={isLoaded} w='100%' height='100%'>
            <AreaLineChart data={chartData} />
          </Skeleton>
        </Box>
      </Box>
    </Box>
  )
}

export default ChartContainer
