import { Box, Text } from '@chakra-ui/react'
import { SIDEBAR_WIDTH } from 'constants/misc'
import queryString from 'query-string'
import { useContext } from 'react'
import {
  matchPath,
  NavLink,
  Navigate,
  Route,
  useLocation,
  useNavigate,
  Routes,
} from 'react-router-dom'

import Navigation from 'modules/Navigation'
import PrimaryLink from 'modules/Navigation/SidebarNavigation/PrimaryLink'

import { AuthContext } from 'contexts'

import APIPage from './api'
import SecurityPage from './security'

const Settings = () => {
  const { userCanAccessAPI } = useContext(AuthContext)

  const navigate = useNavigate()
  const location = useLocation()

  const ref = queryString.parse(location.search)?.ref as string

  return (
    <>
      <Navigation
        showSearchBar={false}
        onBackButton={() => (!!ref ? navigate(ref) : navigate('/'))}
      />
      <Box display='flex' width='100%' flex={1} overflow='hidden'>
        <Box
          overflow='auto'
          maxHeight='100%'
          width={SIDEBAR_WIDTH}
          display='flex'
          py={0}
          position='relative'
          flexDirection='column'
        >
          <Box py='2rem'>
            <Text
              fontSize='lg'
              fontWeight={600}
              mb={'2rem'}
              color='gray.900'
              textAlign='center'
            >
              Settings
            </Text>
            <NavLink to={`/settings/security` + location.search}>
              <PrimaryLink
                isActive={!!matchPath('/settings/security', location.pathname)}
              >
                Security
              </PrimaryLink>
            </NavLink>
            {userCanAccessAPI && (
              <NavLink to={`/settings/api` + location.search}>
                <PrimaryLink
                  isActive={!!matchPath('/settings/api', location.pathname)}
                >
                  API
                </PrimaryLink>
              </NavLink>
            )}
          </Box>
        </Box>
        <Box
          width={`calc(100% - ${SIDEBAR_WIDTH})`}
          display='flex'
          flex={1}
          pl={3}
          pr={3}
          overflowY='auto'
          flexDirection='column'
          pt={'2rem'}
        >
          <Routes>
            <Route path={`security`} element={<SecurityPage />} />
            {userCanAccessAPI && <Route path={`api`} element={<APIPage />} />}
            <Route
              path='*'
              element={
                <Navigate to={`/settings/security` + location.search} replace />
              }
            />
          </Routes>
        </Box>
      </Box>
    </>
  )
}

export default Settings
