import { IModel } from 'interfaces/model.interface'
import { ValueType } from 'interfaces/valueType.interface'

import DealType from '../tagColours/DealType'
import DealsCancelledColors from '../tagColours/DealsCancelledColors'
import DosingInterval from '../tagColours/DosingInterval'
import EconomyIncomeBand from '../tagColours/EconomyIncomeBand'
import TreatmentType from '../tagColours/TreatmentType'
import { GBPTransformFormat, USDTransformFormat } from '../transformValue/money'

export type DealsData =
  | 'title'
  | 'lookupVaccineName'
  | 'candidates'
  | 'afDealType'
  | 'lookupOrganisationName'
  | 'lookupCountryNames'
  | 'newsCount'
  | 'linkCandidateList'
  | 'afPricePerVaccineTreatment'
  | 'afDealId'
  | 'afPhase3TrialSize'
  | 'afSupplyNumber'
  | 'afPotentialExpansion'
  | 'afCostPerSupplyDealInDollars'
  | 'mediaUrls'
  | 'pressUrls'
  | 'organisations'
  | 'countries'
  | 'afExpectedProduction'
  | 'companyLocations'
  | 'companyRegions'
  | 'afFundingFigure'
  | 'afDeliveryToDate'
  | 'afDateDeliveryToDateWasLastUpdated'
  | 'luIncomeBand'
  | 'afExpectedProduction2022'
  | 'afTypeOfProduction'
  | 'afExpectedFulfillmentDate'
  | 'afPurchaseType'
  | 'linkFullApproval'
  | 'linkEmergencyApproval'
  | 'linkVaccineRejected'
  | 'linkCandidateList'
  | 'luSourceOfSupply'
  | 'afApprovalDate'
  | 'afDateDealMade'
  | 'afSupplyCertainty'
  | 'afPublicSupplyNotes'
  | 'afDeliveryCertainty'
  | 'afPublicDeliveryNotes'
  | 'afDonorCountry'
  | 'luCountrySourceOfSupply'
  | 'afDateExpectedProductionLastAnnounced'
  | 'afPublicExpectedProductionNotes'
  | 'organisationsOrRegulators'
  | 'afSupply2022'
  | 'notes'
  | 'afFundingDealType'
  | 'afFundingType'
  | 'afEstimatedPricePerDoseUsd'
  | 'afFundingFigureUsDollars'
  | 'lookupAfTrTypeFromBnf'
  | 'typeOfProductionDealTreatments'
  | 'companyProductionLocation'
  | 'countriesProductionToBeSuppliedTo'
  | 'manufacturerProductionPerMonth'
  | 'afExpectedStartDateProduction'
  | 'afEconomyIncomeBand'
  | 'afContinent'
  | 'supplyNumberType'
  | 'afImportType'
  | 'timeIntervalBetweenDoses'
  | 'indication'
  | 'rejected'
  | 'linkEndorsementOrAdviceIssued'
  | 'countryIncomeBandReceivingSupply'
  | 'afSourceOfSupplyCertainty'
  | 'afCovaxSource'
  | 'afCovaxSourceDonorCountry'
  | 'afRestrictionsToUse'
  | 'urlRestrictionsToUse'
  | 'urlRecommendingMixingDoses'
  | 'supplyDealCostUsd'
  | 'supplyDealCostCertainty'
  | 'pricePerDoseSource'
  | 'volume'
  | 'source'
  | 'linkSupplyDeal'
  | 'countryOrOrganisationGrantingDecision'
  | 'approvalType'
  | 'patientSetting'
  | 'supportingDocumentsUrlApprovals'
  | 'clinicalTrials'
  | 'parentChild'
  | 'luCountryPopulation'
  | 'lookupContinent'
  | 'twitterUrl'
  | 'afDonorIncomeBand'
  | 'linkManufacturer'
  | 'supplySourceCountry'
  | 'licensingAgreement'
  | 'recommendingHeterologousVaccinationPrimarySeries'
  | 'timeIntervalBetweenHeterologousVaccinations'
  | 'urlHeterologousVaccination'
  | 'afAgeGroup'
  | 'afDealContracted'
  | 'linkAfTrUniqueTreatmentNameToBnf'
  | 'linkRegions'
  | 'afExpectedProduction2021'
  | 'tx2021ProductionCertainty'
  | 'tx2022ProductionCertainty'
  | 'pressUrl'
  | 'mediaUrl'
  | 'afDecisionDate'
  | 'linkAfTrUniqueTreatmentNameToBnfFromLinkCandidateList'
  | 'lookupAfTrTypeFromBnfFromLinkCandidateList'
  | 'linkOrganisations'
  | 'linkCountries'
  | 'supplyContractedYear'
  | 'potentialExpansionContractedYear'
  | 'status'
  | 'targetVariant'
  | 'partyOne'
  | 'brandName'
  | 'dealCancelled'

const allowedAirtables = [
  'api_price_per_vaccine_treatment',
  'api_supply_deals',
  'api_supply_all',
  'api_production_deals',
  'api_price_per_diagnostic_test',
  'api_supply_deals_diagnostics',
  'api_production_deals_diagnostics',
  'price_and_cost_api',
  'api_supply_all_bilateral_deals',
  'api_supply_all_bilateral_donations',
  'api_supply_all_covax_deliveries',
  'tt_supply_deals_api',
  'tt_production_api',
] as const

export const DealsModel: IModel<
  Record<DealsData, string>,
  typeof allowedAirtables
> = {
  allowedAirtables,
  displayKey: 'title',
  name: 'Deals',
  entityName: 'Deals',
  searchField: 'lookupVaccineName',
  endpoint: 'deal',
  defaultSortKey: 'lookupVaccineName',
  schema: {
    columns: [
      {
        key: 'lookupVaccineName',
        label: 'Vaccine',
        type: ValueType.SINGLE,
        width: 450,
      },
      {
        key: 'candidates',
        label: 'Candidates',
        type: ValueType.MULTI,
        width: 160,
      },
      {
        key: 'afDealType',
        label: 'Deal type',
        type: ValueType.SINGLE,
        width: 230,
        cellFormat: {
          colours: DealType,
        },
      },
      {
        key: 'lookupOrganisationName',
        label: 'Organisations',
        width: 280,
        type: ValueType.SINGLE,
      },
      {
        key: 'lookupCountryNames',
        label: 'Countries',
        type: ValueType.MULTI,
        width: 250,
      },
      {
        key: 'newsCount',
        label: 'News count',
        type: ValueType.SINGLE,
        width: 250,
      },
      {
        key: 'linkCandidateList',
        label: 'Candidates',
        type: ValueType.MULTI,
        width: 250,
      },
      {
        key: 'afPricePerVaccineTreatment',
        label: 'Price per Vaccine Treatment in USD',
        type: ValueType.TEXT,
        width: 300,
      },
      { key: 'afDealId', label: 'Deal', type: ValueType.TEXT },
      {
        key: 'afPhase3TrialSize',
        label: 'Phase 3 Trial Size',
        type: ValueType.TEXT,
      },
      {
        key: 'afPricePerVaccineTreatment',
        label: 'Price Per Vaccine Treatment',
        type: ValueType.TEXT,
      },
      {
        key: 'afSupplyNumber',
        label: 'Total supply of COVID-19 vaccine doses',
        type: ValueType.NUMBER,
      },
      {
        key: 'afPotentialExpansion',
        label: 'Potential Expansion to order of COVID-19 vaccine doses',
        type: ValueType.NUMBER,
      },
      {
        key: 'afCostPerSupplyDealInDollars',
        label: 'Cost Per Deal In USD',
        type: ValueType.TEXT,
      },
      { key: 'mediaUrls', label: 'Media link', type: ValueType.URL },
      { key: 'pressUrls', label: 'Press link', type: ValueType.URL },
      {
        key: 'organisations',
        width: 280,
        label: 'Organisations',
        type: ValueType.MULTI,
      },
      {
        key: 'countries',
        label: 'Countries that have paid this price',
        type: ValueType.MULTI,
      },
      {
        key: 'afExpectedProduction',
        label: 'Most recent company stated production',
        type: ValueType.NUMBER,
      },
      {
        key: 'companyLocations',
        label: 'Company Locations',
        type: ValueType.MULTI,
      },
      {
        key: 'companyRegions',
        label: 'Regions that have paid this price',
        type: ValueType.MULTI,
      },
      {
        key: 'afFundingFigure',
        label: 'Funding Figure GBP',
        type: ValueType.TEXT,
        transformValue: GBPTransformFormat,
      },
      {
        key: 'afDeliveryToDate',
        label: 'Total deliveries to date of COVID-19 vaccine doses',
        type: ValueType.NUMBER,
        width: 150,
      },
      {
        key: 'afDateDeliveryToDateWasLastUpdated',
        label: 'Delivery date was last updated',
        type: ValueType.DATE,
        width: 250,
      },
      {
        key: 'luIncomeBand',
        label: 'Income Band of the Recipient Country',
        type: ValueType.TEXT,
        width: 180,
      },
      {
        key: 'afExpectedProduction2022',
        label: 'Expected production of COVID-19 vaccine doses in 2022',
        type: ValueType.NUMBER,
        width: 180,
      },
      {
        key: 'afTypeOfProduction',
        label: 'Type of Production',
        type: ValueType.MULTI,
        width: 180,
      },
      {
        key: 'afExpectedFulfillmentDate',
        label: 'Expected Fulfilment Date',
        type: ValueType.TEXT,
        width: 170,
      },
      {
        key: 'afPurchaseType',
        label: 'Purchase Type',
        type: ValueType.SINGLE,
        width: 150,
      },
      {
        key: 'linkFullApproval',
        label: 'Country granting a COVID-19 Vaccine full approval',
        type: ValueType.MULTI,
        width: 200,
      },
      {
        key: 'linkEmergencyApproval',
        label: 'Country granting COVID-19 Vaccine emergency use approval',
        type: ValueType.MULTI,
        width: 200,
      },
      {
        key: 'linkVaccineRejected',
        label: 'COVID-19 vaccine application rejected',
        type: ValueType.MULTI,
        width: 200,
      },
      {
        key: 'linkCandidateList',
        label: 'Candidate List',
        type: ValueType.MULTI,
        width: 200,
      },
      {
        key: 'luSourceOfSupply',
        label: 'Source of Supply',
        type: ValueType.MULTI,
        width: 240,
      },
      {
        key: 'afApprovalDate',
        label: 'Approval Date',
        type: ValueType.DATE,
        width: 150,
      },
      {
        key: 'afDateDealMade',
        label: 'Date Deal Made',
        type: ValueType.DATE,
        width: 200,
      },
      {
        key: 'afSupplyCertainty',
        label: 'Supply Certainty',
        type: ValueType.MULTI,
        width: 150,
      },
      {
        key: 'afPublicSupplyNotes',
        label: 'Supply Notes',
        type: ValueType.TEXT,
        width: 200,
      },
      {
        key: 'afDeliveryCertainty',
        label: 'Delivery Certainty',
        type: ValueType.MULTI,
        width: 150,
      },
      {
        key: 'afPublicDeliveryNotes',
        label: 'Delivery Notes',
        type: ValueType.TEXT,
        width: 200,
      },
      {
        key: 'afDonorCountry',
        label: 'Donor',
        type: ValueType.MULTI,
        width: 150,
      },
      {
        key: 'luCountrySourceOfSupply',
        label: 'Assumed Production Location of Delivered Doses',
        type: ValueType.MULTI,
        width: 180,
      },
      {
        key: 'afDateExpectedProductionLastAnnounced',
        label: 'Date production announced',
        type: ValueType.DATE,
        width: 130,
      },
      {
        key: 'afPublicExpectedProductionNotes',
        label: 'Expected Production Notes',
        type: ValueType.TEXT,
        width: 240,
      },
      {
        key: 'organisationsOrRegulators',
        label: 'Organisation granting COVID-19 vaccine approval',
        type: ValueType.MULTI,
        width: 200,
      },
      {
        key: 'afSupply2022',
        label: 'Supply beyond 2021',
        type: ValueType.TEXT,
        width: 200,
      },
      { key: 'notes', label: 'Notes', width: 300, type: ValueType.TEXT },
      {
        key: 'afFundingDealType',
        label: 'Funding Deal Type',
        width: 230,
        type: ValueType.MULTI,
      },
      {
        key: 'afFundingType',
        label: 'Funding Type',
        width: 150,
        type: ValueType.SINGLE,
      },
      {
        key: 'afEstimatedPricePerDoseUsd',
        label: 'Estimated Price Per Dose USD',
        width: 150,
        type: ValueType.TEXT,
        transformValue: USDTransformFormat,
      },
      {
        key: 'afFundingFigureUsDollars',
        label: 'Funding Figure US Dollars',
        width: 200,
        type: ValueType.NUMBER,
      },
      {
        key: 'lookupAfTrTypeFromBnf',
        label: 'Treatment type',
        width: 230,
        type: ValueType.MULTI,
        cellFormat: {
          colours: TreatmentType,
        },
      },
      {
        key: 'typeOfProductionDealTreatments',
        label: 'Production type',
        width: 250,
        type: ValueType.MULTI,
      },
      {
        key: 'companyProductionLocation',
        label: 'Location of production',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'countriesProductionToBeSuppliedTo',
        label: 'Countries production to be supplied to',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'manufacturerProductionPerMonth',
        label: 'Production per month',
        width: 200,
        type: ValueType.NUMBER,
      },
      {
        key: 'afExpectedStartDateProduction',
        label: 'Expected Production Start Date',
        width: 200,
        type: ValueType.DATE,
      },
      {
        key: 'afEconomyIncomeBand',
        label: 'Economy Income Band',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'afContinent',
        label: 'Continent',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'supplyNumberType',
        label: 'Supply Number Type',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'afImportType',
        label: 'Import Type',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'targetVariant',
        label: 'Target variant',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'timeIntervalBetweenDoses',
        label: 'Time Interval Between Doses',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'indication',
        label: 'Indication in COVID-19',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'rejected',
        label: 'Country rejecting authorisation',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'linkEndorsementOrAdviceIssued',
        label: 'Endorsement/positive advice issued',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'countryIncomeBandReceivingSupply',
        label: 'Country income receiving supply',
        width: 200,
        type: ValueType.MULTI,
        cellFormat: {
          colours: EconomyIncomeBand,
        },
      },
      {
        key: 'afSourceOfSupplyCertainty',
        label: 'Production Location Certainty',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'afCovaxSource',
        label: 'Source of COVAX Supply',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'afCovaxSourceDonorCountry',
        label: 'COVAX Donor',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'afRestrictionsToUse',
        label: 'Restrictions To Use',
        width: 200,
        type: ValueType.TEXT,
      },
      {
        key: 'urlRestrictionsToUse',
        label: 'Restrictions to use source link',
        width: 200,
        type: ValueType.URL,
      },
      {
        key: 'urlRecommendingMixingDoses',
        label: 'Recommending mixing doses source link',
        width: 200,
        type: ValueType.URL,
      },
      {
        key: 'supplyDealCostUsd',
        label: 'Supply Deal Cost',
        width: 150,
        type: ValueType.TEXT,
        transformValue: USDTransformFormat,
      },
      {
        key: 'supplyDealCostCertainty',
        label: 'Cost Certainty',
        width: 150,
        type: ValueType.SINGLE,
      },
      {
        key: 'pricePerDoseSource',
        label: 'Price/Cost Source Type',
        width: 200,
        type: ValueType.MULTI,
      },
      { key: 'volume', label: 'Volume', width: 150, type: ValueType.MULTI },
      { key: 'source', label: 'Source', width: 170, type: ValueType.URL },
      {
        key: 'linkSupplyDeal',
        label: 'Price From Supply Deal',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'countryOrOrganisationGrantingDecision',
        label: 'Country/Organisation',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'approvalType',
        label: 'Approval Type',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'patientSetting',
        label: 'Patient Setting',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'supportingDocumentsUrlApprovals',
        label: 'Approval Documents',
        width: 150,
        type: ValueType.URL,
      },
      {
        key: 'clinicalTrials',
        label: 'Trials Cited In Approval',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'parentChild',
        label: 'Parent Or Child',
        type: ValueType.TEXT,
        width: 200,
        showDetail: false,
        hiddenByDefault: true,
      },
      {
        key: 'luCountryPopulation',
        label: 'Country Population',
        type: ValueType.NUMBER,
        width: 150,
      },
      {
        key: 'lookupContinent',
        label: 'Continent where doses have been secured',
        type: ValueType.MULTI,
        width: 150,
      },
      {
        key: 'twitterUrl',
        label: 'Twitter Link',
        type: ValueType.URL,
        width: 180,
      },
      {
        key: 'afDonorIncomeBand',
        label: 'Income Band of the Donor Country',
        type: ValueType.MULTI,
        width: 160,
      },
      {
        key: 'linkManufacturer',
        label: 'Source Manufacturer',
        type: ValueType.MULTI,
        width: 160,
      },
      {
        key: 'supplySourceCountry',
        label: 'Supply Source Country',
        type: ValueType.MULTI,
        width: 180,
      },
      {
        key: 'licensingAgreement',
        label: 'Under licensing agreement',
        type: ValueType.MULTI,
        width: 190,
      },
      {
        key: 'recommendingHeterologousVaccinationPrimarySeries',
        label: 'Recommending Heterologous Vaccination for Primary Series',
        type: ValueType.TEXT,
        width: 200,
      },
      {
        key: 'timeIntervalBetweenHeterologousVaccinations',
        label: 'Time Interval Between Heterologous Vaccination',
        type: ValueType.MULTI,
        width: 220,
        cellFormat: {
          colours: DosingInterval,
        },
      },
      {
        key: 'urlHeterologousVaccination',
        label: 'URL Heterologous Vaccinations',
        type: ValueType.URL,
        width: 190,
      },
      {
        key: 'afAgeGroup',
        label: 'Age Group',
        type: ValueType.MULTI,
        width: 150,
      },
      {
        key: 'afDealContracted',
        label: 'In House vs. Contracted Production',
        type: ValueType.MULTI,
        width: 240,
      },
      {
        key: 'linkAfTrUniqueTreatmentNameToBnf',
        label: 'Treatment Name',
        type: ValueType.SINGLE,
        width: 180,
      },
      {
        key: 'linkRegions',
        label: 'Regions that have secured these doses',
        type: ValueType.MULTI,
        width: 180,
      },
      {
        key: 'afExpectedProduction2021',
        label: '2021 Production',
        type: ValueType.NUMBER,
        width: 180,
      },
      {
        key: 'tx2021ProductionCertainty',
        label: '2021 Production Certainty',
        type: ValueType.SINGLE,
        width: 180,
      },
      {
        key: 'tx2022ProductionCertainty',
        label: '2022 Production Certainty',
        type: ValueType.SINGLE,
        width: 180,
      },
      {
        key: 'pressUrl',
        label: 'Press Link',
        type: ValueType.URL,
      },
      {
        key: 'mediaUrl',
        label: 'Media Link',
        type: ValueType.URL,
      },
      {
        key: 'brandName',
        label: 'Brand name',
        type: ValueType.MULTI,
      },
      {
        key: 'partyOne',
        label: 'Party One',
        type: ValueType.MULTI,
      },
      {
        key: 'afDecisionDate',
        label: 'Decision date',
        type: ValueType.DATE,
        width: 180,
      },
      {
        key: 'linkAfTrUniqueTreatmentNameToBnfFromLinkCandidateList',
        label: 'Treatment Name',
        type: ValueType.MULTI,
        width: 180,
      },
      {
        key: 'lookupAfTrTypeFromBnfFromLinkCandidateList',
        label: 'Treatment Type',
        type: ValueType.MULTI,
        width: 180,
      },
      {
        key: 'linkOrganisations',
        width: 280,
        label: 'Organisations',
        type: ValueType.MULTI,
      },
      {
        key: 'linkCountries',
        width: 280,
        label: 'Country',
        type: ValueType.MULTI,
      },
      {
        key: 'status',
        width: 150,
        label: 'Status',
        type: ValueType.MULTI,
      },
      {
        key: 'supplyContractedYear',
        label: 'Supply Contracted For',
        width: 200,
        type: ValueType.SINGLE,
      },
      {
        key: 'potentialExpansionContractedYear',
        label: 'Potential Expansion For',
        width: 200,
        type: ValueType.SINGLE,
      },
      {
        key: 'dealCancelled',
        label: 'Status',
        width: 200,
        type: ValueType.SINGLE,
        cellFormat: {
          colours: DealsCancelledColors,
        },
      },
    ],
  },
}
