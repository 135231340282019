import { Box } from '@chakra-ui/react'

import PandemicDashboard from 'modules/Pandemic'
import EmergencyRiskDashboard from 'modules/Pandemic/EmergencyRisk'
import PandemicEpidemiology from 'modules/Pandemic/Epidemiology'
import OutbreakOverviewDashboards from 'modules/Pandemic/OutbreakOverview'

import FullReportPage from 'routes/common/FullReportPage'

import { RShinyEmbed } from 'components'

import { ISingleCustomPage } from 'interfaces/navigationPage.interface'

import { BioriskSlugs } from './BioriskMenu'

const Reports: ISingleCustomPage<BioriskSlugs> = {
  path: 'reports',
  component: () => <FullReportPage />,
}

const MainDashboard: ISingleCustomPage<BioriskSlugs> = {
  path: [''],
  component: () => <PandemicDashboard />,
}

const EpidData: ISingleCustomPage<BioriskSlugs> = {
  path: 'covid-19',
  component: PandemicEpidemiology,
}

const RestrictionsRisk: ISingleCustomPage<BioriskSlugs> = {
  path: null,
  component: () => (
    <Box mt='2rem'>
      <RShinyEmbed path='lockdown_risk_index_weekly' />
    </Box>
  ),
}
const OutbreakTracker: ISingleCustomPage<BioriskSlugs> = {
  path: ['outbreak-overview'],
  component: OutbreakOverviewDashboards,
}

const EmergencyRisk: ISingleCustomPage<BioriskSlugs> = {
  path: ['emergent-risk'],
  component: EmergencyRiskDashboard,
}

export const BioriskCustomPages: Record<
  string,
  ISingleCustomPage<BioriskSlugs>
> = {
  Reports,
  MainDashboard,
  EpidData,
  RestrictionsRisk,
  OutbreakTracker,
  EmergencyRisk,
}
