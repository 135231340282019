import { Flex, Heading, Text } from '@chakra-ui/react'

interface NothingFoundPropTypes {
  searchString: string
}

const NothingFound = ({ searchString }: NothingFoundPropTypes) => {
  return (
    <Flex px={8} py={4} flexDir='column'>
      <Heading variant='h3'>0 Results</Heading>
      <Text variant='body-bold' color='gray2'>
        We found no results for "{searchString}", let's try typing something
        else.
      </Text>
    </Flex>
  )
}

export default NothingFound
