import { Download } from '@carbon/icons-react'
import { Box, Flex, Icon, Skeleton } from '@chakra-ui/react'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { AxiosError, AxiosInstance } from 'axios'
import { useEffect, useMemo, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { Document, Page } from 'react-pdf'
import rehypeRaw from 'rehype-raw'

import { useAppRoute } from 'routes/utils'

import {
  DownloadPdfReport,
  FullPageError,
  InlineLoading,
  PageHeader,
  Select,
  ViewPdfReport,
} from 'components'
import { usePdfData } from 'components/PdfDownload/usePdfData'

import { recursiveCamelCase } from 'api/helper'
import useAxios from 'api/useAxios'

import { apps, appsListUnion } from 'config/apps'

type ProcurementReport = {
  id: number
  country: string
  description: string
  pdf: string
}
const fetchProcurementReports = async (
  axios: AxiosInstance,
  app: appsListUnion
): Promise<ProcurementReport[]> => {
  return axios
    .get(`/${apps[app].endpoint}/procurement-and-reimbursement/`)
    .then((res) => recursiveCamelCase(res.data))
}

function useProcurementReports(
  app: appsListUnion,
  extraProps?: UseQueryOptions<any, any>
) {
  const axios = useAxios()

  return useQuery<ProcurementReport[], AxiosError>(
    ['procurement-and-reimbursement', app],
    () => fetchProcurementReports(axios, app),
    { ...extraProps, enabled: !!app }
  )
}

const sortCountries = (countryData: ProcurementReport[]) => {
  const sortedData = countryData
    .map((item: ProcurementReport) => item.country)
    .sort()
  // We want G20 to be the first option in the list of countries so we move it to the front
  // Requested by Salvini
  if (sortedData.indexOf('G20') > -1) {
    sortedData.splice(sortedData.indexOf('G20'), 1)
    sortedData.unshift('G20')
  }
  return sortedData.map((country) => ({
    label: country,
    value: country,
  }))
}

type CountryOptionType = { label: string; value: string }

const ProcurementAndReimbursementArchetypes = () => {
  const selectedApp = useAppRoute()
  const selectedAppObject = useMemo(() => apps[selectedApp], [selectedApp])
  const [selectedCountry, setSelectedCountry] = useState<
    CountryOptionType | undefined
  >(undefined)

  const { data, isLoading, isError } = useProcurementReports(
    selectedAppObject.slug,
    {
      onSuccess: (data) => {
        setSelectedCountry(sortCountries(data)[0])
      },
    }
  )

  const countriesList = useMemo(() => {
    if (data) {
      return sortCountries(data)
    }
  }, [data])

  const report = useMemo(() => {
    return data?.filter((report) => report.country === selectedCountry?.value)
  }, [data, selectedCountry])

  const { data: pdfData, refetch: loadPdf } = usePdfData(
    report && report.length > 0 ? report[0].pdf : ''
  )

  useEffect(() => {
    report && report.length > 0 && loadPdf()
  }, [loadPdf, report])

  if (isError) {
    return <FullPageError />
  }

  return (
    <Box>
      <Flex justifyContent='space-between' pt={3}>
        <PageHeader title='Procurement And Reimbursement Archetypes' />

        {report && report.length > 0 && (
          <Flex gap='24px' alignItems='center'>
            <DownloadPdfReport
              url={report[0].pdf}
              title={`Procurement And Reimbursement Archetypes - ${selectedCountry?.label}`}
              triggerComponent={({ handleClick }) => (
                <Box fontSize='sm' cursor='pointer' onClick={handleClick}>
                  <Icon as={Download} mr={2} />
                  Download
                </Box>
              )}
            />
            <Box width='200px'>
              <Select
                options={countriesList}
                value={selectedCountry}
                onChange={setSelectedCountry}
              />
            </Box>
          </Flex>
        )}
      </Flex>

      {isLoading && <InlineLoading />}

      {report && report.length > 0 && pdfData && (
        <Flex
          border='1px solid'
          borderColor='gray3'
          borderRadius='8px'
          p={4}
          my={6}
          gap='24px'
        >
          <ViewPdfReport
            key={report[0].pdf}
            url={report[0].pdf}
            triggerComponent={({ handleClick }) => (
              <Box
                borderRadius='8px'
                overflow='hidden'
                minW='700px'
                height='393px'
                cursor='pointer'
                onClick={handleClick}
              >
                <Document
                  file={{ data: pdfData }}
                  loading={<Skeleton width='700px' height='433px' />}
                >
                  <Page width={700} pageNumber={1} />
                </Document>
              </Box>
            )}
          />
          <Box>
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>
              {report[0].description}
            </ReactMarkdown>
          </Box>
        </Flex>
      )}
    </Box>
  )
}

export default ProcurementAndReimbursementArchetypes
