import { IModel } from 'interfaces/model.interface'
import { ValueType } from 'interfaces/valueType.interface'

import { aggregateGetFirst } from 'utils/grouping'

import Impact from '../tagColours/Impact'
import Phase from '../tagColours/Phase'

export type TreatmentStudyDataMetaData =
  | 'name'
  | 'dose'
  | 'routeOfAdministration'
  | 'resultOverall'
  | 'afApprovalStatus'
  | 'primaryCompletionDate'
  | 'linkAfTrUniqueTreatmentNameToBnf'
  | 'articleLink'
  | 'acronym'
  | 'resultSummary'
  | 'presentations'
  | 'sourceUrl'
  | 'treatmentType'
  | 'trialLocations'
  | 'primaryOutcome'
  | 'resultStat'
  | 'sponsors'
  | 'clinicalTrialId'
  | 'clinicalTrialNctUrl'
  | 'publicationType'
  | 'afParentOrChild'
  | 'linkPress'
  | 'resultsSummary'
  | 'resultsStat'
  | 'rosRating'
  | 'priority'
  | 'interventionRelatedAe'
  | 'patientPopulation'
  | 'allocationRatio'
  | 'interventionNumberOfParticipants'
  | 'controlNumberOfParticipants'
  | 'linkFromMedia'
  | 'interimResults'
  | 'numberOfParticipantsTotal'
  | 'trialAcronym'
  | 'clinicalTrialTitle'
  | 'clinicalTrialPhase'
  | 'recruitmentStatus'
  | 'media'
  | 'stageOfInterventionNew'
  | 'candidateList'
  | 'inpatientOutpatient'
  | 'patientCharacteristics'
  | 'meanOrMedianAge'
  | 'interimOrFullResults'
  | 'primaryOutcomeSpecific'
  | 'govId'
  | 'lookupAfTrClinicalTrialStageFromBnf'
  | 'linkInfectiousDisease'
  | 'newOverallResultsSummary'
  | 'dataType'
  | 'titleFromClinicalTrials'
  | 'phasesCleanFromClinicalTrials'
  | 'stageOfInterventionFromClinicalTrials'
  | 'patientSettingFromClinicalTrials'
  | 'urlFromClinicalTrials'

const allowedAirtables = [
  'FE view for COVID trial results',
  'FE view for Long COVID results',
] as const

export const TreatmentStudyDataMetaModel: IModel<
  Record<TreatmentStudyDataMetaData, string>,
  typeof allowedAirtables
> = {
  allowedAirtables,
  endpoint: 'treatment-study-data-meta',
  name: 'Meta Science',
  entityName: 'Study Meta Data',
  detailViewType: 'Generic',
  displayKey: 'name',
  searchField: 'name',
  schema: {
    columns: [
      {
        key: 'name',
        label: 'name',
        type: ValueType.TEXT,
        width: 120,
        hiddenByDefault: true,
      },
      {
        key: 'routeOfAdministration',
        label: 'Route Of Administration',
        type: ValueType.MULTI,
        width: 230,
        cellFormat: {
          colours: {
            Oral: 'green',
            'Intravenous ': 'red',
            'Inhalation ': 'blue',
            'Subcutaneous  ': 'orange',
          },
        },
      },
      {
        key: 'resultOverall',
        label: 'Result Overall',
        type: ValueType.MULTI,
        cellFormat: {
          colours: {
            'Moderately better than standard of care': 'teal',
            'Better than standard of care': 'green',
            'Worse than standard of care': 'red',
            'Equal to standard of care': 'yellow',
          },
        },
        width: 210,
      },
      {
        key: 'afApprovalStatus',
        label: 'Approval Status',
        type: ValueType.SINGLE,
        aggregate: aggregateGetFirst,
        width: 160,
      },
      {
        key: 'primaryCompletionDate',
        label: 'Primary Completion Date',
        type: ValueType.DATE,
        width: 180,
      },
      {
        key: 'linkAfTrUniqueTreatmentNameToBnf',
        label: 'Treatment Name',
        type: ValueType.MULTI,
        //@ts-ignore
        aggregateValue: (values, row, column) => {
          if (row.original['afParentOrChild'] === 'Parent') {
            return values
          }
          return undefined
        },
        aggregate: aggregateGetFirst,
        width: 280,
      },
      {
        key: 'articleLink',
        label: 'Papers URL',
        type: ValueType.URL,
        width: 120,
      },
      {
        key: 'acronym',
        label: 'Acronym',
        type: ValueType.TEXT,
        width: 120,
      },
      {
        key: 'resultSummary',
        label: 'Result Summary',
        type: ValueType.TEXT,
        width: 240,
      },
      {
        key: 'presentations',
        label: 'Presentations',
        type: ValueType.MULTI,
        width: 180,
      },
      {
        key: 'sourceUrl',
        label: 'Source URL',
        type: ValueType.URL,
        width: 140,
      },
      {
        key: 'treatmentType',
        label: 'Treatment Type',
        type: ValueType.MULTI,
        aggregate: aggregateGetFirst,
        width: 280,
      },
      {
        key: 'trialLocations',
        label: 'Trial Locations',
        type: ValueType.MULTI,
        width: 120,
      },
      {
        key: 'primaryOutcome',
        label: 'Primary Outcome',
        type: ValueType.MULTI,
        width: 300,
      },
      {
        key: 'resultStat',
        label: 'Result Stat',
        type: ValueType.TEXT,
        width: 200,
      },
      {
        key: 'sponsors',
        label: 'Sponsors',
        type: ValueType.MULTI,
        width: 120,
      },
      {
        key: 'clinicalTrialId',
        label: 'Trial ID',
        type: ValueType.MULTI,
        width: 120,
      },
      {
        key: 'clinicalTrialNctUrl',
        label: 'Clinical Trial Link',
        type: ValueType.URL,
        width: 125,
      },
      {
        key: 'publicationType',
        label: 'Publication Type',
        type: ValueType.MULTI,
        width: 170,
      },
      {
        key: 'afParentOrChild',
        label: 'Relationship',
        showDetail: false,
        type: ValueType.TEXT,
        hiddenByDefault: true,
      },
      {
        key: 'linkPress',
        label: 'Press Release Link',
        type: ValueType.MULTI,
        width: 230,
      },
      {
        key: 'resultsSummary',
        label: 'Results Summary',
        type: ValueType.TEXT,
        width: 210,
      },
      {
        key: 'resultStat',
        label: 'Results Stat',
        type: ValueType.TEXT,
        width: 180,
      },
      {
        key: 'rosRating',
        label: 'Risk Of Bias Rating',
        type: ValueType.TEXT,
        width: 140,
      },
      {
        key: 'priority',
        label: 'Priority',
        type: ValueType.MULTI,
        width: 210,
      },
      {
        key: 'interventionRelatedAe',
        label: 'Intervention Related AE',
        type: ValueType.MULTI,
        width: 210,
        cellFormat: {
          colours: {
            'No difference': 'gray',
            'Less in treatment group': 'green',
            'More in treatment group': 'red',
          },
        },
      },
      {
        key: 'dose',
        label: 'Dose',
        type: ValueType.TEXT,
        width: 300,
      },
      {
        key: 'recruitmentStatus',
        label: 'Recruitement Status',
        type: ValueType.TEXT,
        width: 150,
      },
      {
        key: 'patientPopulation',
        label: 'Patient Population',
        type: ValueType.TEXT,
        width: 150,
      },
      {
        key: 'allocationRatio',
        label: 'Allocation Ratio',
        type: ValueType.TEXT,
        width: 150,
      },
      {
        key: 'interventionNumberOfParticipants',
        label: 'Intervention Number',
        type: ValueType.TEXT,
        width: 150,
      },
      {
        key: 'controlNumberOfParticipants',
        label: 'Control Number',
        type: ValueType.TEXT,
        width: 150,
      },
      {
        key: 'linkFromMedia',
        label: 'Media URL',
        type: ValueType.URL,
        width: 150,
      },
      {
        key: 'interimResults',
        label: 'Interim Results',
        type: ValueType.TEXT,
        width: 170,
      },
      {
        key: 'numberOfParticipantsTotal',
        label: 'Number of Participants',
        type: ValueType.NUMBER,
        width: 180,
      },
      {
        key: 'trialAcronym',
        label: 'Acronym',
        type: ValueType.TEXT,
        width: 120,
      },
      {
        key: 'clinicalTrialTitle',
        label: 'Trial Title',
        type: ValueType.TEXT,
        width: 310,
      },
      {
        key: 'clinicalTrialPhase',
        label: 'Trial Phase',
        type: ValueType.MULTI,
        width: 120,
        cellFormat: {
          colours: Phase,
        },
      },
      {
        key: 'media',
        label: 'Media',
        type: ValueType.MULTI,
        width: 120,
      },
      {
        key: 'stageOfInterventionNew',
        label: 'Stage Of Intervention',
        type: ValueType.MULTI,
        width: 200,
        cellFormat: {
          colours: Impact,
        },
      },
      {
        key: 'candidateList',
        label: 'Candidate List',
        type: ValueType.MULTI,
        width: 200,
      },
      {
        key: 'inpatientOutpatient',
        label: 'Patient Setting',
        type: ValueType.MULTI,
        width: 200,
      },
      {
        key: 'patientCharacteristics',
        label: 'Patient Characteristics',
        type: ValueType.MULTI,
        width: 200,
      },
      {
        key: 'meanOrMedianAge',
        label: 'Mean/Median Age',
        type: ValueType.NUMBER,
        width: 200,
      },
      {
        key: 'interimOrFullResults',
        label: 'Interim/Full Results',
        type: ValueType.MULTI,
        width: 200,
      },
      {
        key: 'primaryOutcomeSpecific',
        label: 'Specific Primary Outcome',
        type: ValueType.MULTI,
        width: 200,
      },
      {
        key: 'govId',
        label: 'Trial ID',
        type: ValueType.MULTI,
        width: 200,
      },
      {
        key: 'linkInfectiousDisease',
        label: 'Disease',
        type: ValueType.MULTI,
        width: 200,
      },
      {
        key: 'newOverallResultsSummary',
        label: 'Results Overall',
        type: ValueType.TEXT,
        width: 280,
      },
      {
        key: 'dataType',
        label: 'Data Type',
        type: ValueType.SINGLE,
        width: 200,
      },
      {
        key: 'lookupAfTrClinicalTrialStageFromBnf',
        label: 'Candidate Phase',
        type: ValueType.MULTI,
        width: 200,
        cellFormat: {
          colours: Phase,
        },
      },
      {
        key: 'titleFromClinicalTrials',
        label: 'Trial Title',
        type: ValueType.MULTI,
        width: 200,
      },
      {
        key: 'phasesCleanFromClinicalTrials',
        label: 'Trial Phase',
        type: ValueType.MULTI,
        width: 200,
      },
      {
        key: 'stageOfInterventionFromClinicalTrials',
        label: 'Stage Of Intervention',
        type: ValueType.MULTI,
        width: 200,
      },
      {
        key: 'patientSettingFromClinicalTrials',
        label: 'Patient Setting',
        type: ValueType.MULTI,
        width: 200,
      },
      {
        key: 'urlFromClinicalTrials',
        label: 'Trial Link',
        type: ValueType.URL,
        width: 200,
      },
    ],
  },
}
