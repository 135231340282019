import { Download } from '@carbon/icons-react'
import { Box, Flex, Icon, Skeleton, Text } from '@chakra-ui/react'
import { useEffect, useMemo } from 'react'
import ReactMarkdown from 'react-markdown'
import { Document, Page } from 'react-pdf'
import rehypeRaw from 'rehype-raw'

import { useAppRoute } from 'routes/utils'

import {
  DownloadPdfReport,
  FullPageError,
  InlineLoading,
  PageHeader,
  ViewPdfReport,
} from 'components'
import { usePdfData } from 'components/PdfDownload/usePdfData'

import useReports from 'api/cms/reports/useReports'

const CombinationVaccinesReport = () => {
  const selectedApp = useAppRoute()

  const reportsRes = useReports(selectedApp, ['combination-vaccines-report'])
  const reportsLoading = reportsRes.some((res) => res.isLoading)
  const reportsFetched = reportsRes.some((res) => res.isFetched)
  const isError = reportsRes.some((res) => res.isError)

  const report = useMemo(() => {
    if (reportsFetched) return reportsRes?.[0]?.data?.[0]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportsFetched])

  const { data: pdfData, refetch: loadPdf } = usePdfData(
    report ? report.report : ''
  )

  useEffect(() => {
    report?.report && loadPdf()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report])

  if (isError) {
    return <FullPageError />
  }
  return (
    <Box>
      <Flex justifyContent='space-between' pt={3}>
        <PageHeader title='Combination Vaccines Approval Timelines and Results' />
        {report?.report && (
          <Flex gap='24px' alignItems='center'>
            <DownloadPdfReport
              url={report?.report}
              title={report.title}
              triggerComponent={({ handleClick }) => (
                <Box fontSize='sm' cursor='pointer' onClick={handleClick}>
                  <Icon as={Download} mr={2} />
                  Download
                </Box>
              )}
            />
          </Flex>
        )}
      </Flex>

      {reportsLoading && <InlineLoading />}

      {report && pdfData && (
        <Flex
          border='1px solid'
          borderColor='gray3'
          borderRadius='8px'
          p={4}
          my={6}
          gap='24px'
        >
          <Box pos='relative'>
            <Box _hover={{ opacity: 0.6 }}>
              <ViewPdfReport
                key={report.report}
                url={report.report}
                triggerComponent={({ handleClick, pdfBytes }) => {
                  const data = pdfBytes || pdfData
                  return (
                    <Box
                      borderRadius='8px'
                      overflow='hidden'
                      minW='700px'
                      height='393px'
                      cursor='pointer'
                      onClick={handleClick}
                    >
                      {!data ? (
                        <Skeleton width='700px' height='433px' />
                      ) : (
                        <Document
                          file={{ data }}
                          loading={<Skeleton width='700px' height='433px' />}
                          error={<Skeleton width='700px' height='433px' />}
                        >
                          <Page
                            width={700}
                            pageNumber={1}
                            onError={() => console.log('error')}
                          />
                        </Document>
                      )}
                    </Box>
                  )
                }}
              />
            </Box>
            <Box pos='absolute' bottom={'0'} zIndex='1' w='full' opacity={'1'}>
              <Text
                fontSize={'md'}
                textAlign='center'
                textColor={'white'}
                opacity='1'
                fontWeight={'bold'}
              >
                Click on the image to open the Report
              </Text>
            </Box>
          </Box>
          <Box p='1rem'>
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>
              {report.content}
            </ReactMarkdown>
          </Box>
        </Flex>
      )}
    </Box>
  )
}

export default CombinationVaccinesReport
