import { ChevronLeft, ChevronRight } from '@carbon/icons-react'
import { Box, BoxProps, Flex, Icon, IconButton, VStack } from '@chakra-ui/react'
import { SIDEBAR_WIDTH } from 'constants/misc'
import { useState } from 'react'
import { Transition } from 'react-transition-group'

import { AppParams } from 'routes/utils'

import { useSidebar } from 'contexts/Sidebar'

import { Footer } from 'components'

import { IApp } from 'interfaces/navigationApp.interface'
import { INavigationMenu } from 'interfaces/navigationMenu.interface'

import { apps as APPS, appsListUnion } from 'config/apps'

import { groupMenu } from 'utils/menu'

import FlavourSelector from './FlavourSelector'
import NavigationEntry from './SidebarNavigation/NavigationEntry'

type PropTypes = {
  appParams: AppParams
  overflow?: BoxProps['overflow']
  onSelect?: () => void
}

const openTransitionStyles = {
  entering: { width: 0, opacity: 0 },
  entered: { width: '80px', opacity: 1 },
  exiting: { width: '80px', opacity: 1 },
  exited: { width: 0, opacity: 0 },
  unmounted: { width: 0, opacity: 0 },
}

const closeTransitionStyles = {
  entering: { width: 0, opacity: 0 },
  entered: { width: SIDEBAR_WIDTH, opacity: 1 },
  exiting: { width: SIDEBAR_WIDTH, opacity: 1 },
  exited: { width: 0, opacity: 0 },
  unmounted: { width: 0, opacity: 0 },
}

const defaultStyle = {
  transition: `all 500ms`,
}

const SidebarTriggerButton = () => {
  const { setSidebarOpened, sidebarOpened } = useSidebar()
  return (
    <IconButton
      width={sidebarOpened ? 5 : 8}
      position='absolute'
      top='21px'
      right={sidebarOpened ? '-15px' : '20px'}
      zIndex={10}
      height={sidebarOpened ? 5 : 8}
      icon={
        <Icon
          as={sidebarOpened ? ChevronLeft : ChevronRight}
          boxSize={sidebarOpened ? '14px' : '16px'}
        />
      }
      aria-label='Close Sidebar'
      onClick={() => setSidebarOpened(!sidebarOpened)}
      p={3}
      borderColor='gray.200'
      _hover={{
        bg: 'white',
      }}
      color='black'
      rounded='full'
    />
  )
}

const NavigationSidebarBody = ({
  appParams,
  onSelect = () => {},
}: PropTypes) => {
  const { sidebarOpened } = useSidebar()

  const selectedAppSlug = appParams.app as appsListUnion

  const selectedApp = APPS[selectedAppSlug]

  const combinedMenu = selectedApp.menu.flat()
  if (!sidebarOpened) {
    return (
      <Transition in={!sidebarOpened} timeout={0}>
        {(state) => (
          <VStack
            style={{
              ...defaultStyle,
              ...openTransitionStyles[state],
            }}
            border='none'
            paddingTop={4}
            position='relative'
            justifyContent='center'
            alignItems='center'
          >
            <SidebarTriggerButton />
          </VStack>
        )}
      </Transition>
    )
  }

  return (
    <Transition in={sidebarOpened} timeout={0}>
      {(state) => (
        <VStack
          style={{
            ...defaultStyle,
            ...closeTransitionStyles[state],
          }}
          spacing={4}
          borderRight='1px solid'
          borderColor='gray.200'
          overflow='visible'
          paddingTop={4}
          position='relative'
          alignItems='center'
          width={SIDEBAR_WIDTH}
        >
          <FlavourSelector appParams={appParams} />
          <SidebarTriggerButton />
          <Box
            display='flex'
            height='100%'
            position='relative'
            width={SIDEBAR_WIDTH}
            flexDirection='column'
            color='black'
            data-cy='sidebar-navigation-container'
            overflowY='auto'
          >
            <NavigationSidebarBodySection
              selectedApp={selectedApp}
              appParams={appParams}
              menus={combinedMenu ?? []}
              onSelect={onSelect}
            />
          </Box>
        </VStack>
      )}
    </Transition>
  )
}

type NavigationSidebarBodySectionPropTypes = {
  selectedApp: IApp<any>
  appParams: AppParams
  menus: INavigationMenu<any>[]
  onSelect: () => void
}

const NavigationSidebarBodySection = ({
  menus,
  appParams,
  onSelect,
}: NavigationSidebarBodySectionPropTypes) => {
  const { pageSlug } = appParams

  const selectedMenu = menus.find((menu) => menu.slug === pageSlug)

  const groupedMenu = groupMenu(menus)

  const [selectedGroup, setSelectedGroup] = useState(
    selectedMenu
      ? selectedMenu.grouping
        ? Object.keys(groupedMenu).indexOf(selectedMenu?.grouping)
        : Object.keys(groupedMenu).indexOf('Default')
      : 0
  )

  const hasMenu = !!Object.keys(groupedMenu)?.length

  const visibleMenus = menus.filter((menu) => !menu.isHidden)

  return (
    <Box display={'flex'} flexDirection={'column'}>
      {hasMenu && Object.keys(groupedMenu).length > 1 && (
        <Flex w='100%'>
          {Object.keys(groupedMenu).map((groupName, i) => (
            <Flex
              key={i}
              flex={1}
              p={1}
              alignItems='center'
              justifyContent='center'
              cursor='pointer'
              _hover={{ bg: 'gray.600' }}
              bg={selectedGroup === i ? 'yellow.500' : 'inherit'}
              onClick={() => setSelectedGroup(i)}
              borderBottom='1px solid'
              borderColor='gray.500'
              color={selectedGroup === i ? 'inherit' : 'white'}
            >
              {groupName}
            </Flex>
          ))}
        </Flex>
      )}
      <Box>
        {hasMenu && Object.keys(groupedMenu).length > 1
          ? Object.values(groupedMenu)[selectedGroup].map((menu, i) => {
              return (
                <NavigationEntry
                  key={i}
                  appParams={appParams}
                  menu={menu}
                  onSelect={onSelect}
                />
              )
            })
          : visibleMenus.map((menu, i) => {
              return (
                <NavigationEntry
                  key={i}
                  appParams={appParams}
                  menu={menu}
                  isFirst={i === 0}
                  isLast={i === visibleMenus.length - 1}
                  onSelect={onSelect}
                />
              )
            })}
      </Box>
      <Footer fromNav />
    </Box>
  )
}

export default (props: PropTypes) => {
  return <NavigationSidebarBody {...props} />
}
