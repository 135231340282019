import { IModel } from 'interfaces/model.interface'
import { ValueType } from 'interfaces/valueType.interface'

import CandidateType from 'config/common/tagColours/CandidateType'
import Phase from 'config/common/tagColours/Phase'
import RouteOfAdministration from 'config/common/tagColours/RouteOfAdministration'
import Target from 'config/common/tagColours/Target'

export type CardioCandidateData =
  | 'candidateName'
  | 'companies'
  | 'candidateType'
  | 'target'
  | 'phaseInActiveClinicalTrials'
  | 'routeOfAdministration'
  | 'frequencyOfDoseStructured'
  | 'doseInformationFromBnf'
  | 'usApprovedIndications'
  | 'ukAndEuApprovedIndications'
  | 'usFullLabel'
  | 'ukAndEuFullLabel'
  | 'priceRangePerDose'
  | 'costEffectivenessGuidelines'
  | 'primaryCompositeCardiovascularEndpointDefinition'
  | 'primCompCardioEndPointHrAnd95PercentCi'
  | 'numberNeededToTreatPrimaryCompositeEndpoint'
  | 'cardiovascularOutcomesTrialsAndStatus'
  | 'bnfUrl'
  | 'lookupCandidateClinicalTrials'
  | 'lookupPressReleases'
  | 'lookupCandidateMediaArticles'
  | 'lookupCandidateTweets'
  | 'lookupCandidatePublications'
  | 'conferencePresentations'
  | 'brandNames'
  | 'approvedPopulationsForLipidLowering'
  | 'effectsOnLdlc'
  | 'effectsOnTotalCholesterol'
  | 'effectsOnVldlc'
  | 'effectsOnNonHdlc'
  | 'effectsOnHdlc'
  | 'effectsOnApob'
  | 'effectsOnApocIii'
  | 'effectsOnTriglycerides'
  | 'effectsOnLpa'
  | 'deathFromCoronaryHeartDiseaseHrAnd95PercentCi'
  | 'myocardialInfarctionHrAnd95PercentCi'
  | 'strokeHrAnd95PercentCi'
  | 'hospitalisationDueToUnstableAnginaHrAnd95PercentCi'
  | 'cardiovascularDeathHrAnd95PercentCi'
  | 'coronaryRevascularisationHrAnd95PercentCi'
  | 'hospitalisationDueToHeartFailureHrAnd95PercentCi'
  | 'allCauseMortalityHrAnd95PercentCi'

const allowedAirtables = [
  'api_lipid_360',
  'api_lipid_lowering_efficacy',
  'api_cardiovascular_outcomes',
  'api_anti-thrombotics_candidate_overview',
] as const

export const CardioCandidateModel: IModel<
  Record<CardioCandidateData, string>,
  typeof allowedAirtables
> = {
  allowedAirtables,
  endpoint: 'candidate',
  name: 'Candidates',
  entityName: 'Candidates',
  searchField: 'candidateName',
  detailViewType: 'Generic',
  displayKey: 'candidateName',
  defaultSortKey: 'candidateName',
  schema: {
    columns: [
      {
        key: 'candidateName',
        label: 'Name',
        width: 150,
        type: ValueType.TEXT,
      },
      {
        key: 'companies',
        label: 'Primary Developers',
        width: 230,
        type: ValueType.MULTI,
      },
      {
        key: 'candidateType',
        label: 'Technology Type',
        width: 230,
        type: ValueType.MULTI,
        cellFormat: {
          colours: CandidateType,
        },
      },
      {
        key: 'target',
        label: 'Target Domains',
        width: 230,
        type: ValueType.MULTI,
        cellFormat: {
          colours: Target,
        },
      },
      {
        key: 'routeOfAdministration',
        label: 'Route Of Administration',
        width: 200,
        type: ValueType.MULTI,
        cellFormat: {
          colours: RouteOfAdministration,
        },
      },
      {
        key: 'frequencyOfDoseStructured',
        label: 'Frequency Of Dose Structured',
        width: 230,
        type: ValueType.MULTI,
      },
      {
        key: 'doseInformationFromBnf',
        label: 'Dose Information From BNF',
        width: 200,
        type: ValueType.TEXT,
      },
      {
        key: 'usApprovedIndications',
        label: 'US - Approved Indications',
        width: 200,
        type: ValueType.TEXT,
      },
      {
        key: 'ukAndEuApprovedIndications',
        label: 'UK & EU - Approved Indications',
        width: 200,
        type: ValueType.TEXT,
      },
      {
        key: 'usFullLabel',
        label: 'US - Full Label',
        width: 200,
        type: ValueType.TEXT,
      },
      {
        key: 'ukAndEuFullLabel',
        label: 'UK & EU - Full Label',
        width: 200,
        type: ValueType.TEXT,
      },
      {
        key: 'priceRangePerDose',
        label: 'Price Range Per Dose',
        width: 170,
        type: ValueType.MULTI,
      },
      {
        key: 'costEffectivenessGuidelines',
        label: 'Cost Effectiveness Guidelines',
        width: 200,
        type: ValueType.TEXT,
      },
      {
        key: 'primaryCompositeCardiovascularEndpointDefinition',
        label: 'Primary composite Cardiovascular Endpoint Definition',
        width: 250,
        type: ValueType.MULTI,
      },
      {
        key: 'primCompCardioEndPointHrAnd95PercentCi',
        label: 'Primary composite Cardiovascular end point HR & 95% CI',
        width: 200,
        type: ValueType.TEXT,
      },
      {
        key: 'numberNeededToTreatPrimaryCompositeEndpoint',
        label: 'Number Needed To Treat Primary Composite Endpoint',
        width: 200,
        type: ValueType.TEXT,
      },
      {
        key: 'cardiovascularOutcomesTrialsAndStatus',
        label: 'Cardiovascular Outcomes Trials & Status',
        width: 200,
        type: ValueType.TEXT,
      },
      { key: 'bnfUrl', label: 'BNF URL', width: 150, type: ValueType.URL },
      {
        key: 'lookupCandidateClinicalTrials',
        label: 'Candidate Clinical Trials',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'lookupCandidatePublications',
        label: 'Candidate Publications',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'lookupPressReleases',
        label: 'Press Releases',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'lookupCandidateMediaArticles',
        label: 'Candidate Media Articles',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'lookupCandidateTweets',
        label: 'Candidate Tweets',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'phaseInActiveClinicalTrials',
        label: 'Phase',
        width: 200,
        type: ValueType.MULTI,
        cellFormat: {
          colours: Phase,
        },
      },
      {
        key: 'conferencePresentations',
        label: 'Candidate Conference Presentations',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'brandNames',
        label: 'Brand Names',
        width: 190,
        type: ValueType.MULTI,
      },
      {
        key: 'approvedPopulationsForLipidLowering',
        label: 'Approved Populations For Lipid Lowering',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'effectsOnLdlc',
        label: 'Effects On LDL-C',
        width: 330,
        type: ValueType.TEXT,
      },
      {
        key: 'effectsOnTotalCholesterol',
        label: 'Effects On Total Cholesterol',
        width: 180,
        type: ValueType.TEXT,
      },
      {
        key: 'effectsOnVldlc',
        label: 'Effects On VLDL-C',
        width: 180,
        type: ValueType.TEXT,
      },
      {
        key: 'effectsOnNonHdlc',
        label: 'Effects On non-HDL-C',
        width: 180,
        type: ValueType.TEXT,
      },
      {
        key: 'effectsOnHdlc',
        label: 'Effects On HDL-C',
        width: 180,
        type: ValueType.TEXT,
      },
      {
        key: 'effectsOnApob',
        label: 'Effects On ApoB',
        width: 180,
        type: ValueType.TEXT,
      },
      {
        key: 'effectsOnApocIii',
        label: 'Effects On ApoC-III',
        width: 180,
        type: ValueType.TEXT,
      },
      {
        key: 'effectsOnTriglycerides',
        label: 'Effects On Triglycerides',
        width: 180,
        type: ValueType.TEXT,
      },
      {
        key: 'effectsOnLpa',
        label: 'Effects On Lp(a)',
        width: 180,
        type: ValueType.TEXT,
      },
      {
        key: 'deathFromCoronaryHeartDiseaseHrAnd95PercentCi',
        label: 'Death From Coronary Heart Disease Hr & 95% Ci',
        width: 150,
        type: ValueType.TEXT,
      },
      {
        key: 'myocardialInfarctionHrAnd95PercentCi',
        label: 'Myocardial Infarction HR & 95% CI',
        width: 150,
        type: ValueType.TEXT,
      },
      {
        key: 'strokeHrAnd95PercentCi',
        label: 'Stroke HR & 95% CI',
        width: 150,
        type: ValueType.TEXT,
      },
      {
        key: 'hospitalisationDueToUnstableAnginaHrAnd95PercentCi',
        label: 'Hospitalisation Due To Unstable Angina HR & 95% CI',
        width: 150,
        type: ValueType.TEXT,
      },
      {
        key: 'cardiovascularDeathHrAnd95PercentCi',
        label: 'Cardiovascular Death HR & 95% CI',
        width: 150,
        type: ValueType.TEXT,
      },
      {
        key: 'coronaryRevascularisationHrAnd95PercentCi',
        label: 'Coronary Revascularisation HR & 95% CI',
        width: 150,
        type: ValueType.TEXT,
      },
      {
        key: 'hospitalisationDueToHeartFailureHrAnd95PercentCi',
        label: 'Hospitalisation Due To Heart Failure HR & 95% CI',
        width: 150,
        type: ValueType.TEXT,
      },
      {
        key: 'allCauseMortalityHrAnd95PercentCi',
        label: 'All Cause Mortality HR & 95% CI',
        width: 150,
        type: ValueType.TEXT,
      },
    ],
  },
}
