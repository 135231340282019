import { Box, Link, SimpleGrid, Text, VStack } from '@chakra-ui/react'
import Case from 'case'
import { useContext } from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'

import { useAppParams } from 'routes/utils'

import { AuthContext } from 'contexts'

import useTracking from 'tracking/useTracking'

import {
  AppletGroupConfig,
  useAppletGroupFromApplet,
} from 'config/appletGroups'
import { useAppletFromApp } from 'config/applets'

export type AppsSelectorBodyPropTypes = {
  appletGroupsToShow: AppletGroupConfig[]
  onSelectApp?: () => void
}

const AppsSelectorBody = ({
  appletGroupsToShow,
  onSelectApp,
}: AppsSelectorBodyPropTypes) => {
  const appParams = useAppParams()
  const { app: currentApp, pageSlug: currentPageSlug } = appParams

  const selectedApplet = useAppletFromApp(currentApp)
  const selectedAppletGroup = useAppletGroupFromApplet(selectedApplet?.key!)

  const { appListUserCanAccess, appletsUserCanSee } = useContext(AuthContext)

  const [tracking] = useTracking()

  return (
    <VStack spacing='24px' alignItems='flex-start'>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3 }}
        width='100%'
        spacing='24px'
      >
        {appletGroupsToShow.map((appletGroup) => {
          const app = appListUserCanAccess.find(
            (app) =>
              app.slug === appletsUserCanSee[appletGroup.applets[0]]?.apps[0]
          )

          const currentlySelected = selectedAppletGroup?.key === appletGroup.key

          return (
            <VStack
              data-cy='app-card'
              justifyContent='space-between'
              border='1px solid'
              borderColor='gray.200'
              rounded='md'
              p='24px'
              boxShadow='sm'
              alignItems='flex-start'
              spacing={3}
              key={appletGroup.key}
              {...(currentlySelected ? { bgColor: 'gray4' } : {})}
            >
              <VStack alignItems='flex-start' spacing={1}>
                <Text color='black' fontSize='12px'>
                  {Case.title(appletGroup.category)}
                </Text>
                <Text color='black' fontSize='15px' fontWeight='500'>
                  {appletGroup.name}
                </Text>
                <Text fontSize='13px' color='gray1'>
                  {appletGroup.description}
                </Text>
              </VStack>

              <Box>
                {currentlySelected && (
                  <Text fontSize='12px' fontWeight={500}>
                    Already Open
                  </Text>
                )}
                {!currentlySelected && (
                  <Link
                    to={`/${app?.slug}`}
                    onClick={() => {
                      appParams &&
                        tracking.changeApp({
                          fromApp: currentApp,
                          fromPage: currentPageSlug,
                          toApp: app?.slug,
                        })
                      onSelectApp?.()
                    }}
                    as={ReactRouterLink}
                    py={2}
                    px={4}
                    fontWeight={500}
                    fontSize='12px'
                    rounded='sm'
                    bgColor='yellow.500'
                    color='black'
                    variant='button'
                    _hover={{
                      textDecor: 'none',
                      boxShadow: 'base',
                    }}
                    data-cy='open-app-button'
                  >
                    Open App
                  </Link>
                )}
              </Box>
            </VStack>
          )
        })}
      </SimpleGrid>
    </VStack>
  )
}

export default AppsSelectorBody
