import { Box } from '@chakra-ui/react'

import OverviewTab from 'modules/Pandemic/Epidemiology/OverviewTab'
import TabbedDisplay from 'modules/TabbedDisplay'

import FullReportPage from 'routes/common/FullReportPage'
import Dashboard from 'routes/dashboard'

import {
  Panel,
  PanelBody,
  PanelHeader,
  PanelTitle,
  RShinyEmbed,
  TableauEmbed,
} from 'components'

import { ISingleCustomPage } from 'interfaces/navigationPage.interface'

import { getMediaDefaultWidget } from 'config/common/baseModel/Media'
import { getPressReleasesDefaultWidget } from 'config/common/baseModel/PressReleases'

import { EpidemiologySlugs } from './EpidemiologyMenu'
import { EpidemiologyPages, LongCovidPages } from './EpidemiologyPages'

const OverviewAndRisk: ISingleCustomPage<EpidemiologySlugs> = {
  path: '',
  component: () => <OverviewTab />,
}

const Reports: ISingleCustomPage<EpidemiologySlugs> = {
  path: 'reports',
  component: () => <FullReportPage showCategory={false} />,
}

const ProtectionAndVaccinations: ISingleCustomPage<EpidemiologySlugs> = {
  path: ['protection-vaccinations', 'protected-population'],
  component: () => (
    <TabbedDisplay
      tabs={[
        {
          heading: 'People Protected',
          body: <RShinyEmbed path='peopleProtected' height='100vh' />,
        },
      ]}
    />
  ),
}
const LongCovidDashboard: ISingleCustomPage<EpidemiologySlugs> = {
  path: ['long-covid'],
  component: () => (
    <TabbedDisplay
      tabs={[
        {
          heading: 'Risk Factors',
          body: <TableauEmbed path='/Long-COVID_RF/Dashboard1' />,
        },
        LongCovidPages['LongCovidPresentations'],
      ]}
    />
  ),
}

const VariantsLatestNews: ISingleCustomPage<EpidemiologySlugs> = {
  path: ['variants', 'latest-news'],
  component: () => (
    <Dashboard
      hideReports
      widgetTabs={[
        getMediaDefaultWidget({
          title: 'Media',
          view: EpidemiologyPages['VariantsMedia'].views[0],
        }),
        getPressReleasesDefaultWidget({
          view: EpidemiologyPages['VariantsPressReleases'].views[0],
        }),
      ]}
    />
  ),
}

const VariantsVirologyImpact: ISingleCustomPage<EpidemiologySlugs> = {
  path: ['variants', 'impact'],
  component: () => (
    <TabbedDisplay
      tabs={[
        {
          heading: 'Overview',
          body: <TableauEmbed path='/Variantvaccineheatmap/Dashboard1' />,
        },
        {
          heading: 'Degree of Impact',
          body: <TableauEmbed path='/Variant_Treatmentimpact/Dashboard1' />,
        },
      ]}
    />
  ),
}

const EpidDataByAgeGroup: ISingleCustomPage<EpidemiologySlugs> = {
  path: 'by-age-group',
  component: () => (
    <Box py={3} overflowX='hidden' overflowY='scroll' h='100%'>
      <Panel overflow='hidden' height='100%'>
        <PanelHeader>
          <Box display='flex' flexDirection='row' alignItems='center'>
            <PanelTitle>COVID Epidemiology Track By Age Group</PanelTitle>
          </Box>
        </PanelHeader>
        <PanelBody height='100%'>
          <TableauEmbed
            path='/COVIDEpidemiologyDashboard/CovidEpidemiolgyDashboard'
            height={`calc(100vh - 170px)`}
          />
        </PanelBody>
      </Panel>
    </Box>
  ),
}

export const EpidemiologyCustomPages: Record<
  string,
  ISingleCustomPage<EpidemiologySlugs>
> = {
  OverviewAndRisk,
  VariantsLatestNews,
  VariantsVirologyImpact,
  ProtectionAndVaccinations,
  EpidDataByAgeGroup,
  Reports,
  LongCovidDashboard,
}
