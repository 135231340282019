import { OktaAuth } from '@okta/okta-auth-js'

const CLIENT_ID = process.env.REACT_APP_OKTA_CLIENT_ID
const ISSUER = process.env.REACT_APP_OKTA_ISSUER
const REDIRECT_URI = window.location.origin + '/implicit/callback'
const OKTA_TESTING_DISABLEHTTPSCHECK =
  process.env.OKTA_TESTING_DISABLEHTTPSCHECK || false

const oidc = {
  clientId: CLIENT_ID,
  issuer: ISSUER,
  redirectUri: REDIRECT_URI,
  scopes: ['openid', 'profile', 'email', 'groups'],
  pkce: true,
  disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
  useClassicEngine: true,
}

const oktaAuth = new OktaAuth(oidc)

export default {
  oidc,
  oktaAuth,
  resourceServer: {},
}
