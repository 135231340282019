import { SettingsAdjust } from '@carbon/icons-react'
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { memo, useState } from 'react'

// import { useTablePageData } from 'modules/Tables/TablePageProvider'
import { IResponseBase } from 'api/types'

import { IModel } from 'interfaces/model.interface'

import useTracking from 'tracking/useTracking'

import AdvancedSearch from '../AdvancedSearch'
import {
  AdvancedFilterUpdaterProp,
  SingleAdvancedFilter,
} from '../AdvancedSearch/useAdvancedFilters'

type PropTypes<D extends Object> = {
  model: IModel<D, any>
  data: IResponseBase<string>[]
  advancedFilters: SingleAdvancedFilter<D>[]
  setAdvancedFilters: (updater: AdvancedFilterUpdaterProp) => void
}

function Filter<D extends Object>({
  model,
  data,
  advancedFilters,
  setAdvancedFilters,
}: PropTypes<D>) {
  // const { handleFilters } = useTablePageData()
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <Box mr={1} position='relative'>
      <FilterModal
        key={JSON.stringify(advancedFilters)}
        isOpen={isOpen}
        onClose={onClose}
        data={data}
        model={model}
        currentAdvancedFilters={advancedFilters}
        onSaveFilter={setAdvancedFilters}
      />
      <Button variant='clean' onClick={onOpen} fontSize='12px'>
        <Box mr={1}>
          <SettingsAdjust size={16} />
        </Box>
        Filter
      </Button>
      {advancedFilters.length > 0 && (
        <Box
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          width='16px'
          height='16px'
          backgroundColor='yellow.500'
          position='absolute'
          borderRadius='50%'
          top='0px'
          right='-8px'
          pointerEvents='none'
        >
          <Text color='primary' fontSize='12px' width='100%' textAlign='center'>
            {advancedFilters.length <= 9 ? advancedFilters.length : '!'}
          </Text>
        </Box>
      )}
    </Box>
  )
}

export default memo(Filter)

type FilterModalPropTypes<D extends Object> = {
  model: IModel<D, any>
  data: IResponseBase<Extract<keyof D, string>>[]
  currentAdvancedFilters: SingleAdvancedFilter<D>[]
  isOpen: boolean
  onClose: () => void
  onSaveFilter: (newAdvancedFilters: AdvancedFilterUpdaterProp) => void
}

function FilterModal<D extends Object>({
  data,
  model,
  currentAdvancedFilters,
  isOpen,
  onClose,
  onSaveFilter,
}: FilterModalPropTypes<D>) {
  const [tracking] = useTracking()

  const [localAdvancedFilters, setLocalAdvancedFilters] = useState(
    currentAdvancedFilters
  )

  return (
    <Modal
      blockScrollOnMount={false}
      isOpen={isOpen}
      onClose={() => {
        onClose()
        setLocalAdvancedFilters(currentAdvancedFilters)
      }}
      isCentered
    >
      <ModalOverlay />
      <ModalContent width='100%' maxWidth='648px'>
        <ModalHeader color='primary' fontWeight='600'>
          Filter
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text color='primary' fontSize='14px' lineHeight='22px'>
            You can setup filters to streamline your results.
          </Text>
          <Box mb='24px' />
          <AdvancedSearch
            data={data}
            model={model}
            advancedFilters={localAdvancedFilters}
            setAdvancedFilters={setLocalAdvancedFilters}
          />
        </ModalBody>

        <ModalFooter justifyContent='space-between'>
          <Button
            textDecor='underline'
            variant='clean'
            color='black'
            onClick={() => {
              setLocalAdvancedFilters(currentAdvancedFilters)
              onSaveFilter([])
            }}
          >
            Reset Filters
          </Button>
          <Button
            variant='yellow'
            onClick={() => {
              localAdvancedFilters.forEach((filter) => {
                tracking.tableAdvanceFilter({
                  column: filter.column,
                  type: filter.type,
                  filterValue: filter.filterValue,
                })
              })
              onSaveFilter(localAdvancedFilters)
              onClose()
            }}
          >
            Save & Apply
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
