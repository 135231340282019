import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/react'
import { useEffect, useRef, useState } from 'react'

function bytesToBlob(pdfBytes: Uint8Array) {
  return new Blob([pdfBytes], { type: 'application/pdf' })
}

interface PdfVisualizationModalTypes {
  pdfBytes?: Uint8Array | null
  isOpen: boolean
  onClose: () => void
  pageIndex?: number
  searchString?: string
}

const PdfVisualizationModal = ({
  pdfBytes,
  isOpen,
  onClose,
  pageIndex = 1,
  searchString,
}: PdfVisualizationModalTypes) => {
  const viewer = useRef(null)

  const [objectUrl, setObjectUrl] = useState<string | null>(null)

  useEffect(() => {
    if (pdfBytes) {
      const objectUrl = URL.createObjectURL(bytesToBlob(pdfBytes))
      setObjectUrl(objectUrl)

      return () => {
        URL.revokeObjectURL(objectUrl)
        setObjectUrl(null)
      }
    }
  }, [pdfBytes])

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        onOverlayClick={onClose}
        size='full'
      >
        <ModalOverlay />
        <ModalContent w='75vw' h='85vh' pos='relative' overflow='hidden'>
          <ModalBody p='0rem'>
            {objectUrl && (
              <iframe
                width='100%'
                height='100%'
                title='test-frame'
                src={`/pdfjs/web/viewer.html?file=${encodeURIComponent(
                  objectUrl
                )}#page=${pageIndex}${
                  searchString && `&search=${searchString}`
                }`}
                ref={viewer}
              />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default PdfVisualizationModal
