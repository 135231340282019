import { ChevronDown, ChevronRight } from '@carbon/icons-react'
import {
  Icon,
  Box,
  Button,
  Flex,
  Heading,
  Text,
  BoxProps,
} from '@chakra-ui/react'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { sortBy } from 'lodash'

import { ReportCategory } from 'api/types'

import useTracking from 'tracking/useTracking'

import { groupCategoriesByType } from '../utils'

type CategoriesDropdownPropTypes = {
  categories: (ReportCategory | undefined)[]
  selectedCategory: ReportCategory | null
  setSelectedCategory: React.Dispatch<
    React.SetStateAction<ReportCategory | null>
  >
}

const DropdownItem = (props: BoxProps) => {
  return (
    <Box
      px={4}
      py={3}
      _hover={{
        bg: 'gray5',
      }}
      cursor='pointer'
      sx={{
        '&[data-highlighted]': {
          outline: 'none',
        },
      }}
      borderColor='gray3'
      {...props}
    >
      {props.children}
    </Box>
  )
}

export const CategoriesDropdown = ({
  categories,
  selectedCategory,
  setSelectedCategory,
}: CategoriesDropdownPropTypes) => {
  const groupedCategories = groupCategoriesByType(categories)
  const [tracking] = useTracking()

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <Button
          w='220px'
          minW='220px'
          bg='gray5'
          _hover={{
            bg: 'gray5',
          }}
          color='black'
          borderColor='gray3'
          borderWidth='1px 0px 1px 1px'
          height='61px'
          padding='8px 24px'
          borderStartRadius='8px'
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          _focus={{
            boxShadow: 'none',
          }}
        >
          <Flex w='full' flexGrow={0} flexDir='column' textAlign='left'>
            <Text variant='body4' color='gray1'>
              {selectedCategory?.type ?? 'Categories'}
            </Text>
            <Heading
              textOverflow='ellipsis'
              overflow='hidden'
              whiteSpace='nowrap'
              variant='h6'
            >
              {selectedCategory?.name ?? 'All'}
            </Heading>
          </Flex>
          <Icon as={ChevronDown} boxSize='17px' />
        </Button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <Box
          width='201px'
          bg='white'
          borderRadius='8px'
          border='1px solid'
          borderColor='gray3'
          boxShadow='-3px 3px 20px rgba(0, 0, 0, 0.09)'
          as={DropdownMenu.Content}
        >
          <DropdownItem
            borderBottomWidth='1px'
            borderColor='gray3'
            borderTopRadius='8px'
            as={DropdownMenu.Item}
            onClick={() => setSelectedCategory(null)}
          >
            <Text
              variant={!selectedCategory ? 'body2-bold' : 'body2'}
              color={!selectedCategory ? 'black' : 'gray1'}
            >
              All
            </Text>
          </DropdownItem>
          {Object.keys(groupedCategories).map((categoryType, i, arr) => (
            <DropdownMenu.Sub key={categoryType}>
              <DropdownItem
                display='flex'
                flexDir='row'
                as={DropdownMenu.SubTrigger}
                borderBottomWidth={i !== arr.length - 1 ? '1px' : undefined}
                borderColor='gray3'
                borderTopRadius={i === 0 ? '8px' : undefined}
                borderBottomRadius={i === arr.length - 1 ? '8px' : undefined}
                alignItems='center'
                justifyContent='space-between'
              >
                <Text
                  variant={
                    selectedCategory?.type === categoryType
                      ? 'body2-bold'
                      : 'body2'
                  }
                  color={
                    selectedCategory?.type === categoryType ? 'black' : 'gray1'
                  }
                >
                  {categoryType}
                </Text>
                <Icon as={ChevronRight} />
              </DropdownItem>
              <DropdownMenu.Portal>
                <Box
                  as={DropdownMenu.SubContent}
                  className='DropdownMenuSubContent'
                  sideOffset={1}
                  alignOffset={-1}
                  filter='drop-shadow(0px 2px 6px rgba(0,0,0,0.25))'
                  bg='white'
                  borderWidth='1px 1px 1px 0px'
                >
                  {sortBy(groupedCategories[categoryType], 'name').map(
                    (category, i, arr) => (
                      <DropdownItem
                        as={DropdownMenu.Item}
                        borderBottomWidth={
                          i !== arr.length - 1 ? '1px' : undefined
                        }
                        borderTopRadius={i === 0 ? '8px' : undefined}
                        borderBottomRadius={
                          i === arr.length - 1 ? '8px' : undefined
                        }
                        key={category.name}
                        onClick={() => {
                          tracking.filterReports({ filter: category.name })
                          setSelectedCategory(category)
                        }}
                      >
                        <Text
                          variant={
                            selectedCategory?.name === category.name
                              ? 'body2-bold'
                              : 'body2'
                          }
                          color={
                            selectedCategory?.name === category.name
                              ? 'black'
                              : 'gray1'
                          }
                        >
                          {category.name}
                        </Text>
                      </DropdownItem>
                    )
                  )}
                </Box>
              </DropdownMenu.Portal>
            </DropdownMenu.Sub>
          ))}
        </Box>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  )
}
