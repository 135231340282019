import { INavigationMenu } from 'interfaces/navigationMenu.interface'

export type CardioAntithromboticsSlugs =
  | ''
  | 'pipeline'
  | 'clinical-trials'
  | 'approved-indications'
  | 'publications'
  | 'conference-presentations'
  | 'press-releases'
  | 'tweets'
  | 'kols'
  | 'global-revenue'
  | 'news'
  | 'media'
  | 'reports'
  | 'candidates'
  | 'table'
  | 'progress-by-phase'
  | 'timeline'
  | 'fxi-and-fxii-inhibitor-timeline'
  | 'safety-overview'

const CardioAntithromboticsMenu: Array<
  INavigationMenu<CardioAntithromboticsSlugs>[]
> = [
  [
    {
      title: 'Latest Information',
      slug: '',
      description:
        'Front page with latest dyslipidemia news, press releases, KOL activity on Twitter, and monthly reports',
      createdAt: new Date(2022, 6, 20),
    },
    {
      title: 'Reports',
      slug: 'reports',
      createdAt: new Date(2022, 3, 19),
    },
  ],
  [
    {
      title: 'Candidates',
      slug: 'candidates',
      description: '',
      createdAt: new Date(2022, 2, 21),
      children: [
        {
          title: 'Table',
          slug: 'table',
          createdAt: new Date(2022, 2, 21),
        },
        {
          title: 'Pipeline',
          slug: 'pipeline',
          description: '',
          createdAt: new Date(2022, 8, 6),
        },
        {
          title: 'Progress Tracker',
          slug: 'progress-by-phase',
          description: '',
          createdAt: new Date(2022, 6, 20),
        },
      ],
    },
    {
      title: 'Clinical Trials',
      slug: 'clinical-trials',
      description: '',
      createdAt: new Date(2022, 2, 21),
      children: [
        {
          title: 'Table',
          slug: 'table',
          createdAt: new Date(2022, 2, 21),
        },
        {
          title: 'Timeline',
          slug: 'timeline',
          createdAt: new Date(2022, 8, 6),
        },
        {
          title: 'FXI and FXII Inhibitor Timeline',
          slug: 'fxi-and-fxii-inhibitor-timeline',
          createdAt: new Date(2022, 8, 6),
        },
      ],
    },
    {
      title: 'Approved Indications',
      slug: 'approved-indications',
      description: '',
      createdAt: new Date(2022, 3, 25),
    },
    {
      title: 'Global Revenue',
      slug: 'global-revenue',
      description: '',
      createdAt: new Date(2022, 2, 25),
    },
    {
      title: 'Publications',
      slug: 'publications',
      description: '',
      createdAt: new Date(2022, 2, 21),
    },
    {
      title: 'Conference Presentations',
      slug: 'conference-presentations',
      description: '',
      createdAt: new Date(2022, 2, 31),
    },
    {
      title: 'News',
      slug: 'news',
      description: '',
      createdAt: new Date(2022, 2, 21),
      children: [
        {
          title: 'Press Releases',
          slug: 'press-releases',
          createdAt: new Date(2022, 2, 21),
        },
        {
          title: 'Media',
          slug: 'media',
          createdAt: new Date(2022, 2, 21),
        },
      ],
    },
    {
      title: 'Tweets',
      slug: 'tweets',
      description: '',
      createdAt: new Date(2022, 2, 21),
    },
    {
      title: 'KOLS',
      slug: 'kols',
      description: '',
      createdAt: new Date(2022, 5, 14),
    },
    {
      title: 'Safety Overview',
      slug: 'safety-overview',
      createdAt: new Date(2022, 10, 29),
    },
  ],
]

export { CardioAntithromboticsMenu }
