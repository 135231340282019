import { INavigationMenu } from 'interfaces/navigationMenu.interface'

export type PNHSlugs =
  | ''
  | 'reports'
  | 'candidates'
  | 'all-data'
  | 'pipeline'
  | 'progress-tracker'
  | 'trials'
  | 'publications'
  | 'table'
  | 'timeline'
  | 'news'
  | 'press-releases'
  | 'media'
  | 'social'
  | 'tweets'

const PNHMenu: Array<INavigationMenu<PNHSlugs>[]> = [
  [
    {
      title: 'Latest Information',
      slug: '',
      createdAt: new Date(2021, 10, 29),
    },
    {
      title: 'Reports',
      slug: 'reports',
      createdAt: new Date(2021, 3, 1),
    },
  ],
  [
    {
      title: 'Candidates',
      slug: 'candidates',
      createdAt: new Date(2021, 10, 30),
      children: [
        {
          title: 'All Data',
          slug: 'all-data',
          createdAt: new Date(2021, 10, 30),
        },
        {
          title: 'Pipeline',
          slug: 'pipeline',
          createdAt: new Date(2021, 10, 30),
        },
        {
          title: 'Progress Tracker',
          slug: 'progress-tracker',
          createdAt: new Date(2021, 10, 30),
        },
      ],
    },
    {
      title: 'Publications',
      slug: 'publications',
      createdAt: new Date(2022, 10, 2),
    },
    {
      title: 'Trials',
      slug: 'trials',
      createdAt: new Date(2021, 11, 1),
      children: [
        {
          title: 'Table',
          slug: 'table',
          createdAt: new Date(2021, 11, 1),
        },
        {
          title: 'Timeline',
          slug: 'timeline',
          createdAt: new Date(2021, 11, 1),
        },
      ],
    },
    {
      title: 'News',
      slug: 'news',
      createdAt: new Date(2021, 11, 14),
      children: [
        {
          title: 'Press Releases',
          slug: 'press-releases',
          createdAt: new Date(2021, 11, 14),
        },
        {
          title: 'Media',
          slug: 'media',
          createdAt: new Date(2021, 11, 14),
        },
      ],
    },
    {
      title: 'Social',
      slug: 'social',
      createdAt: new Date(2022, 1, 7),
      children: [
        {
          title: 'Tweets',
          slug: 'tweets',
          createdAt: new Date(2022, 1, 7),
        },
      ],
    },
  ],
]

export { PNHMenu }
