import { IApp } from 'interfaces/navigationApp.interface'

import { AccessGroups } from 'enums/AccessGroups'

import { EpidemiologyCustomPages } from './EpidemiologyCustomPages'
import { EpidemiologyMenu, EpidemiologySlugs } from './EpidemiologyMenu'
import { EpidemiologyPages } from './EpidemiologyPages'

const epidemiology: IApp<EpidemiologySlugs> = {
  name: 'Epidemiology',
  slug: 'epidemiology',
  accessGroups: [AccessGroups.Epidemiology],
  endpoint: 'covid-epidemiology',
  menu: EpidemiologyMenu,
  pages: EpidemiologyPages,
  customPages: EpidemiologyCustomPages,
  disableAnalytics: true,
  createdAt: new Date(2022, 1, 4),
}

export default epidemiology
