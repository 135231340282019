import {
  Button,
  Modal as ChakraModal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'

import { Upsell } from 'components'

export type PdfBasicUpsellPropTypes = {
  isOpen: boolean
  onClose: () => void
}

export const PdfBasicUpsell = ({
  isOpen,
  onClose,
}: PdfBasicUpsellPropTypes) => {
  return (
    <ChakraModal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Paid Subscription Tier Feature</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Upsell inModal />
        </ModalBody>

        <ModalFooter>
          <Button colorScheme='blue' mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </ChakraModal>
  )
}
