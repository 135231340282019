import { Box } from '@chakra-ui/react'
import { useMemo } from 'react'

import DashboardReports from 'modules/DashboardReports'
import { TabData } from 'modules/TabbedDisplay'

import { useAppRoute } from 'routes/utils'

import { apps } from 'config/apps'

import useIsMobile from 'utils/useIsMobile'

import WidgetList from './WidgetList'

interface DashboardProps {
  widgetTabs?: TabData[]
  hideReports?: boolean
  analysisExtraKey?: string
}
const Dashboard = ({
  widgetTabs = [],
  hideReports = false,
  analysisExtraKey,
}: DashboardProps) => {
  const selectedApp = useAppRoute()
  const selectedAppObject = useMemo(() => apps[selectedApp], [selectedApp])

  const isMobile = useIsMobile()

  return (
    <Box flex='1' display='flex'>
      <Box
        flex='1'
        display='flex'
        flexDirection='column'
        overflow='hidden'
        width='calc(100% - 600px)'
      >
        <Box p={3}>
          <WidgetList
            tabs={widgetTabs}
            hideReports={hideReports}
            analysisExtraKey={analysisExtraKey}
          />
        </Box>
      </Box>
      {!hideReports && !isMobile && (
        <DashboardReports app={selectedAppObject} extraKey={analysisExtraKey} />
      )}
    </Box>
  )
}

export default Dashboard
