import TabbedDisplay from 'modules/TabbedDisplay'

import { TableauEmbed } from 'components'

import { ISingleCustomPage } from 'interfaces/navigationPage.interface'

import { RSVEpidemiologySlugs } from './RSVEpidemiologyMenu'

const AbsoluteCases: ISingleCustomPage<RSVEpidemiologySlugs> = {
  path: '',
  component: () => (
    <TableauEmbed path='/FRONTENDRSVdashboardsUpdated/AverageepidRSVcasedashboardV2' />
  ),
}
const PercentPositivityCases: ISingleCustomPage<RSVEpidemiologySlugs> = {
  path: 'usa-percent-positivity',
  component: () => (
    <TableauEmbed path='/FRONTENDRSVdashboards/USStatesPositivity' />
  ),
}

const Severity: ISingleCustomPage<RSVEpidemiologySlugs> = {
  path: 'hospitalisations',
  component: () => (
    <TabbedDisplay
      tabs={[
        {
          heading: 'Hospitalisations',
          body: (
            <TableauEmbed path='/FRONTENDRSVdashboards/Hospitalisations_1' />
          ),
        },
        {
          heading: 'Hospitalisations by Age',
          body: (
            <TableauEmbed path='/FRONTENDRSVdashboards/HospitalisationsbyAge' />
          ),
        },
      ]}
    />
  ),
}

export const RSVEpidemiologyCustomPages: Record<
  string,
  ISingleCustomPage<RSVEpidemiologySlugs>
> = {
  AbsoluteCases,
  PercentPositivityCases,
  Severity,
}
