import { Box, Text, VStack } from '@chakra-ui/react'
import { capitalize } from 'lodash'
import { useContext } from 'react'

import { AuthContext } from 'contexts'

import { Select } from 'components'

import { appletGroupCategoriesUnion } from 'config/appletGroups'

export type AppsSelectorDropdownType = {
  label: string
  value: appletGroupCategoriesUnion | null
}
export const selectAllOption: AppsSelectorDropdownType = {
  label: 'All Apps',
  value: null,
}

export type AppsSelectorHeaderPropTypes = {
  selectedCategory: AppsSelectorDropdownType
  setSelectedCategory: React.Dispatch<AppsSelectorDropdownType>
}

const AppsSelectorHeader = ({
  selectedCategory,
  setSelectedCategory,
}: AppsSelectorHeaderPropTypes) => {
  const { appletGroupsUserCanSee } = useContext(AuthContext)

  // Category dropdown filter options
  const categoryOptions: AppsSelectorDropdownType[] = [selectAllOption].concat(
    Array.from(
      new Set(
        Object.values(appletGroupsUserCanSee).map(
          (appletGroup) => appletGroup.category
        )
      )
    ).map((category) => ({
      label: capitalize(category),
      value: category,
    }))
  )

  return (
    <VStack spacing='24px' alignItems='flex-start'>
      <Text fontSize='3xl' fontWeight='bold' color='black'>
        Apps
      </Text>
      <Text fontWeight='normal' fontSize='13px'>
        Your plan has access to{' '}
        <Text as='b' color='primary'>
          {Object.keys(appletGroupsUserCanSee).length} apps.
        </Text>
      </Text>
      <Box maxW='642px' w='100%'>
        <Select
          isSearchable={false}
          value={selectedCategory}
          onChange={(x: any) => {
            setSelectedCategory(x)
          }}
          options={categoryOptions}
        />
      </Box>
    </VStack>
  )
}

export default AppsSelectorHeader
