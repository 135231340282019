import { Box, useToken } from '@chakra-ui/react'
import { useContext } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { RedocStandalone } from 'redoc'

import Navigation from 'modules/Navigation'

import { AuthContext } from 'contexts'

import useOpenAPISchema from 'api/forecast/useOpenAPISchema'

export default function APISpec() {
  const { userCanAccessAPI } = useContext(AuthContext)

  const navigate = useNavigate()

  if (!userCanAccessAPI) {
    return <Navigate to='/' replace />
  }

  return (
    <>
      <Navigation
        showSearchBar={false}
        onBackButton={() => navigate('/settings/api')}
      />
      <Box overflowY='auto' height='100%'>
        <Redoc />
      </Box>
    </>
  )
}

const Redoc = () => {
  const brandColor = useToken('colors', 'brand')

  const { data: spec } = useOpenAPISchema()

  return (
    <RedocStandalone
      spec={spec}
      options={{
        theme: { colors: { primary: { main: brandColor } } },
        hideHostname: true,
      }}
    />
  )
}
