import React, { useContext, useEffect, useState } from 'react'

import useIsMobile from 'utils/useIsMobile'

type ProviderType = {
  sidebarOpened: boolean
  setSidebarOpened: React.Dispatch<React.SetStateAction<boolean>>
}

export const SidebarContext = React.createContext<ProviderType>({
  sidebarOpened: true,
  setSidebarOpened: () => {},
})

export const useSidebar = (): ProviderType => {
  return useContext(SidebarContext)
}

export const SidebarConsumer = SidebarContext.Consumer

export const SidebarProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const isMobile = useIsMobile()
  const [sidebarOpened, setSidebarOpened] = useState(false)

  useEffect(() => {
    if (isMobile) {
      setSidebarOpened(true)
    } else {
      setSidebarOpened(true)
    }
  }, [isMobile])

  return (
    <SidebarContext.Provider
      value={{
        sidebarOpened,
        setSidebarOpened,
      }}
    >
      {children}
    </SidebarContext.Provider>
  )
}
