import GenericTable from 'modules/Tables'

import { IModel } from 'interfaces/model.interface'
import {
  INavigationPages,
  ISingleNavigationPage,
} from 'interfaces/navigationPage.interface'

import { Covid19CommercialTherapeuticsSlugs } from './Covid19CommercialTherapeuticsMenu'
import {
  TreatmentManufacturersModel,
  TreatmentManufacturingAndProductionDealsModel,
  TreatmentOrganisationsModel,
  TreatmentPriceDealsModel,
  TreatmentSupplyDealsModel,
} from './Covid19CommercialTherapeuticsModels'

type Covid19CommercialTherapeuticsPage<TModel extends IModel<any>> =
  ISingleNavigationPage<Covid19CommercialTherapeuticsSlugs, TModel>

const Developers: Covid19CommercialTherapeuticsPage<
  typeof TreatmentOrganisationsModel
> = {
  key: 'Developers',
  path: null,
  component: GenericTable,
  model: { ...TreatmentOrganisationsModel, name: 'All', hideTableHeader: true },
  views: [
    {
      name: 'Default',
      airtableName: 'api_tt_organisations',
      airtableBase: 'idtreatments360',
    },
  ],
}
export const DevelopersPages: INavigationPages<Covid19CommercialTherapeuticsSlugs> =
  {
    Developers,
  }

const Manufacturers: Covid19CommercialTherapeuticsPage<
  typeof TreatmentManufacturersModel
> = {
  key: 'Manufacturers',
  path: null,
  component: GenericTable,
  model: { ...TreatmentManufacturersModel, name: 'All', hideTableHeader: true },
  views: [
    {
      name: 'Default',
      airtableName: 'api_tt_manufacturers',
      airtableBase: 'idtreatments360',
    },
  ],
}
export const ManufacturersPages: INavigationPages<Covid19CommercialTherapeuticsSlugs> =
  {
    Manufacturers,
  }

const DealsPrice: Covid19CommercialTherapeuticsPage<
  typeof TreatmentPriceDealsModel
> = {
  key: 'DealsPrice',
  path: null,
  model: {
    ...TreatmentPriceDealsModel,
    name: 'Table',
    hideTableHeader: true,
  },
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableName: 'price_and_cost_api',
      airtableBase: 'idtreatments360',
    },
  ],
}
export const DealsPricePages: INavigationPages<Covid19CommercialTherapeuticsSlugs> =
  {
    DealsPrice,
  }

const DealsSupply: Covid19CommercialTherapeuticsPage<
  typeof TreatmentSupplyDealsModel
> = {
  key: 'DealsSupply',
  path: null,
  model: { ...TreatmentSupplyDealsModel, name: 'Deals', hideTableHeader: true },
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableName: 'tt_supply_deals_api',
      airtableBase: 'idtreatments360',
    },
  ],
}
export const DealsSupplyPages: INavigationPages<Covid19CommercialTherapeuticsSlugs> =
  {
    DealsSupply,
  }

const DealsManufacturingAndProductionDeals: Covid19CommercialTherapeuticsPage<
  typeof TreatmentManufacturingAndProductionDealsModel
> = {
  key: 'DealsManufacturingAndProductionDeals',
  path: null,
  model: TreatmentManufacturingAndProductionDealsModel,
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableName: 'tt_production_api',
      airtableBase: 'idtreatments360',
    },
  ],
}
export const DealsManufacturingAndProductionDealsPages: INavigationPages<Covid19CommercialTherapeuticsSlugs> =
  {
    DealsManufacturingAndProductionDeals,
  }

export const Covid19CommercialTherapeuticsPages: INavigationPages<Covid19CommercialTherapeuticsSlugs> =
  {
    Developers,
    Manufacturers,
    DealsPrice,
    DealsSupply,
    DealsManufacturingAndProductionDeals,
  }
