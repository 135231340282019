import TabbedDisplay from 'modules/TabbedDisplay'

import FullReportPage from 'routes/common/FullReportPage'

import { TableauEmbed } from 'components'

import { ISingleCustomPage } from 'interfaces/navigationPage.interface'

import { InfluenzaCommercialSlugs } from './InfluenzaCommercialMenu'
import ProcurementAndReimbursementArchetypesPage from './ProcurementAndReimbursementArchetypes'

const Reports: ISingleCustomPage<InfluenzaCommercialSlugs> = {
  path: 'reports',
  component: () => <FullReportPage showCategory={false} />,
}

const SupplyDealsTimelinePerCompany: ISingleCustomPage<InfluenzaCommercialSlugs> =
  {
    path: ['supply-deals', 'by-country'],
    component: () => (
      <TabbedDisplay
        tabs={[
          {
            heading: 'Doses Supplied Map',
            body: <TableauEmbed path='/IDAfludoses/Dashboard4' />,
          },
          {
            heading: 'Doses Supplied by Manufacturer over Time',
            body: <TableauEmbed path='/IDAfludoses/CountrySelection_1' />,
          },
          {
            heading: 'Doses Supplied Country Comparison over Time',
            body: (
              <TableauEmbed path='/IDAfludoses/PublicandPrivateMarkets_1' />
            ),
          },
          {
            heading: 'US Public Vax Supply by State',
            body: <TableauEmbed path='/FOIAUSVis/Highlevel' />,
          },
        ]}
      />
    ),
  }

const SupplyDealsTotalPerCompany: ISingleCustomPage<InfluenzaCommercialSlugs> =
  {
    path: ['supply-deals', 'by-company'],
    component: () => <TableauEmbed path='/IDAfludoses/Manufacturers' />,
  }

const SupplyDealsPriceVsDoses: ISingleCustomPage<InfluenzaCommercialSlugs> = {
  path: ['supply-deals', 'price-vs-doses'],
  component: () => <TableauEmbed path='/SupplyDealsViz/PricevsSupply' />,
}
const DemandForecastPage: ISingleCustomPage<InfluenzaCommercialSlugs> = {
  path: '',
  component: () => (
    <TableauEmbed path='/DataCollectionExplanation/InfluenzaVaccineCoverage' />
  ),
}

const SupplyDealsTotalPerVaccineName: ISingleCustomPage<InfluenzaCommercialSlugs> =
  {
    path: ['supply-deals', 'total-per-vaccine-name'],
    component: () => <TableauEmbed path='/SupplyDealsViz/DealsbyBrandName' />,
  }

const ProcurementAndReimbursementArchetypes: ISingleCustomPage<InfluenzaCommercialSlugs> =
  {
    path: [
      'market-access-and-regulatory',
      'procurement-reimbursement-archetypes',
    ],
    component: () => <ProcurementAndReimbursementArchetypesPage />,
  }

const GlobalRevenueAndMarketShare: ISingleCustomPage<InfluenzaCommercialSlugs> =
  {
    path: ['market-share-and-size', 'global-revenue-and-market-share'],
    component: () => (
      <TableauEmbed path='/Influenzavaccinerevenuemarketshareprice/RevenueandMarketshare' />
    ),
  }

const NumberOfTenders: ISingleCustomPage<InfluenzaCommercialSlugs> = {
  path: ['tenders', 'number-of-tenders'],
  component: () => <TableauEmbed path='/InfluenzatendersG20/Dashboard1' />,
}

const DosesTendered: ISingleCustomPage<InfluenzaCommercialSlugs> = {
  path: ['tenders', 'doses-tendered'],
  component: () => <TableauEmbed path='/InfluenzatendersG20/Dosestendered_1' />,
}

const TendersValueAndPrice: ISingleCustomPage<InfluenzaCommercialSlugs> = {
  path: ['tenders', 'value-and-price'],
  component: () => (
    <TableauEmbed path='/InfluenzatendersG20/Tendervalueandpriceperdose_1' />
  ),
}

export const InfluenzaCommercialCustomPages: Record<
  string,
  ISingleCustomPage<InfluenzaCommercialSlugs>
> = {
  Reports,
  SupplyDealsTimelinePerCompany,
  SupplyDealsTotalPerCompany,
  SupplyDealsPriceVsDoses,
  SupplyDealsTotalPerVaccineName,
  ProcurementAndReimbursementArchetypes,
  DemandForecastPage,
  GlobalRevenueAndMarketShare,
  NumberOfTenders,
  DosesTendered,
  TendersValueAndPrice,
}
