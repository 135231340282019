import _ from 'lodash'
import moment from 'moment'

import { Report, ReportCategory } from 'api/types'

export const groupByMonth = (items: (Report | undefined)[]) => {
  const groups = _.groupBy(items, (result) => {
    const value = moment(result?.date, 'YYYY-MM-DD')
      .startOf('month')
      .format('YYYY-MM')
    return value
  })
  return groups
}

const capitalise = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

const titleCaseWords = (
  words: string,
  ignore = [
    'a',
    'an',
    'and',
    'at',
    'but',
    'by',
    'for',
    'in',
    'nor',
    'of',
    'on',
    'or',
    'out',
    'so',
    'the',
    'to',
    'up',
    'yet',
  ]
) => {
  const ignoredWords = new Set(ignore)
  if (words === null) {
    // TODO remove this once we ban null values
    return 'null'
  }
  return words.replace(/\w+/g, (word, i) => {
    word = word.toLowerCase()
    if (i && ignoredWords.has(word)) {
      return word
    }
    return capitalise(word)
  })
}

interface GroupedCategoriesType {
  [key: string]: ReportCategory[]
}
export const groupCategoriesByType = (
  categories: (ReportCategory | undefined)[]
) => {
  return categories?.reduce(
    (acc: GroupedCategoriesType, item: ReportCategory | undefined) => {
      if (!item) return acc
      return {
        ...acc,
        [titleCaseWords(item.type)]: acc[titleCaseWords(item.type)]
          ? [...acc[titleCaseWords(item.type)], item]
          : [item],
      }
    },
    {}
  )
}
