import { useMediaQuery } from '@chakra-ui/react'

function isTouchDevice() {
  return (
    'ontouchstart' in window ||
    navigator?.maxTouchPoints > 0 ||
    // @ts-ignore
    navigator?.msMaxTouchPoints > 0
  )
}

export default function useIsMobile(): boolean {
  const [isMobile] = useMediaQuery('(max-width: 760px)')
  return (isMobile ?? false) || isTouchDevice()
}
