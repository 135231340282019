import { Box } from '@chakra-ui/layout'

import TabbedDisplay from 'modules/TabbedDisplay'

import FullReportPage from 'routes/common/FullReportPage'
import Dashboard from 'routes/dashboard'

import {
  Panel,
  PanelBody,
  PanelHeader,
  PanelTitle,
  TableauEmbed,
} from 'components'

import { ISingleCustomPage } from 'interfaces/navigationPage.interface'

import { getMediaDefaultWidget } from 'config/common/baseModel/Media'
import { getPressReleasesDefaultWidget } from 'config/common/baseModel/PressReleases'
import { getTweetsDefaultWidget } from 'config/common/baseModel/Tweets'

import { CardioLipidSlugs } from './CardioLipidMenu'
import { CardioLipidPages } from './CardioLipidPages'

const DashboardPage: ISingleCustomPage<CardioLipidSlugs> = {
  path: '',
  component: () => (
    <Dashboard
      widgetTabs={[
        getMediaDefaultWidget({
          view: CardioLipidPages['Media'].views[0],
        }),
        getTweetsDefaultWidget({
          view: CardioLipidPages['Tweets'].views[0],
        }),
        getPressReleasesDefaultWidget({
          view: CardioLipidPages['PressReleases'].views[0],
        }),
      ]}
    />
  ),
}

const CandidatesProgressTracker: ISingleCustomPage<CardioLipidSlugs> = {
  path: ['candidates', 'progress-tracker'],
  component: () => (
    <TabbedDisplay
      tabs={[
        {
          heading: 'Overall Progress',
          body: (
            <TableauEmbed path='/frontendcardioprogresstracker/OverallProgressbyMetric' />
          ),
        },
        {
          heading: 'Metric Breakdown',
          body: (
            <TableauEmbed path='/frontendcardioprogresstracker/Dashboard2' />
          ),
        },
      ]}
    />
  ),
}

const Reports: ISingleCustomPage<CardioLipidSlugs> = {
  path: 'reports',
  component: () => <FullReportPage showCategory={false} />,
}

const ApprovalsForecast: ISingleCustomPage<CardioLipidSlugs> = {
  path: ['trials', 'readout-forecast'],
  component: () => (
    <Panel overflow='hidden' height='100%'>
      <PanelHeader>
        <Box display='flex' py={3} flexDirection='row' alignItems='center'>
          <PanelTitle>Approvals Forecast</PanelTitle>
        </Box>
      </PanelHeader>
      <PanelBody>
        <TableauEmbed
          path='/approvalspredictor/Dashboard2'
          height={`calc(100vh - 170px)`}
        />
      </PanelBody>
    </Panel>
  ),
}
const SafetyOverview: ISingleCustomPage<CardioLipidSlugs> = {
  path: ['results', 'safety-overview'],
  component: () => (
    <Panel overflow='hidden' height='100%'>
      <PanelHeader>
        <Box display='flex' py={3} flexDirection='row' alignItems='center'>
          <PanelTitle>Approvals Forecast</PanelTitle>
        </Box>
      </PanelHeader>
      <PanelBody>
        <TableauEmbed
          path='/LLTsafetydata/Story1'
          height={`calc(100vh - 170px)`}
        />
      </PanelBody>
    </Panel>
  ),
}

const GlobalRevenue: ISingleCustomPage<CardioLipidSlugs> = {
  path: ['global-revenue'],
  component: () => (
    <Panel overflow='hidden' height='100%'>
      <PanelHeader>
        <Box display='flex' py={3} flexDirection='row' alignItems='center'>
          <PanelTitle>Global Revenue</PanelTitle>
        </Box>
      </PanelHeader>
      <PanelBody>
        <TableauEmbed
          path='/LipidRevenues/RevenueDashboardwTargetfilter'
          height={`calc(100vh - 230px)`}
        />
      </PanelBody>
    </Panel>
  ),
}

const CandidatesPipeline: ISingleCustomPage<CardioLipidSlugs> = {
  path: ['candidates', 'pipeline'],
  component: () => <TableauEmbed path='/pipelineoverviewLLT2/Dashboard1' />,
}

const CandidatesApprovedIndications: ISingleCustomPage<CardioLipidSlugs> = {
  path: ['candidates', 'approved-indications'],
  component: () => <TableauEmbed path='/LLTdrugapprovalsheatmap/Dashboard1' />,
}

const TrialsTimeline: ISingleCustomPage<CardioLipidSlugs> = {
  path: ['trials', 'timeline'],
  component: () => (
    <TableauEmbed path='/LLTclinicaltrialstimeline/Dashboard1' />
  ),
}

const TrialsReachingCompletion: ISingleCustomPage<CardioLipidSlugs> = {
  path: ['trials', 'trials-reaching-completion'],
  component: () => (
    <TableauEmbed path='/LLTclinicaltrialstimeline3monthversion/Dashboard1' />
  ),
}

const ResultsEfficacyHeatMap: ISingleCustomPage<CardioLipidSlugs> = {
  path: ['results', 'efficacy-heat-map'],
  component: () => (
    <TableauEmbed path='/LLTapproveddrugsefficacyheatmap2/Dashboard1' />
  ),
}

const CardioEventsReductionComparator: ISingleCustomPage<CardioLipidSlugs> = {
  path: ['results', 'cardiovascular-events-reduction-comparator'],
  component: () => <TableauEmbed path='/cvoutcomesefficacy/Story1' />,
}

export const CardioLipidCustomPages: Record<
  string,
  ISingleCustomPage<CardioLipidSlugs>
> = {
  DashboardPage,
  CandidatesProgressTracker,
  Reports,
  ApprovalsForecast,
  GlobalRevenue,
  CandidatesPipeline,
  CandidatesApprovedIndications,
  TrialsTimeline,
  TrialsReachingCompletion,
  ResultsEfficacyHeatMap,
  CardioEventsReductionComparator,
  SafetyOverview,
}
