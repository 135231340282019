import { Box } from '@chakra-ui/react'

import { TableauEmbed } from 'components'

const AppsSelectorPage = () => {
  return (
    <Box w='100vw' px='3rem' margin={'auto'}>
      <TableauEmbed
        path='/csl_dashboard/dashboard'
        height='calc(100vh - 130px)'
      />
    </Box>
  )
}

export default AppsSelectorPage
