import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { apps, appsListUnion } from 'config/apps'

import useAxios from '../../useAxios'

export type AlertType = 'WA' | 'IN' | 'HR' | 'MR' | 'LR' | 'UR'
export type AlertTrend = 'UN' | 'IN' | 'DE' | 'ST'
export type AlertIcon = 'PL' | 'CO' | 'DE'

export interface AlertsType {
  title: string
  sub_header: string
  description: string
  type: AlertType
  icon: AlertIcon
  date_published: Date
  trend: AlertTrend
}

export function useCountryAlerts(app: appsListUnion) {
  const axios = useAxios()

  return useQuery<AlertsType[], AxiosError>(['alerts', app], async () => {
    const response = await axios(`/${apps[app].endpoint}/analyst-alerts/`)
    return response.data
  })
}
