export enum AccessGroups {
  Vaccines = 'product_covid_vaccines_',
  Treatments = 'product_covid_treatments_',
  Diagnostics = 'product_covid_diagnostics_',
  Pandemic = 'product_covid_macro_',
  PAD = 'product_pad_',
  CardioLipid = 'product_cardio_lipid_',
  APIForecast = 'product_covid_api_forecast_',
  CovidCommercialVaccines = 'product_covid_supply_and_production_',
  CardioConference = 'product_esc2021_',
  Influenza = 'product_influenza_',
  InfluenzaCommercial = 'product_influenza_market_and_production_',
  InfluenzaEpidemiology = 'product_influenza_disease360_',
  RSV = 'product_rsv_',
  RSVEpidemiology = 'product_epidemiology_rsv_',
  RSVCommercial = 'product_rsv_market_and_production_',
  CovidEpidData = 'product_covid_epid_data_',
  Moderna = 'product_covid_booster_market_forecast_',
  CovidVaccineMarketForecast = 'product_covid_vaccine_market_',
  HealthSecurity = 'product_health_security_standard_',
  HealthSecurityPremium = 'product_health_security_premium_',
  Epidemiology = 'product_covid_epidemiology_',
  CovidCommercialTherapeutics = 'product_covid_market_and_production_therapeutics_',
  IEGPolicySurveillance = 'product_real_world_evidence_',
  ResearchPowerIndex = 'product_research_power_index_',
  CardioAntithrombotics = 'product_cardio_antithrombotics_',
  InternalTooling = 'internal_tooling_',
  RNATherapeutics = 'product_rna_therapeutics_',
  PNH = 'product_pnh_',
  IDA360Vaccines = 'product_ida_vaccines_',
  IDA360Treatments = 'product_ida_treatments_',
  IDA360ChikV = 'product_ida_chikv_',
  IDA360Amr = 'product_ida_amr_',
  IDA360Malaria = 'product_ida_malaria_',
  IDA360Dengue = 'product_ida_dengue_',
}
