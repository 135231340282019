import { useOktaAuth } from '@okta/okta-react'
import * as Sentry from '@sentry/react'
import axios, { AxiosError } from 'axios'

const getHeader = (loggedIn: boolean, jwt: string) => ({
  headers: {
    ...(loggedIn
      ? { Authorization: `Bearer ${jwt}`, Accept: 'application/json' }
      : {}),
  },
})

const errorFunction = function (error: AxiosError<any>, isLoggedIn: boolean) {
  if (error?.response?.status === 403) {
    if (error.response.data.detail === 'EULA Not Agreed To') {
      window.location.replace('/terms-and-conditions')
      return
    }
    isLoggedIn && window.location.replace('/')
  } else if (error?.response?.status === 401) {
    isLoggedIn && window.location.replace('/logout')
  } else {
    Sentry.captureException(error)
    return Promise.reject(error)
  }
}

export default () => {
  const { authState } = useOktaAuth()

  const loggedIn = authState?.isAuthenticated ?? false
  const jwt = authState?.accessToken?.accessToken ?? ''

  const authHeader = getHeader(loggedIn, jwt)

  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    ...authHeader,
  })

  instance.interceptors.response.use(
    function (response) {
      return response
    },
    (error) => {
      return errorFunction(error, loggedIn)
    }
  )
  return instance
}

/** Use this to create a new axios instance */
export function useAxiosRequestConfig() {
  const { authState } = useOktaAuth()

  const loggedIn = authState?.isAuthenticated ?? false
  const jwt = authState?.accessToken?.accessToken ?? ''

  const authHeader = getHeader(loggedIn, jwt)

  return {
    baseURL: process.env.REACT_APP_API_BASE_URL,
    ...authHeader,
  }
}
