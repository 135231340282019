import { Download, Launch } from '@carbon/icons-react'
import { Box, Flex, Icon, Text } from '@chakra-ui/react'
import ReactMarkdown from 'react-markdown'
import html from 'rehype-parse'
// @ts-ignore
import rehypeTruncate from 'rehype-truncate'

import { DownloadPdfReport, ViewPdfReport } from 'components'

import { Report } from 'api/types'

import useTracking from 'tracking/useTracking'

import { formatUtc } from 'utils/formatDate'

import UpSellSection from './UpSell'

type TriggerPropTypes = {
  handleClick: () => void
  children: React.ReactNode
}
const Trigger = ({ handleClick, children }: TriggerPropTypes) => {
  return (
    <Flex
      cursor='pointer'
      border='1px solid'
      alignItems='center'
      justifyContent='center'
      borderRadius='full'
      width='22px'
      height='22px'
      onClick={handleClick}
    >
      {children}
    </Flex>
  )
}

type MiniCardPropTypes = {
  report: Report
  onClick: () => void
}
const MiniCard = ({ report, onClick }: MiniCardPropTypes) => {
  const [tracking] = useTracking()

  const title = report.title
  const date = new Date(report.date)
  const content = report.content
  const url = report.report
  return (
    <Box>
      <Flex cursor='pointer' onClick={onClick} flexDir='column'>
        <Box>
          <Flex mb={2} alignItems='center' justifyContent='space-between'>
            <Text variant='body-bold'>{title}</Text>
            <Text color='gray1' variant='body3-bold'>
              {formatUtc(date, 'dd MMM yyyy')}
            </Text>
          </Flex>
          <Box
            maxW='480px'
            color='gray1'
            fontWeight={400}
            fontSize='14px'
            lineHeight='22px'
          >
            <ReactMarkdown rehypePlugins={[html, rehypeTruncate]}>
              {content}
            </ReactMarkdown>
          </Box>
        </Box>
      </Flex>
      <Flex gap={3} mt={4} onClick={(e) => e.stopPropagation()}>
        {url ? (
          <>
            <DownloadPdfReport
              triggerComponent={({ handleClick }) => (
                <Trigger handleClick={handleClick}>
                  <Icon w='14px' as={Download} />
                </Trigger>
              )}
              url={url}
              title={title}
              onDownload={() => {
                tracking.downloadReport({
                  report: title,
                })
              }}
            />
            <ViewPdfReport
              triggerComponent={({ handleClick }) => (
                <Trigger handleClick={handleClick}>
                  <Icon w='13px' height='13px' as={Launch} />
                </Trigger>
              )}
              url={url}
              onView={() => {
                tracking.openReport({
                  report: title,
                })
              }}
            />{' '}
          </>
        ) : (
          <UpSellSection areaOfInterest={report.apps} title={title} />
        )}
      </Flex>
    </Box>
  )
}

export default MiniCard
