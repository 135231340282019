import { IModel } from 'interfaces/model.interface'
import { ValueType } from 'interfaces/valueType.interface'

import { SortOrders } from 'enums/SortOrders'

import DealType from '../tagColours/DealType'

export type ApprovalsData =
  | 'lookupVaccineName'
  | 'afDealType'
  | 'afAgeGroup'
  | 'organisationsRegulators'
  | 'pressUrl'
  | 'mediaUrl'
  | 'afDecisionDate'
  | 'timeIntervalBetweenDoses'
  | 'afRestrictionsToUse'
  | 'urlRestrictionsToUse'
  | 'afRecommendingMixingDoses'
  | 'urlRecommendingMixingDoses'
  | 'timeIntervalBetweenHeterologousVaccinations'
  | 'urlHeterologousVaccination'
  | 'parentChild'
  | 'countryOrganisationGrantingDecision'
  | 'approvalType'
  | 'indication'
  | 'patientSetting'
  | 'supportingDocumentsUrlApprovalsFda'
  | 'clinicalTrials'
  | 'afDealId'
  | 'media'
  | 'pressReleases'
  | 'countryGrantingDecision'
  | 'associatedDocument'
  | 'linkAfTrUniqueTreatmentNameToBnfFromLinkCandidateList'
  | 'type'
  | 'expeditedStatusDesignation'
  | 'submissionDate'
  | 'approvalDate'
  | 'expectedRolloutDate'
  | 'prophylacticOrTherapeutic'
  | 'indicationDetails'
  | 'approvedRouteOfAdministration'
  | 'dose'
  | 'associatedDocumentUrl'
  | 'candidate'
  | 'candidateType'
  | 'candidateSubType'
  | 'area'
  | 'regulatoryOrganisation'
  | 'doseIntervalLowerInclusive'
  | 'doseIntervalUpperInclusive'
  | 'targetPopulations'
  | 'organisations'
  | 'luCandidateName'
  | 'luCompany'
  | 'targetPopulation'
  | 'ageIndication'
  | 'linkCountry'
  | 'regulatoryBody'
  | 'url'
  | 'approvalDate'
  | 'diseases'
  | 'linkCompaniesFromVaccineCandidateList'
  | 'afTargetPopulationFromVaccineCandidateList'
  | 'cleanedAgeGroup'
  | 'singleDate'
  | 'countryOrganisationGrantingDecision'
  | 'afTargetPopulationFromTreatmentCandidateList'
  | 'luTreatmentType'
  | 'candidateNameFromTreatmentCandidateList'
  | 'candidateNameFromVaccineCandidateList'
  | 'linkCompaniesFromTreatmentCandidateList'
  | 'pathogenTypes'
  | 'internalNotes'
  | 'designationA'
  | 'designationB'
  | 'sponsors'

const allowedAirtables = [
  'API-vaccine-approvals',
  'API-treatments-approvals',
  'API - C-19 Tx Approvals Prophylaxis',
  'API - C-19 Tx Approvals Outpatient',
  'API - C-19 Tx Approvals Inpatient',
  'id_vaccines',
  'id_all',
  'id_treatments',
  'Front_end_influenza_vaccine_approvals',
  'api_RSV_Vaccines',
  'api_RSV_Treatments',
  'rsv_commercial_eligible_population_all',
  'rsv_commercial_eligible_population_vaccines',
  'rsv_commercial_eligible_population_treatments',
] as const

export const ApprovalsModel: IModel<
  Record<ApprovalsData, string>,
  typeof allowedAirtables
> = {
  allowedAirtables,
  displayKey: 'lookupVaccineName',
  name: 'Approvals',
  entityName: 'Approvals',
  searchField: 'lookupVaccineName',
  endpoint: 'approval',
  defaultSortKey: 'approvalDate',
  defaultSortOrder: SortOrders.DESC,
  schema: {
    columns: [
      {
        key: 'pathogenTypes',
        label: 'Pathogen Types',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'lookupVaccineName',
        label: 'Vaccine',
        type: ValueType.MULTI,
        width: 300,
      },
      {
        key: 'afDealType',
        label: 'Deal Type',
        type: ValueType.SINGLE,
        width: 181,
        cellFormat: {
          colours: DealType,
        },
      },
      {
        key: 'afAgeGroup',
        label: 'Age Group',
        type: ValueType.MULTI,
        width: 180,
      },
      {
        key: 'diseases',
        label: 'Diseases',
        type: ValueType.MULTI,
        width: 180,
      },
      {
        key: 'organisationsRegulators',
        label: 'Organisation Granting COVID-19 Vaccine Approval',
        type: ValueType.TEXT,
        width: 200,
      },
      {
        key: 'pressUrl',
        label: 'Press Link',
        type: ValueType.URL,
        width: 151,
      },
      {
        key: 'pressReleases',
        label: 'Press Releases',
        type: ValueType.MULTI,
        width: 151,
      },
      {
        key: 'mediaUrl',
        label: 'Media Link',
        type: ValueType.URL,
        width: 151,
      },
      {
        key: 'media',
        label: 'Media',
        type: ValueType.MULTI,
        width: 151,
      },
      {
        key: 'afDecisionDate',
        label: 'Approval Date',
        type: ValueType.DATE,
        width: 150,
      },
      {
        key: 'timeIntervalBetweenDoses',
        label: 'Time Interval Between Doses',
        type: ValueType.SINGLE,
        width: 201,
      },
      {
        key: 'afRestrictionsToUse',
        label: 'Restrictions To Use',
        type: ValueType.TEXT,
        width: 201,
      },
      {
        key: 'urlRestrictionsToUse',
        label: 'Restrictions To Use Source Link',
        type: ValueType.URL,
        width: 200,
      },
      {
        key: 'afRecommendingMixingDoses',
        label: 'Recommending Heterologous Vaccination For Primary Series',
        type: ValueType.TEXT,
        width: 200,
      },
      {
        key: 'urlRecommendingMixingDoses',
        label: 'Recommending Mixing Doses Source Link',
        type: ValueType.URL,
        width: 201,
      },
      {
        key: 'timeIntervalBetweenHeterologousVaccinations',
        label: 'Time Interval Between Heterologous Vaccinations',
        type: ValueType.SINGLE,
        width: 200,
      },
      {
        key: 'urlHeterologousVaccination',
        label: 'URL Heterologous Vaccinations',
        type: ValueType.URL,
        width: 200,
      },
      {
        key: 'countryOrganisationGrantingDecision',
        label: 'Country Organisation Granting Decision',
        width: 200,
        type: ValueType.TEXT,
      },
      {
        key: 'supportingDocumentsUrlApprovalsFda',
        label: 'Approval Documents',
        width: 150,
        type: ValueType.URL,
      },
      {
        key: 'afDealId',
        label: 'Deal ID',
        width: 150,
        type: ValueType.TEXT,
      },
      {
        key: 'parentChild',
        label: 'Parent Or Child',
        type: ValueType.TEXT,
        width: 200,
        showDetail: false,
        hiddenByDefault: true,
      },
      {
        key: 'countryGrantingDecision',
        label: 'Country Granting Decision',
        type: ValueType.MULTI,
        width: 200,
      },
      {
        key: 'approvalType',
        label: 'Approval Type',
        type: ValueType.MULTI,
        width: 200,
      },
      {
        key: 'patientSetting',
        label: 'Patient Setting',
        type: ValueType.MULTI,
        width: 180,
      },
      {
        key: 'clinicalTrials',
        label: 'Trial Cited In Approval',
        type: ValueType.MULTI,
        width: 180,
      },
      {
        key: 'associatedDocument',
        label: 'Approval Documents',
        type: ValueType.FILES,
        width: 180,
      },
      {
        key: 'linkAfTrUniqueTreatmentNameToBnfFromLinkCandidateList',
        label: 'Treatment Name',
        type: ValueType.MULTI,
        width: 180,
      },
      {
        key: 'type',
        label: 'Type',
        type: ValueType.TEXT,
        width: 180,
      },
      {
        key: 'luCandidateName',
        label: 'Vaccine',
        type: ValueType.TEXT,
        width: 180,
      },
      {
        key: 'luCompany',
        label: 'Company',
        type: ValueType.TEXT,
        width: 180,
      },
      {
        key: 'targetPopulation',
        label: 'Target Population',
        type: ValueType.MULTI,
        width: 180,
      },
      {
        key: 'expeditedStatusDesignation',
        label: 'Expedited Status Designation',
        type: ValueType.MULTI,
        width: 180,
      },
      {
        key: 'ageIndication',
        label: 'Age Group',
        type: ValueType.MULTI,
        width: 180,
      },
      {
        key: 'submissionDate',
        label: 'Submission Date',
        type: ValueType.DATE,
        width: 180,
      },
      {
        key: 'linkCountry',
        label: 'Country Granting',
        type: ValueType.MULTI,
        width: 180,
      },
      {
        key: 'approvalDate',
        label: 'Approval Date',
        type: ValueType.DATE,
        width: 180,
      },
      {
        key: 'expectedRolloutDate',
        label: 'Expected Rollout Date',
        type: ValueType.DATE,
        width: 180,
      },
      {
        key: 'prophylacticOrTherapeutic',
        label: 'Prophylactic or Therapeutic',
        type: ValueType.MULTI,
        width: 180,
      },
      {
        key: 'indicationDetails',
        label: 'Indication Details',
        type: ValueType.MULTI,
        width: 180,
      },
      {
        key: 'approvedRouteOfAdministration',
        label: 'Route of Administration',
        type: ValueType.MULTI,
        width: 180,
      },
      {
        key: 'dose',
        label: 'Doses',
        type: ValueType.MULTI,
        width: 180,
      },
      {
        key: 'associatedDocumentUrl',
        label: 'Associated Document URL',
        type: ValueType.URL,
        width: 180,
      },
      {
        key: 'url',
        label: 'Source',
        type: ValueType.URL,
        width: 180,
      },
      {
        key: 'candidate',
        label: 'Candidate',
        type: ValueType.TEXT,
        width: 180,
      },
      {
        key: 'area',
        label: 'Location',
        type: ValueType.TEXT,
        width: 180,
      },
      {
        key: 'regulatoryOrganisation',
        label: 'Regulatory Body',
        type: ValueType.TEXT,
        width: 180,
      },
      {
        key: 'doseIntervalLowerInclusive',
        label: 'Dose Interval Minimum (inclusive)',
        type: ValueType.TEXT,
        width: 200,
      },
      {
        key: 'doseIntervalUpperInclusive',
        label: 'Dose Interval Maximum (inclusive)',
        type: ValueType.TEXT,
        width: 200,
      },
      {
        key: 'targetPopulations',
        label: 'Approved Population',
        type: ValueType.MULTI,
        width: 200,
      },
      {
        key: 'internalNotes',
        label: 'Additional Information',
        type: ValueType.TEXT,
        width: 300,
      },
      {
        key: 'organisations',
        label: 'Company Developing',
        type: ValueType.MULTI,
        width: 200,
      },
      {
        key: 'sponsors',
        label: 'Company Developing',
        type: ValueType.MULTI,
        width: 200,
      },
      {
        key: 'regulatoryBody',
        label: 'Regulatory Body Granting',
        type: ValueType.MULTI,
        width: 180,
      },
      {
        key: 'candidateType',
        label: 'Candidate Type',
        type: ValueType.MULTI,
        width: 180,
      },
      {
        key: 'candidateSubType',
        label: 'Candidate Sub-type',
        type: ValueType.MULTI,
        width: 180,
      },
      {
        key: 'candidateNameFromVaccineCandidateList',
        label: 'Candidate Name',
        type: ValueType.MULTI,
        width: 180,
      },
      {
        key: 'candidateNameFromTreatmentCandidateList',
        label: 'Candidate Name',
        type: ValueType.MULTI,
        width: 180,
      },
      {
        key: 'linkCompaniesFromTreatmentCandidateList',
        label: 'Company',
        type: ValueType.MULTI,
        width: 180,
      },
      {
        key: 'linkCompaniesFromVaccineCandidateList',
        label: 'Company',
        type: ValueType.MULTI,
        width: 180,
      },
      {
        key: 'afTargetPopulationFromVaccineCandidateList',
        label: 'Target Population',
        type: ValueType.MULTI,
        width: 180,
      },
      {
        key: 'cleanedAgeGroup',
        label: 'Age',
        type: ValueType.MULTI,
        width: 180,
      },
      {
        key: 'countryOrganisationGrantingDecision',
        label: 'Country Granting',
        type: ValueType.MULTI,
        width: 180,
      },
      {
        key: 'singleDate',
        label: 'Date',
        type: ValueType.DATE,
        width: 180,
      },
      {
        key: 'luTreatmentType',
        label: 'Type',
        type: ValueType.MULTI,
        width: 180,
      },
      {
        key: 'afTargetPopulationFromTreatmentCandidateList',
        label: 'Target population',
        type: ValueType.MULTI,
        width: 180,
      },
      {
        key: 'designationA',
        label: 'Vaccine or Treatment',
        type: ValueType.TEXT,
        width: 120,
      },
      {
        key: 'designationB',
        label: 'Category',
        type: ValueType.TEXT,
        width: 120,
      },
      {
        key: 'sponsors',
        label: 'Company',
        type: ValueType.MULTI,
        width: 200,
      },
    ],
  },
}
