import GenericTable from 'modules/Tables'

import { IModel } from 'interfaces/model.interface'
import {
  INavigationPages,
  ISingleNavigationPage,
} from 'interfaces/navigationPage.interface'

import { SortOrders } from 'enums/SortOrders'

import {
  RSVCommercialRevenueModel,
  RSVCommercialSupplyPriceModel,
  RSVCommercialApprovalModel,
} from './RSVCommercialModels'
import warningMessages from './RSVCommercialWarningMessages'
import { RSVCommercialSlugs } from './RSVMarketProductionMenu'

type RSVCommercialPage<TModel extends IModel<any>> = ISingleNavigationPage<
  RSVCommercialSlugs,
  TModel
>

const EligiblePopulation: RSVCommercialPage<typeof RSVCommercialApprovalModel> =
  {
    key: 'RSVCommercialEligiblePopulation',
    path: ['demand', 'eligible-population'],
    model: {
      ...RSVCommercialApprovalModel,
      warningMessage: warningMessages.eligiblePopulation,
      name: 'Eligible Population',
      defaultSortKey: 'area',
      defaultSortOrder: SortOrders.ASC,
    },
    component: GenericTable,
    views: [
      {
        name: 'All',
        airtableName: 'rsv_commercial_eligible_population_all',
        airtableBase: 'lzdb',
      },
      {
        name: 'Vaccine',
        airtableName: 'rsv_commercial_eligible_population_vaccines',
        airtableBase: 'lzdb',
      },
      {
        name: 'Non-vaccine',
        airtableName: 'rsv_commercial_eligible_population_treatments',
        airtableBase: 'lzdb',
      },
    ],
  }

const SupplyPrice: RSVCommercialPage<typeof RSVCommercialSupplyPriceModel> = {
  key: 'RSVCommercialSupplyPrice',
  path: ['supply-price', 'pricing-table'],
  model: {
    ...RSVCommercialSupplyPriceModel,
    name: 'Pricing',
    defaultSortKey: 'candidates',
    defaultSortOrder: SortOrders.ASC,
    warningMessage: warningMessages.pricing,
  },
  component: GenericTable,
  views: [
    {
      name: 'All',
      airtableName: 'rsv_commercial_supply_and_price_deals_all',
      airtableBase: 'lzdb',
    },
    {
      name: 'Vaccines',
      airtableName: 'rsv_commercial_supply_and_price_deals_vaccines',
      airtableBase: 'lzdb',
    },
    {
      name: 'Non-vaccines',
      airtableName: 'rsv_commercial_supply_and_price_deals_treatments',
      airtableBase: 'lzdb',
    },
  ],
}

const Revenue: RSVCommercialPage<typeof RSVCommercialRevenueModel> = {
  key: 'RSVCommercialRevenue',
  path: ['revenue', 'table'],
  model: {
    ...RSVCommercialRevenueModel,
    defaultSortKey: 'financialYear',
    defaultSortObject: [
      {
        id: 'financialYear',
        sortOrder: SortOrders.DESC,
      },
      {
        id: 'quarter',
        sortOrder: SortOrders.ASC,
      },
    ],
    warningMessage: warningMessages.revenue,
  },
  component: GenericTable,
  views: [
    {
      name: 'All',
      airtableName: 'rsv_commercial_revenue',
      airtableBase: 'lzdb',
    },
    {
      name: 'Vaccine',
      airtableName: 'rsv_commercial_revenue_vaccines',
      airtableBase: 'lzdb',
    },
    {
      name: 'Non-Vaccine',
      airtableName: 'rsv_commercial_revenue_treatments',
      airtableBase: 'lzdb',
    },
  ],
}

export const RSVCommercialPages: INavigationPages<RSVCommercialSlugs> = {
  SupplyPrice,
  EligiblePopulation,
  Revenue,
}
