import {
  Flex,
  Grid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react'
import { createElement, Fragment, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { useAppRoute } from 'routes/utils'

import { Panel, PanelBody } from 'components'

import {
  ISingleNavigationPage,
  NavigationPageComponentProps,
} from 'interfaces/navigationPage.interface'

import { apps } from 'config/apps'

import callFunctionOrReturn from 'utils/callFunctionOrReturn'

export type TabbedType = {
  heading: string
  description?: React.ReactNode
  body: React.ReactNode | React.FC<{}> | ISingleNavigationPage<any, any>
}

export type TabData = TabbedType | ISingleNavigationPage<any, any>

export type TabbedDisplayPropTypes = {
  tabs: TabData[]
  variant?: string
  tabListWrapper?: React.FC<any> | null
}

const getTabIndex = (tabs: TabData[], title: string) => {
  return tabs.findIndex((tab) => {
    if ('heading' in tab) {
      return tab.heading === title
    } else {
      return tab.model.name === title
    }
  })
}

function getTabsIndex(tabs: TabData[], hash: string) {
  const parsedHash = hash.replace(/#/g, '')

  const tabIndex =
    parseInt(parsedHash) || getTabIndex(tabs, decodeURIComponent(parsedHash))
  if (tabIndex < 0) return 0
  if (tabIndex > tabs.length) return tabs.length - 1
  return tabIndex
}

function TabbedDisplay({
  tabs,
  variant = 'custom',
  tabListWrapper = (props) => (
    <Grid templateColumns='repeat(5, 1fr)' gap={3} {...props} />
  ),
}: TabbedDisplayPropTypes) {
  const app = useAppRoute()
  const { hash } = useLocation()
  const currentApp = apps[app]

  const TabWrapper = tabListWrapper ?? Fragment

  const getTabTitle = (index: number) => {
    return tabs
      .map((tab) => {
        if ('heading' in tab) {
          return tab.heading
        } else {
          return tab.model.name
        }
      })
      .find((_, i) => i === index)
  }

  useEffect(() => {
    if (!hash) {
      window.location.hash = getTabTitle(0) ?? ''
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Tabs
      overflow='hidden'
      isLazy
      variant={variant}
      index={getTabsIndex(tabs, hash)}
      onChange={(index) => {
        window.location.hash = getTabTitle(index) ?? ''
      }}
    >
      <TabList minHeight='46px' borderBottom='none' zIndex={2}>
        <TabWrapper>
          {tabs.map((tab, i) => {
            if ('heading' in tab) {
              return (
                <Tab key={i}>
                  <Flex
                    flexDirection='column'
                    h='100%'
                    justifyContent='space-around'
                  >
                    {tab.heading}
                    {tab.description && (
                      <Text fontSize='xs' color='gray.600'>
                        {tab.description}
                      </Text>
                    )}
                  </Flex>
                </Tab>
              )
            } else {
              const model = tab.model
              return <Tab key={i}>{model.name}</Tab>
            }
          })}
        </TabWrapper>
      </TabList>
      <TabPanels display='flex' flex={1} overflow='hidden'>
        {tabs.map((tab, i) => {
          const bodyIsPage =
            'heading' in tab &&
            !!tab.body &&
            typeof tab.body === 'object' &&
            'path' in tab.body

          if (
            // If the data is directly a page
            !('heading' in tab) ||
            // Or the body is a page
            bodyIsPage
          ) {
            const currentPage = (
              bodyIsPage ? tab.body : tab
            ) as ISingleNavigationPage<any, any>
            const currentComponent = currentPage.component
            const views = currentPage.views
            const model = currentPage.model

            const componentProps: NavigationPageComponentProps<any> = {
              app: currentApp,
              views,
              model,
              page: currentPage,
            }

            return (
              <TabPanel key={i} flex={1} overflow='hidden' px={0} py={3}>
                {createElement(currentComponent, componentProps)}
              </TabPanel>
            )
          } else {
            // Otherwise, we just render the body as it is
            return (
              <TabPanel key={i} flex={1} pt={3} px={1}>
                <Panel>
                  <PanelBody p={0}>
                    {callFunctionOrReturn(tab.body, undefined)}
                  </PanelBody>
                </Panel>
              </TabPanel>
            )
          }
        })}
      </TabPanels>
    </Tabs>
  )
}

export default TabbedDisplay
