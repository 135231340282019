import GenericTable from 'modules/Tables'

import { IModel } from 'interfaces/model.interface'
import {
  INavigationPages,
  ISingleNavigationPage,
} from 'interfaces/navigationPage.interface'

import { SortOrders } from 'enums/SortOrders'

import { MetaVaccineEfficacyModel } from 'config/common/baseModel/MetaVaccineEfficacy'
import { VaccinesUnderReviewModel } from 'config/common/baseModel/VaccinesUnderReview'

import {
  CombinationVaccineCandidates,
  VaccinesAdverseEventsSafetyModel,
  VaccinesApprovalsDealsModel,
  VaccinesCandidatePapersCorrelatesModel,
  VaccinesCandidatePapersModel,
  VaccinesCandidatesModel,
  VaccinesConferencesModel,
  VaccinesMediaModel,
  VaccinesPhaseIIIOrIVCandidates,
  VaccinesPhaseIOrIICandidates,
  VaccinesPreclinicalCandidates,
  VaccinesPressReleasesModel,
  VaccinesSAEPapersModel,
  VaccinesTrialsModel,
} from '../Vaccines/VaccinesModels'
import { VaccinesSlugs } from './VaccinesMenu'

type VaccinesNavigationPage<TModel extends IModel<any>> = ISingleNavigationPage<
  VaccinesSlugs,
  TModel
>

const Candidates: VaccinesNavigationPage<typeof VaccinesCandidatesModel> = {
  key: 'Candidates',
  path: ['candidates', 'table'],
  component: GenericTable,
  model: VaccinesCandidatesModel,
  views: [
    {
      name: 'All',
      airtableName: 'api_all',
    },
    {
      name: 'Preclinical',
      airtableName: 'api_preclinical',
      overrideModel: VaccinesPreclinicalCandidates,
    },
    {
      name: 'Phase I or II',
      airtableName: 'api_phase_i_or_ii',
      overrideModel: VaccinesPhaseIOrIICandidates,
    },
    {
      name: 'Phase III or IV',
      airtableName: 'api_phase_iii_or_iv',
      overrideModel: VaccinesPhaseIIIOrIVCandidates,
    },
    {
      name: 'Combination Vaccine',
      airtableName: 'api_combination',
      overrideModel: CombinationVaccineCandidates,
    },
  ],
}

const CandidatePapers: VaccinesNavigationPage<
  typeof VaccinesCandidatePapersModel
> = {
  key: 'Publications',
  path: ['science', 'publications'],
  component: GenericTable,
  model: { ...VaccinesCandidatePapersModel, name: 'All' },
  views: [
    {
      name: 'All',
      airtableName: 'api_candidate_papers',
    },
    {
      name: 'Correlates of Protection',
      airtableName: 'api_correlates',
      overrideModel: {
        ...VaccinesCandidatePapersCorrelatesModel,
        name: 'Correlates of Protection',
      },
    },
  ],
}

const PhaseIVaccineTrials: VaccinesNavigationPage<typeof VaccinesTrialsModel> =
  {
    key: 'PhaseVaccineTrials',
    model: { ...VaccinesTrialsModel, name: 'Clinical Trials' },
    path: ['trials', 'table'],
    component: GenericTable,
    views: [
      {
        name: 'Default',
        airtableName: 'api_phase_all',
        overrideModel: (model) => ({
          ...model,
          defaultSortKey: 'afDateAdded',
          defaultSortOrder: SortOrders.DESC,
        }),
      },
    ],
  }

const ResultsRealWorld: VaccinesNavigationPage<
  typeof MetaVaccineEfficacyModel
> = {
  key: 'ResultsRealWorld',
  model: { ...MetaVaccineEfficacyModel, name: 'Real World' },
  path: ['results', 'real-world'],
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableName: 'api_real_world',
    },
  ],
}

const ResultsPhase3: VaccinesNavigationPage<typeof MetaVaccineEfficacyModel> = {
  key: 'ResultsPhase3',
  model: { ...MetaVaccineEfficacyModel, name: 'Phase III' },
  path: ['results', 'phase3'],
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableName: 'api_phase_iii',
    },
  ],
}

const Safety: VaccinesNavigationPage<typeof VaccinesAdverseEventsSafetyModel> =
  {
    key: 'Safety',
    path: null,
    model: VaccinesAdverseEventsSafetyModel,
    component: GenericTable,
    views: [
      {
        name: 'Default',
        airtableName: 'api_platform',
      },
    ],
  }

const Conferences: VaccinesNavigationPage<typeof VaccinesConferencesModel> = {
  key: 'Conferences',
  path: 'conferences',
  model: VaccinesConferencesModel,
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableName: 'api_vaccines',
    },
  ],
}

const VaccineApprovals: VaccinesNavigationPage<
  typeof VaccinesApprovalsDealsModel
> = {
  key: 'VaccinesApproval',
  path: ['approvals', 'table'],
  model: { ...VaccinesApprovalsDealsModel, hideTableHeader: true },
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableName: 'API-vaccine-approvals',
    },
  ],
}

const VaccinesUnderReview: VaccinesNavigationPage<
  typeof VaccinesUnderReviewModel
> = {
  key: 'Vaccines Under Review',
  path: ['approvals', 'vaccines-under-review'],
  model: VaccinesUnderReviewModel,
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableName: 'api',
    },
  ],
}

const PressReleases: VaccinesNavigationPage<typeof VaccinesPressReleasesModel> =
  {
    key: 'PressReleases',
    model: VaccinesPressReleasesModel,
    path: null,
    component: GenericTable,
    views: [
      {
        name: 'Default',
        airtableName: 'api',
      },
    ],
  }
const Media: VaccinesNavigationPage<typeof VaccinesMediaModel> = {
  key: 'Media',
  model: VaccinesMediaModel,
  path: null,
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableName: 'api',
    },
  ],
}

const SAEPapers: VaccinesNavigationPage<typeof VaccinesSAEPapersModel> = {
  key: 'SAEPapers',
  path: null,
  component: GenericTable,
  model: VaccinesSAEPapersModel,
  views: [
    {
      name: 'Neurological Adverse Events',
      airtableName: 'API_Neurological_Adverse_Events',
    },
    {
      name: 'Thrombotic Adverse Events',
      airtableName: 'API_Thrombotic_Adverse_Events',
    },
    {
      name: 'Cardiac Adverse Events',
      airtableName: 'API_Cardiac_Adverse_Events',
    },
  ],
}

export const VaccinesPages: INavigationPages<VaccinesSlugs> = {
  Candidates,
  CandidatePapers,
  PhaseIVaccineTrials,
  ResultsRealWorld,
  ResultsPhase3,
  Safety,
  Conferences,
  VaccineApprovals,
  PressReleases,
  VaccinesUnderReview,
  Media,
  SAEPapers,
}

export const VaccinesSafetyPages: INavigationPages<VaccinesSlugs> = {
  Safety,
}

export const SAEPapersPages: INavigationPages<VaccinesSlugs> = {
  SAEPapers,
}
