import {
  CaretDown,
  CaretUp,
  ArrowDownRight,
  ArrowUpRight,
  CarbonIconType,
  ArrowsHorizontal,
} from '@carbon/icons-react'
import { Box, Flex, Icon, Text, Tooltip, useDisclosure } from '@chakra-ui/react'
import { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'

import {
  AlertsType,
  AlertTrend,
  AlertType,
} from 'api/cms/alerts/useCountryAlerts'

import { formatDate } from 'utils/formatDate'

const alertColors: Record<AlertType, string> = {
  IN: 'blue.500',
  WA: 'red.500',
  HR: 'red.500',
  MR: 'orange.300',
  LR: 'yellow.500',
  UR: 'gray.300',
}
const alertLevel: Record<AlertType, string> = {
  HR: 'High',
  MR: 'Medium',
  LR: 'Low',
  UR: 'Unknown',
  IN: '',
  WA: '',
}
const alertTrend: Record<AlertTrend, CarbonIconType> = {
  UN: ArrowDownRight,
  IN: ArrowUpRight,
  DE: ArrowDownRight,
  ST: ArrowsHorizontal,
}
const alertTrendColor: Record<AlertTrend, string> = {
  UN: 'gray.300',
  IN: 'red.500',
  DE: 'green.500',
  ST: 'yellow.600',
}
const alertTrendText: Record<AlertTrend, string> = {
  UN: 'Undefined Trend',
  IN: 'Increasing Trend',
  DE: 'Decreasing Trend',
  ST: 'Stable Trend',
}

type PropTypes = {
  alert: AlertsType
}

const TrendIcon = ({ trend }: { trend: AlertTrend }) => {
  if (trend === 'UN') return null
  return (
    <Flex mr='12px' h='fit-content' alignItems={'center'} mt='2px'>
      <Tooltip label={alertTrendText[trend]} aria-label='A tooltip'>
        <Icon
          as={alertTrend[trend]}
          color={alertTrendColor[trend]}
          h='20px'
          w='22px'
        />
      </Tooltip>
    </Flex>
  )
}

const AlertCard = ({ alert }: PropTypes) => {
  const { isOpen, onToggle } = useDisclosure()
  const [isHover, setIsHover] = useState(false)
  const {
    title,
    sub_header: subHeader,
    description,
    type,
    date_published: datePublished,
    trend,
  } = alert

  const color = alertColors[type]

  const date = new Date(datePublished)

  return (
    <Box
      border='1px solid'
      rounded={'4px'}
      borderColor='gray.200'
      display='flex'
      mr='1rem'
      mb='0.75rem'
      overflow={'hidden'}
      pos='relative'
      cursor='pointer'
      onClick={onToggle}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      minH='60px'
    >
      <Box color={`${color}`} bg={`${color}`} minW='25px' maxW='25px'>
        <Text
          color='legacy-primary.500'
          fontSize={'11px'}
          style={{
            transform: 'rotate(-90deg)',
            whiteSpace: 'nowrap',
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {alertLevel[type]} {isOpen ? ' Risk' : ''}
        </Text>
      </Box>
      <Box mx='0.5rem' mr='1rem' py='1rem'>
        <Flex>
          <TrendIcon trend={trend} />
          <Text variant={'body'} color='legacy-primary.500'>
            {title}
          </Text>
        </Flex>
        {isOpen && (
          <>
            <Text color='legacy-primary.500' fontSize='sm' mb='4px'>
              {subHeader}
            </Text>
            <Box color='legacy-primary.400' fontSize='sm' mt='0.5rem'>
              <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                {description}
              </ReactMarkdown>
            </Box>
            <Text
              as='span'
              color='legacy-primary.400'
              fontSize={'xs'}
              whiteSpace='nowrap'
            >
              {formatDate(date)}
            </Text>
          </>
        )}
      </Box>
      {isHover && (
        <Box pos='absolute' bottom='0px' right={'4px'} id='dropdown'>
          <Icon
            as={isOpen ? CaretUp : CaretDown}
            color={'legacy-primary.400'}
          />
        </Box>
      )}
    </Box>
  )
}

export default AlertCard
