import { IDAApprovalsDetailView } from 'routes/apps/ida360/Approvals'
import { IDACandidatesDetailView } from 'routes/apps/ida360/Candidates'
import { IDANewsPressDetailView } from 'routes/apps/ida360/NewsPress'
import { IDARevenueDetailView } from 'routes/apps/ida360/Revenue'
import { IDASupplyDealsDetailView } from 'routes/apps/ida360/SupplyDeals'
import { IDAClinicalTrialsDetailView } from 'routes/apps/ida360/Trials'

import { SortOrders } from 'enums/SortOrders'

import {
  CandidateRankingModel,
  ClinicalTrialsModel,
} from 'config/common/baseModel'
import { ApprovalsModel } from 'config/common/baseModel/Approvals'
import { IDTreatmentPremiumDealsModel } from 'config/common/baseModel/ida360/IDPremiumDeals'
import { IDPremiumRevenueModel } from 'config/common/baseModel/ida360/IDPremiumRevenue'
import { IDTreatment360NewsAndPressReleasesModel } from 'config/common/baseModel/ida360/IDTreatment360NewsAndPressReleases'

import {
  compose,
  updateColumnAttributes,
  whiteListColumn,
} from 'utils/overrideModel'

const LatestPhaseManualSortingObject = {
  sortOrderObject: [
    'null',
    'Validation',
    'Not Applicable',
    'Unknown',
    'Inactive',
    'Discontinued',
    'Paused',
    'Product Launch',
    'Approved',
    'Phase IV',
    'Preclinical',
    'Phase 0',
    'Phase I',
    'Phase I/II',
    'Phase II',
    'Phase II/III',
    'Phase III',
    'Phase III/IV',
  ],
}

export const IDA360CandidatesModel = compose<typeof CandidateRankingModel>(
  whiteListColumn([
    'name',
    'latestPhaseManual',
    'organisations',
    'alternativeNames',
    'diseaseName',
    'pathogenTypes',
    'designationB',
    'routeOfAdministration',
    'type',
    'subType',
    'vaccineAdjuvants',
    'vaccineTargetPopulation',
    'vaccineValency',
    'targetViralStrain',
    'deliverySystemName',
  ]),
  updateColumnAttributes('latestPhaseManual', LatestPhaseManualSortingObject)
)({
  ...CandidateRankingModel,
  defaultSortObject: [
    {
      id: 'latestPhaseManual',
      sortOrder: SortOrders.DESC,
    },
    {
      id: 'name',
      sortOrder: SortOrders.ASC,
    },
  ],
  detailViewType: 'Generic',
  renderDetailView: IDACandidatesDetailView,
  searchField: 'name',
  displayKey: 'name',
  quickFilter: 'diseaseName',
})

export const IDA360CandidatesAllModel = compose<typeof CandidateRankingModel>(
  whiteListColumn([
    'name',
    'latestPhaseManual',
    'organisations',
    'alternativeNames',
    'diseaseName',
    'pathogenTypes',
    'designationA',
    'designationB',
    'routeOfAdministration',
    'type',
    'subType',
    'vaccineAdjuvants',
    'vaccineTargetPopulation',
    'targetDomains',
    'treatmentStagesOfIntervention',
    'treatmentPatientSettings',
    'vaccineValency',
    'targetViralStrain',
    'deliverySystemName',
  ]),
  updateColumnAttributes('latestPhaseManual', LatestPhaseManualSortingObject)
)({
  ...CandidateRankingModel,
  defaultSortObject: [
    {
      id: 'latestPhaseManual',
      sortOrder: SortOrders.DESC,
    },
    {
      id: 'name',
      sortOrder: SortOrders.ASC,
    },
  ],
  renderDetailView: IDACandidatesDetailView,
  detailViewType: 'Generic',
  searchField: 'name',
  displayKey: 'name',
  quickFilter: 'diseaseName',
})

const IDA360CandidatesRankingModelModel: typeof CandidateRankingModel = {
  ...CandidateRankingModel,
  defaultSortObject: [
    {
      id: 'latestPhaseManual',
      sortOrder: SortOrders.DESC,
    },
    {
      id: 'name',
      sortOrder: SortOrders.ASC,
    },
  ],
  renderDetailView: IDACandidatesDetailView,
  searchField: 'name',
  detailViewType: 'Generic',
  displayKey: 'name',
  quickFilter: 'diseaseName',
}

export const IDA360CandidatesTreatmentsModel = compose<
  typeof CandidateRankingModel
>(
  whiteListColumn([
    'name',
    'latestPhaseManual',
    'organisations',
    'alternativeNames',
    'diseaseName',
    'pathogenTypes',
    'designationB',
    'routeOfAdministration',
    'type',
    'subType',
    'targetDomains',
    'treatmentStagesOfIntervention',
    'treatmentPatientSettings',
    'targetViralStrain',
    'deliverySystemName',
  ]),
  updateColumnAttributes('latestPhaseManual', LatestPhaseManualSortingObject)
)(IDA360CandidatesRankingModelModel)

export const IDA360CandidatesAMRModel = compose<typeof CandidateRankingModel>(
  whiteListColumn([
    'targetViralStrain',
    'name',
    'latestPhaseManual',
    'organisations',
    'alternativeNames',
    'diseaseName',
    'pathogenTypes',
    'designationB',
    'routeOfAdministration',
    'type',
    'subType',
    'targetDomains',
    'treatmentStagesOfIntervention',
    'treatmentPatientSettings',
  ]),
  updateColumnAttributes('latestPhaseManual', LatestPhaseManualSortingObject)
)(IDA360CandidatesRankingModelModel)

export const IDA360TrialsModel = compose<typeof ClinicalTrialsModel>(
  whiteListColumn([
    'id',
    'title',
    'diseaseName',
    'pathogenTypes',
    'acronym',
    'candidates',
    'developers',
    'designationA',
    'designationB',
    'candidateType',
    'candidateSubType',
    'sponsors',
    'latestPhaseFinal',
    'status',
    'startDate',
    'enrolledAt',
    'primaryCompletionDate',
    'completedAt',
    'targetEnrollment',
    'numberOfParticipants',
    'studyType',
    'ages',
    'patientSetting',
    'rawPrimaryOutcomeMeasures',
    'areas',
    'url',
  ])
)({
  ...ClinicalTrialsModel,
  chartColumnBlackList: ['id'],
  name: 'Trials',
  endpoint: 'clinical-trial',
  displayKey: 'id',
  renderDetailView: IDAClinicalTrialsDetailView,
  defaultSortKey: 'completedAt',
  defaultSortOrder: SortOrders.DESC,
  searchField: 'diseaseName',
  quickFilter: 'diseaseName',
})

export const IDA360ApprovalsModel = compose<typeof ApprovalsModel>(
  whiteListColumn([
    'candidate',
    'diseases',
    'pathogenTypes',
    'sponsors',
    'area',
    'regulatoryOrganisation',
    'targetPopulations',
    'internalNotes',
    'type',
    'expeditedStatusDesignation',
    'submissionDate',
    'approvalDate',
    'candidateType',
    'candidateSubType',
    'associatedDocumentUrl',
  ])
)({
  ...ApprovalsModel,
  name: 'Approvals',
  displayKey: 'candidate',
  detailViewType: 'Generic',
  defaultSortKey: 'approvalDate',
  defaultSortOrder: SortOrders.DESC,
  renderDetailView: IDAApprovalsDetailView,
  quickFilter: 'diseases',
})

export const IDA360DealsModel = compose<typeof IDTreatmentPremiumDealsModel>(
  whiteListColumn([
    'recipients',
    'recipientIncomeBand',
    'recipientRegion',
    'suppliers',
    'diseaseName',
    'pathogenTypes',
    'candidates',
    'categoryDetails',
    'procurementMechanism',
    'quantity',
    'quantityUnit',
    'quantityMethodologyType',
    'quantityMethodologyDescription',
    'pricePerUnitUsd',
    'pricePerUnitMethodologyType',
    'dateStart',
    'dateEnd',
    'market',
    'description',
    'sources',
  ])
)({
  ...IDTreatmentPremiumDealsModel,
  name: 'Deals',
  displayKey: 'candidates',
  detailViewType: 'Generic',
  defaultSortObject: [
    {
      id: 'dateStart',
      sortOrder: SortOrders.DESC,
    },
    {
      id: 'recipients',
      sortOrder: SortOrders.ASC,
    },
  ],
  renderDetailView: IDASupplyDealsDetailView,
  quickFilter: 'diseaseName',
})

export const IDA360DealsPriceOrientedModel = compose<
  typeof IDTreatmentPremiumDealsModel
>(
  whiteListColumn([
    'diseaseName',
    'candidates',
    'pricePerUnitUsd',
    'pricePerUnitMethodologyType',
    'procurementMechanism',
    'recipients',
    'recipientIncomeBand',
    'recipientRegion',
    'suppliers',
    'categoryDetails',
    'quantity',
    'quantityUnit',
    'quantityMethodologyType',
    'quantityMethodologyDescription',
    'dateStart',
    'dateEnd',
    'market',
    'sources',
  ])
)({
  ...IDTreatmentPremiumDealsModel,
  name: 'Deals',
  displayKey: 'candidates',
  detailViewType: 'Generic',
  defaultSortObject: [
    {
      id: 'recipients',
      sortOrder: SortOrders.ASC,
    },
    {
      id: 'dateStart',
      sortOrder: SortOrders.DESC,
    },
    {
      id: 'suppliers',
      sortOrder: SortOrders.ASC,
    },
    {
      id: 'candidates',
      sortOrder: SortOrders.ASC,
    },
  ],
  defaultSortOrder: SortOrders.ASC,
  renderDetailView: IDASupplyDealsDetailView,
  quickFilter: 'diseaseName',
})

export const IDA360PremiumRevenueModel = compose<typeof IDPremiumRevenueModel>(
  whiteListColumn([
    'id',
    'organisations',
    'financialYear',
    'quarter',
    'diseases',
    'pathogenTypes',
    'candidates',
    'valueUsd',
    'areas',
    'rssArticles',
  ])
)({
  ...IDPremiumRevenueModel,
  name: 'Revenue',
  detailViewType: 'Generic',
  defaultSortKey: 'dateStart',
  defaultSortOrder: SortOrders.DESC,
  renderDetailView: IDARevenueDetailView,
  quickFilter: 'diseases',
})

export const NewsPressReleasesModel = compose<
  typeof IDTreatment360NewsAndPressReleasesModel
>(
  whiteListColumn([
    'title',
    'descriptionClean',
    'diseases',
    'pathogenTypes',
    'candidates',
    'designationA',
    'designationB',
    'type',
    // 'subType',
    'organisations',
    'publishedAt',
    'feedCategory',
    'feedDescription',
  ])
)({
  ...IDTreatment360NewsAndPressReleasesModel,
  name: 'News & Press Releases',
  defaultSortKey: 'publishedAt',
  searchField: 'title',
  defaultSortOrder: SortOrders.DESC,
  detailViewType: 'Generic',
  renderDetailView: IDANewsPressDetailView,
  quickFilter: 'diseases',
})

export const NewsPressReleasesVaccineTreatmentModel = compose<
  typeof IDTreatment360NewsAndPressReleasesModel
>(
  whiteListColumn([
    'title',
    'descriptionClean',
    'diseases',
    'candidates',
    'designationB',
    'type',
    'organisations',
    'publishedAt',
    'feedCategory',
    'feedDescription',
  ])
)({
  ...IDTreatment360NewsAndPressReleasesModel,
  name: 'News & Press Releases',
  defaultSortKey: 'publishedAt',
  searchField: 'title',
  defaultSortOrder: SortOrders.DESC,
  detailViewType: 'Generic',
  renderDetailView: IDANewsPressDetailView,
  quickFilter: 'diseases',
})
