import { INavigationMenu } from 'interfaces/navigationMenu.interface'

export type Covid19CommercialTherapeuticsSlugs =
  | ''
  | 'organisations'
  | 'dashboard'
  | 'developers'
  | 'manufacturers'
  | 'supply'
  | 'overview'
  | 'timeseries'
  | 'production'
  | 'price'
  | 'revenue'
  | 'to-date'
  | 'forecast-report'
  | 'reports'
  | 'production-agreements'
  | 'uptake'
  | 'country-profile'

const Covid19CommercialTherapeuticsMenu: Array<
  INavigationMenu<Covid19CommercialTherapeuticsSlugs>[]
> = [
  [
    {
      title: 'Dashboard',
      slug: '',
      createdAt: new Date(2022, 3, 27),
    },
    {
      title: 'Reports',
      slug: 'reports',
      createdAt: new Date(2022, 4, 5),
    },
  ],
  [
    {
      title: 'Organisations',
      slug: 'organisations',
      description:
        'Every company or organisation currently involved in the research or production of a potential, or approved, COVID-19 treatment candidate.',
      createdAt: new Date(2022, 1, 7),
      children: [
        {
          title: 'Developers',
          slug: 'developers',
          createdAt: new Date(2022, 1, 7),
        },
        {
          title: 'Manufacturers',
          slug: 'manufacturers',
          createdAt: new Date(2022, 1, 7),
        },
      ],
    },
    {
      title: 'Production',
      slug: 'production',
      createdAt: new Date(2022, 1, 7),
      children: [
        {
          title: 'Production Agreements',
          slug: 'production-agreements',
          createdAt: new Date(2022, 4, 11),
        },
      ],
    },
    {
      title: 'Supply',
      slug: 'supply',
      createdAt: new Date(2022, 1, 7),
      children: [
        {
          title: 'Overview',
          slug: 'overview',
          createdAt: new Date(2022, 3, 27),
        },
        {
          title: 'Timeseries',
          slug: 'timeseries',
          createdAt: new Date(2022, 3, 27),
        },
      ],
    },
    {
      title: 'Uptake',
      slug: 'uptake',
      createdAt: new Date(2022, 4, 16),
      children: [
        {
          title: 'Country Profile',
          slug: 'country-profile',
          createdAt: new Date(2022, 12, 12),
        },
      ],
    },
    {
      title: 'Price',
      slug: 'price',
      createdAt: new Date(2022, 1, 7),
    },
    {
      title: 'Revenue',
      slug: 'revenue',
      createdAt: new Date(2022, 3, 27),
      children: [
        {
          title: 'To Date',
          slug: 'to-date',
          createdAt: new Date(2022, 3, 27),
        },
        {
          title: 'Forecast Report',
          slug: 'forecast-report',
          createdAt: new Date(2022, 4, 18),
        },
      ],
    },
  ],
]

export { Covid19CommercialTherapeuticsMenu }
