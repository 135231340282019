import { Box } from '@chakra-ui/react'

import {
  Panel,
  PanelBody,
  PanelHeader,
  PanelTitle,
  TableauEmbed,
} from 'components'

import { ISingleCustomPage } from 'interfaces/navigationPage.interface'

import { CovidEpidDataSlugs } from './CovidEpidDataMenu'

const Dashboard: ISingleCustomPage<CovidEpidDataSlugs> = {
  path: '',
  component: () => (
    <Box py={3} overflowX='hidden' overflowY='scroll' h='100%'>
      <Panel overflow='hidden' height='100%'>
        <PanelHeader>
          <Box display='flex' flexDirection='row' alignItems='center'>
            <PanelTitle>COVID Epidemiology Data</PanelTitle>
          </Box>
        </PanelHeader>
        <PanelBody height='100%'>
          <TableauEmbed
            path='/COVIDEpidemiologyDashboard/CovidEpidemiolgyDashboard'
            height={`calc(100vh - 170px)`}
          />
        </PanelBody>
      </Panel>
    </Box>
  ),
}

export const CovidEpidDataCustomPages: Record<
  string,
  ISingleCustomPage<CovidEpidDataSlugs>
> = {
  Dashboard,
}
