import { NavLink } from 'react-router-dom'

import { INavigationMenu } from 'interfaces/navigationMenu.interface'

import PrimaryLink from '../PrimaryLink'
import NoAccessNavigationEntry from './NoAccessNavigationEntry'

type SimpleNavigationEntryPropType = {
  menu: INavigationMenu<any>
  onSelect: () => void
  isActive: boolean
  isFirst?: boolean
  isLast?: boolean
  link: string
  isDropdownChild?: boolean
  hasAccess?: boolean
}

export default function SimpleNavigationEntry({
  menu,
  onSelect,
  isActive,
  isFirst,
  isLast,
  link,
  isDropdownChild,
  hasAccess,
}: SimpleNavigationEntryPropType) {
  if (!hasAccess) {
    return (
      <NoAccessNavigationEntry
        isFirst={isFirst}
        isLast={isLast}
        isDropdownChild={isDropdownChild}
        isActive={isActive}
        title={menu.title}
      />
    )
  }
  return (
    <NavLink
      key={menu.slug}
      to={link}
      style={{
        display: 'block',
      }}
      onClick={onSelect}
      data-cy='sidebar-navigation-item'
    >
      <PrimaryLink
        isFirst={isFirst}
        isLast={isLast}
        isDropdownChild={isDropdownChild}
        isActive={isActive}
      >
        {menu.title}
      </PrimaryLink>
    </NavLink>
  )
}
