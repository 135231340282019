import { INavigationMenu } from 'interfaces/navigationMenu.interface'

export type VaccinesSlugs =
  | ''
  | 'candidates'
  | 'table'
  | 'pipeline'
  | 'science'
  | 'candidate-papers'
  | 'neutralising-antibodies'
  | 'vaccine-approval-guidelines'
  | 'booster-campaigns'
  | 'reports'
  | 'standard'
  | 'combination-vaccines'
  | 'trials'
  | 'recent-trials-map'
  | 'results'
  | 'real-world'
  | 'phase3'
  | 'performance-against-variants'
  | 'heterologous-vs-homologous-performance'
  | 'bivalent-mrna-effectiveness'
  | 'safety'
  | 'all'
  | 'trials-adverse-events-frequency'
  | 'serious-adverse-events'
  | 'conferences'
  | 'organisations'
  | 'deals-and-donations'
  | 'approvals'
  | 'all'
  | 'this-week'
  | 'news'
  | 'policies'
  | 'vaccine-performance'
  | 'progress-tracker'
  | 'overall-progress'
  | 'metric-breakdown'
  | 'landscape'
  | 'vaccines-under-review'
  | 'total-per-country-vaccine'
  | 'duration-timeline'
  | 'publications'

const VaccinesMenu: Array<INavigationMenu<VaccinesSlugs>[]> = [
  [
    {
      title: 'Latest Information',
      slug: '',
      description:
        'The latest news articles, publications, tweets and weekly reports for COVID-19 vaccines.',
      createdAt: new Date(2021, 3, 1),
    },
    {
      title: 'Reports',
      slug: 'reports',
      createdAt: new Date(2022, 7, 24),
      children: [
        {
          title: 'Reports',
          slug: 'standard',
          createdAt: new Date(2022, 7, 24),
        },
        {
          title: 'Combination Vaccines',
          slug: 'combination-vaccines',
          createdAt: new Date(2022, 7, 24),
        },
      ],
    },
  ],
  [
    {
      title: 'Vaccine Performance',
      slug: 'vaccine-performance',
      description:
        'Airfinity Meta-Analysis tool combining vaccine efficacy and real-world effectiveness from preprints, journal papers and media.',
      createdAt: new Date(2021, 3, 1),
    },
    {
      title: 'Candidates',
      slug: 'candidates',
      description: 'See information on all COVID-19 vaccine candidates.',
      createdAt: new Date(2020, 7, 13),
      children: [
        {
          title: 'Table',
          slug: 'table',
          createdAt: new Date(2020, 7, 13),
        },
        {
          title: 'Pipeline',
          slug: 'pipeline',
          createdAt: new Date(2020, 4, 11),
        },
        {
          title: 'Progress Tracker',
          slug: 'progress-tracker',
          createdAt: new Date(2022, 5, 9),
        },
      ],
    },
    {
      title: 'New Science',
      slug: 'science',
      description:
        'All scientific papers related to vaccine candidates split into relevant sections.',
      createdAt: new Date(2020, 7, 13),
      children: [
        {
          title: 'Publications',
          slug: 'publications',
          createdAt: new Date(2020, 7, 13),
        },
      ],
    },
    {
      title: 'Booster Campaigns',
      slug: 'booster-campaigns',
      description: 'In depth insights into various topics related to COVID-19.',
      createdAt: new Date(2021, 3, 1),
    },
    {
      title: 'Trials',
      slug: 'trials',
      description: 'List of clinical trials involving COVID-19 vaccines.',
      createdAt: new Date(2020, 9, 8),
      children: [
        {
          title: 'Table',
          slug: 'table',
          createdAt: new Date(2020, 9, 21),
        },
        {
          title: 'Recent Trials Map',
          slug: 'recent-trials-map',
          createdAt: new Date(2022, 2, 29),
        },
        {
          title: 'Landscape',
          slug: 'landscape',
          createdAt: new Date(2022, 2, 29),
        },
      ],
    },
    {
      title: 'Results',
      slug: 'results',
      description: 'See clinical and real-world data on COVID-19 vaccines.',
      createdAt: new Date(2021, 2, 3),
      children: [
        {
          title: 'Real World',
          slug: 'real-world',
          createdAt: new Date(2021, 2, 3),
        },
        { title: 'Phase III', slug: 'phase3', createdAt: new Date(2021, 2, 3) },
        {
          title: 'Performance Against Variants',
          slug: 'performance-against-variants',
          createdAt: new Date(2022, 1, 7),
        },
        {
          title: 'Heterologous vs Homologous Performance',
          slug: 'heterologous-vs-homologous-performance',
          createdAt: new Date(2022, 2, 29),
        },
        {
          title: 'Bivalent mRNA Effectiveness',
          slug: 'bivalent-mrna-effectiveness',
          createdAt: new Date(2023, 2, 29),
        },
      ],
    },
    {
      title: 'Safety',
      slug: 'safety',
      description: 'Information on adverse events for COVID-19 vaccines.',
      createdAt: new Date(2021, 3, 15),
      children: [
        {
          title: 'Real World',
          slug: 'real-world',
          createdAt: new Date(2021, 4, 20),
        },
        {
          title: 'Trials - Adverse Events Frequency',
          slug: 'trials-adverse-events-frequency',
          createdAt: new Date(2022, 4, 3),
        },
        {
          title: 'Serious Adverse Events',
          slug: 'serious-adverse-events',
          createdAt: new Date(2022, 8, 29),
        },
      ],
    },
    {
      title: 'Conferences',
      slug: 'conferences',
      description: 'List of relevant conferences related to COVID-19.',
      createdAt: new Date(2020, 7, 13),
    },
    {
      title: 'Approvals',
      slug: 'approvals',
      createdAt: new Date(2022, 2, 11),
      children: [
        {
          title: 'Table',
          slug: 'table',
          createdAt: new Date(2022, 2, 11),
        },
        {
          title: 'Approval Duration Timeline',
          slug: 'duration-timeline',
          createdAt: new Date(2022, 2, 11),
        },
        {
          title: 'Total per Country & Vaccine',
          slug: 'total-per-country-vaccine',
          createdAt: new Date(2022, 2, 11),
        },
        {
          title: 'This Week',
          slug: 'this-week',
          createdAt: new Date(2022, 4, 3),
        },
        {
          title: 'Vaccines Under Review',
          slug: 'vaccines-under-review',
          createdAt: new Date(2022, 12, 7),
        },
      ],
    },
  ],
]

export { VaccinesMenu }
