import ReportPage from 'routes/common/FullReportPage'

import { TableauEmbed } from 'components'

import { ISingleCustomPage } from 'interfaces/navigationPage.interface'

import { IDA360Slugs } from './IDA360Menu'

const MainDashboard: ISingleCustomPage<IDA360Slugs> = {
  path: [''],
  component: () => (
    <TableauEmbed path='/InfectiousDiseaseAnalyticsIDA360/InfectiousDiseaseAnalyticsIDA360Bar' />
  ),
}

const Reports: ISingleCustomPage<IDA360Slugs> = {
  path: 'reports',
  component: () => <ReportPage showCategory={false} showAppFilter />,
}

const CandidatePipeline: ISingleCustomPage<IDA360Slugs> = {
  path: ['candidates', 'pipeline'],
  component: () => (
    <TableauEmbed path='/CandidatePipelineindications/VaccineCandidatePipeline' />
  ),
}

const TrialsOngoingMap: ISingleCustomPage<IDA360Slugs> = {
  path: ['trials', 'map'],
  component: () => (
    <TableauEmbed path='/IDA360ClinicalTrials/IDA360Clinicaltrialdashboard' />
  ),
}

const ApprovalsMap: ISingleCustomPage<IDA360Slugs> = {
  path: ['approvals', 'map'],
  component: () => <TableauEmbed path='/IDA360Approvals/IDA360Approvals' />,
}
const PriceDashboard: ISingleCustomPage<IDA360Slugs> = {
  path: ['supply-price', 'price-dashboard'],
  component: () => (
    <TableauEmbed path='/IDA360Supplydata/VaccinePriceDashboardV2-Fixed' />
  ),
}
const SupplyDashboard: ISingleCustomPage<IDA360Slugs> = {
  path: ['supply-price', 'supply-dashboard'],
  component: () => (
    <TableauEmbed path='/IDA360Supplydata/VaccineSupplyDashboardV1' />
  ),
}
const RevenueDashboard: ISingleCustomPage<IDA360Slugs> = {
  path: ['revenue', 'revenue-dashboard'],
  component: () => <TableauEmbed path='/RevenueInformation-new/Revenue' />,
}

export const IDA360CustomPages: Record<
  string,
  ISingleCustomPage<IDA360Slugs>
> = {
  MainDashboard,
  Reports,
  CandidatePipeline,
  TrialsOngoingMap,
  ApprovalsMap,
  PriceDashboard,
  SupplyDashboard,
  RevenueDashboard,
}
