import { INavigationMenu } from 'interfaces/navigationMenu.interface'

export type InfluenzaCommercialSlugs =
  | ''
  | 'supply-deals'
  | 'table'
  | 'by-country'
  | 'by-company'
  | 'price-vs-doses'
  | 'total-per-vaccine-name'
  | 'pricing-table'
  | 'production'
  | 'reports'
  | 'immunisation-schedules'
  | 'company-production'
  | 'procurement-reimbursement-archetypes'
  | 'tenders'
  | 'market-share-and-size'
  | 'market-access-and-regulatory'
  | 'global-revenue-and-market-share'
  | 'number-of-tenders'
  | 'doses-tendered'
  | 'value-and-price'

const InfluenzaCommercialMenu: Array<
  INavigationMenu<InfluenzaCommercialSlugs>[]
> = [
  [
    {
      title: 'Demand Forecast',
      slug: '',
      createdAt: new Date(2021, 9, 8),
    },
    {
      title: 'Tenders',
      slug: 'tenders',
      createdAt: new Date(2022, 6, 5),
      children: [
        {
          title: 'Table',
          slug: 'table',
          createdAt: new Date(2022, 6, 5),
        },
        {
          title: 'Number of Tenders',
          slug: 'number-of-tenders',
          createdAt: new Date(2023, 1, 8),
        },
        {
          title: 'Doses Tendered',
          slug: 'doses-tendered',
          createdAt: new Date(2023, 1, 8),
        },
        {
          title: 'Value & Price',
          slug: 'value-and-price',
          createdAt: new Date(2023, 1, 8),
        },
      ],
    },
    {
      title: 'Immunisation Schedules',
      slug: 'immunisation-schedules',
      createdAt: new Date(2022, 6, 24),
    },
  ],
  [
    {
      title: 'Supply Deals',
      slug: 'supply-deals',
      createdAt: new Date(2022, 5, 22),
      children: [
        {
          title: 'Table',
          slug: 'table',
          createdAt: new Date(2021, 9, 8),
        },
        {
          title: 'Pricing Table',
          slug: 'pricing-table',
          createdAt: new Date(2021, 9, 8),
        },
        {
          title: 'By Country',
          slug: 'by-country',
          createdAt: new Date(2022, 5, 22),
        },
        {
          title: 'By Company',
          slug: 'by-company',
          createdAt: new Date(2022, 5, 22),
        },
        {
          title: 'Price vs Doses',
          slug: 'price-vs-doses',
          createdAt: new Date(2022, 5, 22),
        },
        {
          title: 'Total per Vaccine Name',
          slug: 'total-per-vaccine-name',
          createdAt: new Date(2022, 5, 22),
        },
      ],
    },
    {
      title: 'Market Share & Size',
      slug: 'market-share-and-size',
      createdAt: new Date(2022, 7, 10),
      children: [
        {
          title: 'Global Revenue & Market Share',
          slug: 'global-revenue-and-market-share',
          createdAt: new Date(2023, 1, 8),
        },
      ],
    },
    {
      title: 'Market Access & Regulatory',
      slug: 'market-access-and-regulatory',
      createdAt: new Date(2022, 7, 10),
      children: [
        {
          title: 'Procurement & Reimbursement Archetypes',
          slug: 'procurement-reimbursement-archetypes',
          createdAt: new Date(2022, 5, 28),
        },
      ],
    },
    {
      title: 'Reports',
      slug: 'reports',
      description: 'In-depth insights on topics relevant to Influenza',
      createdAt: new Date(2021, 9, 12),
    },
  ],
]

export { InfluenzaCommercialMenu }
