import { INavigationMenu } from 'interfaces/navigationMenu.interface'

export type InfluenzaSlugs =
  | ''
  | 'candidates'
  | 'table'
  | 'pipeline'
  | 'progress-tracker'
  | 'science'
  | 'trials'
  | 'all-data'
  | 'recent-trials-map'
  | 'news'
  | 'candidate-papers'
  | 'reports'
  | 'standard'
  | 'combination-reports'
  | 'results'
  | 'approvals'
  | 'map'
  | 'vaccines'
  | 'non-vaccines'
  | 'conferences'
  | 'publications'

const InfluenzaMenu: Array<INavigationMenu<InfluenzaSlugs>[]> = [
  [
    {
      title: 'Latest Information',
      slug: '',
      description:
        'The most recent news reports, press releases, publications, tweets and reports',
      createdAt: new Date(2021, 9, 8),
    },
    {
      title: 'Reports',
      slug: 'reports',
      description: 'In-depth insights on topics relevant to Influenza',
      createdAt: new Date(2021, 9, 12),
      children: [
        {
          title: 'Reports',
          slug: 'standard',
          createdAt: new Date(2021, 9, 8),
        },
        {
          title: 'Combination Reports',
          slug: 'combination-reports',
          createdAt: new Date(2020, 4, 11),
        },
      ],
    },
  ],
  [
    {
      title: 'Candidates',
      slug: 'candidates',
      description:
        'See all information on influenza vaccine and treatment candidates',
      createdAt: new Date(2021, 9, 8),
      children: [
        {
          title: 'Table',
          slug: 'table',
          createdAt: new Date(2021, 9, 8),
        },
        {
          title: 'Pipeline',
          slug: 'pipeline',
          createdAt: new Date(2020, 4, 11),
        },
        {
          title: 'Progress Tracker',
          slug: 'progress-tracker',
          createdAt: new Date(2022, 5, 23),
        },
      ],
    },
    {
      title: 'Approvals',
      slug: 'approvals',
      description: '',
      createdAt: new Date(2022, 11, 29),
      children: [
        {
          title: 'Table',
          slug: 'table',
          createdAt: new Date(2023, 1, 6),
        },
        {
          title: 'Map',
          slug: 'map',
          createdAt: new Date(2022, 11, 29),
        },
      ],
    },
    {
      title: 'New Science',
      slug: 'science',
      description:
        'The latest scientific publications on influenza vaccines and treatments',
      createdAt: new Date(2021, 9, 8),
      children: [
        {
          title: 'Publications',
          slug: 'publications',
          createdAt: new Date(2021, 9, 8),
        },
      ],
    },
    {
      title: 'Results',
      slug: 'results',
      createdAt: new Date(2022, 10, 23),
      children: [
        {
          title: 'Vaccines',
          slug: 'vaccines',
          createdAt: new Date(2022, 10, 23),
        },
        {
          title: 'Non-Vaccines',
          slug: 'non-vaccines',
          createdAt: new Date(2022, 10, 23),
        },
      ],
    },
    {
      title: 'Trials',
      slug: 'trials',
      description:
        'List of clinical trials involving influenza vaccines and treatments',
      createdAt: new Date(2021, 9, 8),
      children: [
        {
          title: 'All Data',
          slug: 'all-data',
          createdAt: new Date(2021, 9, 8),
        },
        {
          title: 'Recent Trials Map',
          slug: 'recent-trials-map',
          createdAt: new Date(2022, 2, 29),
        },
      ],
    },
    {
      title: 'Conferences',
      slug: 'conferences',
      createdAt: new Date(2023, 1, 19),
    },
  ],
]

export { InfluenzaMenu }
