import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import useAxios from 'api/useAxios'

export type SummaryDataType = { [key: string]: number }

export default function useDashboardSummaryData() {
  const axios = useAxios()
  return useQuery<SummaryDataType[], AxiosError>(
    ['vaccinesDashboardSummaryData'],
    async () => {
      const requests = [
        axios(`/covid/summary/candidates_added_in_the_past_week/`),
        axios(`/covid/summary/clinical_trials_added_in_the_past_week/`),
        axios(`/covid/summary/total_strategy_papers/`),
      ]
      const response = await Promise.all(requests)
      return response.map((value) => value.data)
    }
  )
}
