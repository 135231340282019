import { Text as ChakraText, BoxProps } from '@chakra-ui/react'

import Boolean from 'modules/Cells/Boolean'
import Date from 'modules/Cells/Date'
import Files from 'modules/Cells/Files'
import Logo from 'modules/Cells/Logo'
import Long from 'modules/Cells/Long'
import SingleTag from 'modules/Cells/SingleTag'
import Stars from 'modules/Cells/Stars'
import Tags from 'modules/Cells/Tags'
import Text from 'modules/Cells/Text'
import Tweet from 'modules/Cells/Tweet'
import URL from 'modules/Cells/URL'

import { ResponseData } from 'api/types'

import { ValueType } from 'interfaces/valueType.interface'

import numberWithCommas from './formatNumber'
import {
  relationGetDisplayValue,
  relationTransformValue,
  TransformValueFunction,
} from './relational'

export type FormatValueProps = {
  type?: ValueType
  value: ResponseData
  width?: string | number
  format?: string
  maxLines?: number
  maxTags?: number
  colours?: any
  wrap?: boolean
  boxStyles?: BoxProps
  transformValue?: TransformValueFunction
  formatForOncology?: boolean
}

const formatValues = ({
  type = ValueType.TEXT,
  value: rawValue,
  colours,
  wrap,
  width,
  maxTags,
  format,
  maxLines,
  boxStyles,
  transformValue,
  formatForOncology,
}: FormatValueProps) => {
  // Handle value transformation
  let formattedValue = !!transformValue
    ? relationTransformValue(rawValue, transformValue)
    : rawValue

  // Handle relational data
  let value = relationGetDisplayValue(formattedValue)

  if (
    value === null ||
    value === undefined ||
    value === '' ||
    (typeof value !== 'number' && value.length === 0)
  ) {
    return (
      <ChakraText color='gray.500' fontSize='12px'>
        N/A
      </ChakraText>
    )
  } else {
    switch (type) {
      // Realistically, we're only going to show relation data as SINGLE or MULTI.
      // So let's just handle for those 2 types. We'll pass the raw value and let them handle it
      case ValueType.MULTI:
        return (
          <Tags
            value={rawValue}
            colours={colours}
            wrap={wrap}
            columnWidth={width as number}
            {...boxStyles}
          />
        )
      case ValueType.SINGLE:
        return (
          <SingleTag
            value={rawValue}
            colours={colours}
            wrap={wrap}
            columnWidth={width as number}
            {...boxStyles}
          />
        )
      case ValueType.URL:
        return <URL value={rawValue} />
      case ValueType.DATE:
        return <Date value={value} dateFormat={format} />
      case ValueType.LOGO:
        return <Logo value={value} />
      case ValueType.LONG:
        return (
          <Long
            value={value}
            maxLines={maxLines}
            wrap={wrap}
            formatForOncology={formatForOncology}
          />
        )
      case ValueType.FILES:
        return <Files value={rawValue} />
      case ValueType.TWEET:
        return <Tweet value={value} />
      case ValueType.STAR:
        return <Stars value={value} />
      case ValueType.BOOLEAN:
        return <Boolean value={value} />
      case ValueType.NUMBER:
        return <Text value={numberWithCommas(value)} format={format} />
      case ValueType.TEXT:
      default:
        return <Text value={value} format={format} {...boxStyles} />
    }
  }
}

export default formatValues
