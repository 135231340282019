import { Edit } from '@carbon/icons-react'
import { Box, Button, ButtonProps, Text, useDisclosure } from '@chakra-ui/react'

import RenameUserViewModal from './RenameUserViewModal'

type PropTypes = {
  currentViewName: string
  onRenameUserView: (title: string) => void
} & ButtonProps

export default function RenameUserViewButton({
  currentViewName,
  onRenameUserView,
  ...buttonProps
}: PropTypes) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Button variant='clean' onClick={onOpen} {...buttonProps}>
        <Edit size={16} />
        <Box mr={1} />
        <Text fontSize='12px'>Rename</Text>
      </Button>
      <RenameUserViewModal
        currentViewName={currentViewName}
        isOpen={isOpen}
        onClose={onClose}
        onRenameUserView={onRenameUserView}
      />
    </>
  )
}
