import { IModel } from 'interfaces/model.interface'
import { ValueType } from 'interfaces/valueType.interface'

import { aggregateGetFirst } from 'utils/grouping'

export type VaccineAdverseEventData =
  | 'name'
  | 'parentOrChild'
  | 'linkToCandidateList'
  | 'afSource'
  | 'afEstimatedNumberOfDosesAdministeredAtLastDataPoint'
  | 'influenzaLikeIllness'
  | 'cerebralVenousSinusThrombosis'
  | 'splanchnicVeinThrombosis'
  | 'arterialThrombosis'
  | 'deepVeinThrombosis'
  | 'thrombosisSiteUnspecified'
  | 'thrombocytopeniaIncludingImmune'
  | 'disseminatedIntravascularCoagulation'
  | 'capillaryLeakSyndrome'
  | 'pulmonaryEmbolism'
  | 'cerebralHaemorrhage'
  | 'cerebralInfarction'
  | 'cerebralThrombosis'
  | 'facialParalysisBellsPalsy'
  | 'transverseMyelitis'
  | 'afDataUpTo'
  | 'sourceLink'
  | 'vaccine'
  | 'otherCerebralVenousThromboses'
  | 'anaphylacticReaction'
  | 'anaphylacticShock'
  | 'seizure'
  | 'myocarditis'
  | 'guillainBarreSyndrome'
  | 'ageGroup'
  | 'sex'
  | 'ischaemicStroke'
  | 'pericarditis'

const allowedAirtables = ['api_platform'] as const

export const VaccineAdverseEventModel: IModel<
  Record<VaccineAdverseEventData, string>,
  typeof allowedAirtables
> = {
  allowedAirtables,
  displayKey: 'name',
  name: 'Safety',
  entityName: 'Adverse Events',
  endpoint: 'vaccine-adverse-event',
  detailViewType: 'Generic',
  searchField: 'name',
  defaultSortKey: 'name',
  schema: {
    columns: [
      {
        key: 'name',
        label: 'Name',
        width: 150,
        type: ValueType.TEXT,
        hiddenByDefault: true,
      },
      {
        key: 'parentOrChild',
        label: 'Relationship',
        showDetail: false,
        hiddenByDefault: true,
        width: 150,
        type: ValueType.TEXT,
      },
      {
        key: 'linkToCandidateList',
        aggregate: aggregateGetFirst,
        label: 'Candidate List',
        width: 200,
        type: ValueType.MULTI,
      },
      { key: 'afSource', label: 'Source', width: 180, type: ValueType.TEXT },
      {
        key: 'afEstimatedNumberOfDosesAdministeredAtLastDataPoint',
        label: 'Estimated Number Of Doses Administered At Last Data Point',
        width: 150,
        type: ValueType.NUMBER,
      },
      {
        key: 'influenzaLikeIllness',
        label: 'Influenza Like Illness',
        width: 150,
        type: ValueType.NUMBER,
      },
      {
        key: 'cerebralVenousSinusThrombosis',
        label: 'Cerebral Venous Sinus Thrombosis',
        width: 150,
        type: ValueType.NUMBER,
      },
      {
        key: 'splanchnicVeinThrombosis',
        label: 'Splanchnic Vein Thrombosis',
        width: 150,
        type: ValueType.NUMBER,
      },
      {
        key: 'arterialThrombosis',
        label: 'Arterial Thrombosis',
        width: 150,
        type: ValueType.NUMBER,
      },
      {
        key: 'deepVeinThrombosis',
        label: 'Deep Vein Thrombosis',
        width: 150,
        type: ValueType.NUMBER,
      },
      {
        key: 'thrombosisSiteUnspecified',
        label: 'Thrombosis (Site Unspecified)',
        width: 150,
        type: ValueType.NUMBER,
      },
      {
        key: 'thrombocytopeniaIncludingImmune',
        label: 'Thrombocytopenia (Including Immune)',
        width: 150,
        type: ValueType.NUMBER,
      },
      {
        key: 'disseminatedIntravascularCoagulation',
        label: 'Disseminated Intravascular Coagulation',
        width: 150,
        type: ValueType.NUMBER,
      },
      {
        key: 'capillaryLeakSyndrome',
        label: 'Capillary Leak Syndrome',
        width: 150,
        type: ValueType.NUMBER,
      },
      {
        key: 'pulmonaryEmbolism',
        label: 'Pulmonary Embolism',
        width: 150,
        type: ValueType.NUMBER,
      },
      {
        key: 'cerebralHaemorrhage',
        label: 'Cerebral Haemorrhage',
        width: 150,
        type: ValueType.NUMBER,
      },
      {
        key: 'cerebralInfarction',
        label: 'Cerebral Infarction',
        width: 150,
        type: ValueType.NUMBER,
      },
      {
        key: 'cerebralThrombosis',
        label: 'Cerebral Thrombosis',
        width: 150,
        type: ValueType.NUMBER,
      },
      {
        key: 'facialParalysisBellsPalsy',
        label: 'Facial Paralysis / Bells Palsy',
        width: 150,
        type: ValueType.NUMBER,
      },
      {
        key: 'transverseMyelitis',
        label: 'Transverse Myelitis',
        width: 150,
        type: ValueType.NUMBER,
      },
      {
        key: 'afDataUpTo',
        label: 'Data Up To',
        width: 150,
        type: ValueType.DATE,
      },
      {
        key: 'sourceLink',
        label: 'Source Link',
        width: 120,
        type: ValueType.URL,
      },
      {
        key: 'vaccine',
        label: 'Candidate',
        width: 200,
        type: ValueType.MULTI,
        aggregate: aggregateGetFirst,
      },
      {
        key: 'otherCerebralVenousThromboses',
        label: 'Other Cerebral Venous Thromboses',
        width: 150,
        type: ValueType.NUMBER,
      },
      {
        key: 'anaphylacticReaction',
        label: 'Anaphylactic Reaction',
        width: 150,
        type: ValueType.NUMBER,
      },
      {
        key: 'anaphylacticShock',
        label: 'Anaphylactic Shock',
        width: 150,
        type: ValueType.NUMBER,
      },
      {
        key: 'seizure',
        label: 'Seizure',
        width: 130,
        type: ValueType.NUMBER,
      },
      {
        key: 'myocarditis',
        label: 'Myocarditis',
        width: 130,
        type: ValueType.NUMBER,
      },
      {
        key: 'guillainBarreSyndrome',
        label: 'Guillain-Barré Syndrome',
        width: 130,
        type: ValueType.NUMBER,
      },
      {
        key: 'ageGroup',
        label: 'Age group',
        width: 100,
        type: ValueType.MULTI,
      },
      {
        key: 'sex',
        label: 'Sex',
        width: 100,
        type: ValueType.MULTI,
      },
      {
        key: 'ischaemicStroke',
        label: 'Ischaemic Stroke',
        width: 130,
        type: ValueType.NUMBER,
      },
      {
        key: 'pericarditis',
        label: 'Pericarditis',
        width: 100,
        type: ValueType.NUMBER,
      },
    ],
  },
}
