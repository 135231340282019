import { Box, Flex, Heading } from '@chakra-ui/react'

import { useTablePageData } from 'modules/Tables/TablePageProvider'

import { AdvancedFilterUpdaterProp } from 'components/Table/AdvancedSearch/useAdvancedFilters'

import QuickFilter from './QuickFilter'
import WarningPopover from './WarningPopover'

type PropTypes = {
  title: string
  isLoading?: boolean
  applyFilter?: (e: AdvancedFilterUpdaterProp) => void
}

const PageHeader = ({ title, isLoading, applyFilter }: PropTypes) => {
  const { model } = useTablePageData()
  return (
    <Flex
      flexDir={'column'}
      border={!!model.quickFilter ? '1px solid' : 'none'}
      borderColor={'gray3'}
      w='50%'
      borderRadius={'8px'}
      mb={!!model.quickFilter ? '24px' : 0}
    >
      <Flex
        padding={!!model.quickFilter ? '8px 24px' : '0'}
        h='50px'
        alignItems='center'
        pb={4}
      >
        {!model.hideTableHeader && (
          <Heading
            fontSize='26px'
            color='primary'
            lineHeight='38px'
            fontWeight='semibold'
            letterSpacing='-0.1px'
          >
            {title}
          </Heading>
        )}
        <WarningPopover message={model.warningMessage} />
      </Flex>
      {!!model.quickFilter && (
        <Box bg='gray5' padding={'8px 24px'} h='50px'>
          {!isLoading && applyFilter && (
            <QuickFilter quickFilter={applyFilter} />
          )}
        </Box>
      )}
    </Flex>
  )
}

export default PageHeader
