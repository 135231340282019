import { Copy } from '@carbon/icons-react'
import {
  Alert,
  AlertDescription,
  AlertTitle,
  Box,
  Button,
  Input,
  InputGroup,
  InputRightElement,
  Tooltip,
  useClipboard,
} from '@chakra-ui/react'
import ReactMarkdown from 'react-markdown'
import { Link } from 'react-router-dom'

import { InlineError, InlineLoading } from 'components'

import useAPIReadme from 'api/forecast/useAPIReadme'
import useAPIToken from 'api/forecast/useAPIToken'

const APIPage = () => {
  const {
    data: readme,
    isLoading: readmeIsLoading,
    isError: readmeIsError,
  } = useAPIReadme()
  const {
    data: token,
    isLoading: tokenIsLoading,
    isError: tokenIsError,
  } = useAPIToken()

  const { hasCopied, onCopy } = useClipboard(token ?? '')

  if ([readmeIsLoading, tokenIsLoading].includes(true)) {
    return <InlineLoading />
  }
  if ([readmeIsError, tokenIsError].includes(true)) {
    return <InlineError />
  }

  return (
    <Box px={10}>
      <ReactMarkdown className='markdown'>{readme ?? ''}</ReactMarkdown>

      <Alert bg='gray4' status='info' borderRadius={4} mb={4} maxWidth='xl'>
        <Box flex={1}>
          <AlertTitle mb={2} color='black'>
            Your Authentication Token
          </AlertTitle>
          <AlertDescription>
            <InputGroup size='md'>
              <Input
                readOnly
                onFocus={(event) => event.target.select()}
                value={token}
                backgroundColor='white'
                textShadow='none'
              />
              <InputRightElement width='5.5rem'>
                <Button variant='clean' onClick={() => onCopy()}>
                  {hasCopied ? (
                    'Copied!'
                  ) : (
                    <Tooltip label='Copy'>
                      <Copy size={16} />
                    </Tooltip>
                  )}
                </Button>
              </InputRightElement>
            </InputGroup>
          </AlertDescription>
        </Box>
      </Alert>
      <Link to='/api-spec?ref=/settings/api'>
        <Button variant='yellow'>View Forecast API Specification</Button>
      </Link>
    </Box>
  )
}

export default APIPage
