import { Box } from '@chakra-ui/react'
import { NAV_HEIGHT } from 'constants/misc'
import { useEffect, useState } from 'react'

import { FullPageError, LoadingAnimation } from 'components'

import useAxios from 'api/useAxios'
import { permissionCheck } from 'api/useTableauAuth'

import useTracking from 'tracking/useTracking'

import { TableauPaths } from './paths'

export const TableauEmbed = ({
  path,
  height = `calc(100vh - ${NAV_HEIGHT})`,
  token,
}: {
  path: TableauPaths
  height?: string | string[]
  token?: string
}) => {
  const [data, setData] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<unknown>(null)

  const axios = useAxios()

  const [tracking] = useTracking()

  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://tableau.airfinity.com/javascripts/api/viz_v1.js'
    script.async = false
    document.body.appendChild(script)

    const getToken = async () => {
      try {
        setIsLoading(true)
        const res = await permissionCheck(axios)
        setData(res)
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
        setError(error)
      }
    }
    getToken()
    tracking.viewTableau({})

    return () => {
      document.body.removeChild(script)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (error) {
    return <FullPageError error={error} />
  }
  if (isLoading) {
    return <LoadingAnimation />
  }
  return (
    <Box height={height}>
      <iframe
        id='tableau_embedded'
        src={`https://tableau.airfinity.com/trusted/${
          token ?? data
        }/views${path}?:embed=yes&:toolbar=no&:tabs=no`}
        height='100%'
        width='100%'
        scrolling='no'
        title='Interactive or visual content'
        sandbox='allow-same-origin allow-forms allow-scripts allow-downloads allow-popups allow-popups-to-escape-sandbox allow-top-navigation-by-user-activation'
        frameBorder='0'
      />
    </Box>
  )
}
