import { Box, Text, Flex } from '@chakra-ui/react'
import _ from 'lodash'
import { useCallback, useEffect, useMemo, useRef } from 'react'

import ReportCard from 'modules/DashboardReports/ReportCard'

import { useAppRoute } from 'routes/utils'

import { InlineLoading } from 'components'

import useHighlights from 'api/cms/highlights/useHighlights'
import useReports from 'api/cms/reports/useReports'

import { apps } from 'config/apps'

const ReportSection = ({
  handleShowMore,
  showMore,
  heightCB,
}: {
  handleShowMore: () => void
  showMore: boolean
  heightCB: (h: string) => void
}) => {
  const selectedApp = useAppRoute()
  const reportRef = useRef(null)
  const selectedAppObject = useMemo(() => apps[selectedApp], [selectedApp])
  const {
    isError: isHighlightError,
    data: highlights,
    isFetched: highlightsFetched,
    isLoading: highlightsLoading,
  } = useHighlights(selectedApp)

  const reportsRes = useReports(selectedApp, ['updates'])

  const reportsLoading = reportsRes.some((res) => res.isLoading)
  const reportsFetched = reportsRes.some((res) => res.isFetched)
  const isReportError = reportsRes.some((res) => res.isError)

  const rawReports = useMemo(
    () =>
      reportsRes
        ?.map((reports) => reports?.data)
        .flat()
        .filter((report) => !!report)
        .filter((report) => !!report?.report),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reportsLoading]
  )

  const reports = _.orderBy(rawReports, 'date', 'desc')

  function calcRowHeight() {
    const height = (reportRef?.current as any)?.offsetHeight - 11
    height && reports?.[0] && heightCB(`${height}px`)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandleResize = useCallback(_.debounce(calcRowHeight, 100), [])

  useEffect(() => {
    calcRowHeight()
    window.addEventListener('resize', () => debouncedHandleResize())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [(reportRef?.current as any)?.offsetHeight, reports?.[0]])

  if (isHighlightError && isReportError) {
    return (
      <Flex w='50%' alignItems='center' justifyContent='center'>
        Sorry, something went wrong loading the reports
      </Flex>
    )
  }
  if (!highlights && !reports && highlightsFetched && reportsFetched) {
    return (
      <Flex w='50%' alignItems='center' justifyContent='center'>
        No reports at the moment
      </Flex>
    )
  }

  const report = reports[0]

  return (
    <Flex w='100%' flexDir={'column'} h='fit-content' ref={reportRef}>
      <Text
        fontSize={['lg', 'lg', 'xl']}
        mr='0.5rem'
        ml='4px'
        mb='1.75rem'
        fontWeight={'500'}
      >
        Latest Report
      </Text>
      {(reportsLoading || highlightsLoading) && <InlineLoading />}
      <Box h='100%' overflowY={'auto'}>
        {report && (
          <ReportCard
            onResize={() => {}}
            app={selectedAppObject}
            report={report}
          />
        )}
      </Box>
    </Flex>
  )
}

export default ReportSection
