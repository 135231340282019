import { Text } from '@chakra-ui/react'

import RSVDetailView from 'routes/apps/rsv/Candidates'

import { SortOrders } from 'enums/SortOrders'

import { ApprovalsModel } from 'config/common/baseModel/Approvals'
import { RespiratoryCandidatesModel } from 'config/common/baseModel/respiratory360/RespiratoryCandidates'
import { RespiratoryToolMetaVaccineEfficacyModel } from 'config/common/baseModel/respiratory360/RespiratoryToolMetaVaccineEfficacy'

import {
  compose,
  renameColumn,
  updateColumnAttributes,
  whiteListColumn,
} from 'utils/overrideModel'

export const RSVCandidatesVaccineModel = compose<
  typeof RespiratoryCandidatesModel
>(
  whiteListColumn([
    'linkInfectiousDisease',
    'candidateName',
    'alternateNames',
    'manualLatestPhase',
    'influenzaValency',
    'vaccineType',
    'adjuvanted',
    'linkCompanies',
    'linkResearchInstitutions',
    'administrationRoute',
  ])
)({
  ...RespiratoryCandidatesModel,
  name: 'Vaccine Candidates',
  endpoint: 'vaccine-candidate',
  renderDetailView: RSVDetailView,
  defaultSortOrder: SortOrders.ASC,
  defaultSortKey: 'manualLatestPhase',
})
export const RSVCandidatesCombinationVaccinesModel = compose<
  typeof RespiratoryCandidatesModel
>(
  whiteListColumn([
    'linkInfectiousDisease',
    'candidateName',
    'alternateNames',
    'manualLatestPhase',
    'vaccineGroupTypeSplit',
    'linkCompanies',
    'linkResearchInstitutions',
    'administrationRoute',
  ])
)({
  ...RespiratoryCandidatesModel,
  name: 'Combination vaccines',
  renderDetailView: RSVDetailView,
  endpoint: 'vaccine-candidate',
  defaultSortOrder: SortOrders.ASC,
  defaultSortKey: 'manualLatestPhase',
})

export const RSVToolMetaVaccineEfficacyModel = compose<
  typeof RespiratoryToolMetaVaccineEfficacyModel
>(
  whiteListColumn([
    'candidateNameFromVaccineCandidate',
    'companies',
    'phasesCleanFromClinicalTrialId',
    'trialType',
    'achievedPrimaryEndpoint',
    'criteriaForPrimaryEndpoint',
    'studyPopulationType',
    'clinicalTrialId',
    'genPop',
    'efficacyEffectiveness',
    'confidenceIntervalOfEfficacy',
    'adverseEventsMildModerate',
    'adverseEventsSevere',
    'trialSize',
    'numberDoses',
    'dosingInterval',
    'dose',
    'numberOfConfirmedCases',
    'numberOfCasesInVaccinatedGroup',
    'numberOfCasesOnPlaceboGroup',
    'trialLocations',
    'articleLink',
    'dataSource',
  ])
)({
  ...RespiratoryToolMetaVaccineEfficacyModel,
  name: 'Vaccine Efficacy',
  description: () => (
    <Text fontSize='sm' mb={3}>
      These vaccines have not been compared in head to heads and thus,
      differences should be interpreted with caution.
    </Text>
  ),
  excludeGeneratedCharts: true,
})

export const RSVToolMetaNonVaccineEfficacyModel = compose<
  typeof RespiratoryToolMetaVaccineEfficacyModel
>(
  whiteListColumn([
    'candidateNameFromTreatmentCandidate',
    'manualLatestPhaseFromTreatmentCandidate',
    'linkCompaniesFromTreatmentCandidate',
    'trialType',
    'trialPhase',
    'achievedPrimaryEndpoint',
    'treatmentTimingFromTreatmentCandidate',
    'treatmentTypeFromTreatmentCandidate',
    'directVsHostTargetedFromTreatmentCandidate',
    'studyPopulationType',
    'clinicalTrialId',
    'genPop',
    'efficacyResults',
    'efficacyRelativeReductionInHospitalisation',
    'efficacyAdditionalSAndSScoreReduction',
    'efficacyAdditionalReductionViralLoad',
    'pooledEfficacyRelativeReductionInHospitalisation',
    'efficacyRelativeReductionInInfection',
    'efficacyAucMeanViralLoad',
    'efficacyAucMeanSymptomScore',
    'adverseReactions',
    'safetySevereAdverseReactions',
    'safetyGeGrade3',
    'safetyUpperRespiratoryInfection',
    'safetyPharyngitis',
    'safetyRhinitis',
    'safetyOtitisMedia',
    'safetyPain',
    'safetyHeadache',
    'safetyRash',
    'safetyHernia',
    'safetyGastrointestinalDisorders',
    'safetyDiarrhoea',
    'safetyDeath',
    'dataSource',
  ]),
  renameColumn('candidateNameFromVaccineCandidate', 'Non-Vaccine'),
  renameColumn('safetySevereAdverseReactions', 'Serious Adverse Reactions'),
  updateColumnAttributes('studyPopulationType', { width: 180 })
)({
  ...RespiratoryToolMetaVaccineEfficacyModel,
  displayKey: 'candidateNameFromTreatmentCandidate',
  searchField: 'candidateNameFromTreatmentCandidate',
  excludeGeneratedCharts: true,
})

export const RsvApprovalsVaccinesModel = compose<typeof ApprovalsModel>(
  whiteListColumn([
    'candidateNameFromVaccineCandidateList',
    'linkCompaniesFromVaccineCandidateList',
    'afTargetPopulationFromVaccineCandidateList',
    'cleanedAgeGroup',
    'approvalType',
    'countryOrganisationGrantingDecision',
    'regulatoryBody',
    'url',
    'singleDate',
  ])
)({
  ...ApprovalsModel,
  name: 'Approvals',
  defaultSortKey: 'singleDate',
})
export const RsvApprovalsNonVaccinesModel = compose<typeof ApprovalsModel>(
  whiteListColumn([
    'candidateNameFromTreatmentCandidateList',
    'luTreatmentType',
    'linkCompaniesFromTreatmentCandidateList',
    'afTargetPopulationFromTreatmentCandidateList',
    'indication',
    'patientSetting',
    'cleanedAgeGroup',
    'approvalType',
    'countryOrganisationGrantingDecision',
    'regulatoryBody',
    'url',
    'singleDate',
  ])
)({
  ...ApprovalsModel,
  name: 'Approvals',
})
