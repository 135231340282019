import { BookmarkFilled, CheckmarkFilled, TrashCan } from '@carbon/icons-react'
import {
  Box,
  IconButton,
  Icon,
  Heading,
  Img,
  Tooltip,
  useToast,
} from '@chakra-ui/react'
import { useContext, useCallback } from 'react'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'

import { AuthContext } from 'contexts'
import { useBookmark } from 'contexts/Bookmark'

import { Upsell } from 'components'

import { Report } from 'api/types'

import { IApp } from 'interfaces/navigationApp.interface'

import useTracking from 'tracking/useTracking'

import { formatDate } from 'utils/formatDate'

import ReportViewOrDownload from './ReportViewOrDownload'
import TableOfContent from './TableOfContent'

type PropTypes = {
  report: Report
  app?: IApp<any>
  reportId?: number
  onResize?: () => void
}

const ReportCard = ({ app, onResize, reportId, report }: PropTypes) => {
  const { userIsBasicWithinApp } = useContext(AuthContext)
  const { findReportBookmark, createBookmark, deleteBookmark } = useBookmark()
  const [tracking] = useTracking()
  const toast = useToast()

  const bookmarkReport = useCallback(
    (id: string) => {
      const currentBookmark = app && findReportBookmark(app.slug, id)

      if (!!currentBookmark) {
        deleteBookmark({ id: currentBookmark.id })
      } else {
        createBookmark({
          app: app!.slug,
          type: 'report',
          createdAt: new Date(),
          metadata: { id, extraKey: '' },
        })
        toast({
          duration: 3000,
          position: 'top',
          render: () => (
            <Box
              as='a'
              display='block'
              href='/bookmarks'
              color='black'
              p={3}
              bg='secondary'
              onClick={() => tracking.openBookmarks({})}
            >
              <Icon m='0 8px -3px 0' boxSize='20px' as={CheckmarkFilled} />
              Bookmark added. Click here to view all your bookmarks
            </Box>
          ),
        })
      }
    },
    [app, createBookmark, deleteBookmark, findReportBookmark, toast, tracking]
  )

  const isBookmarked = app && !!findReportBookmark(app.slug, report.id)

  return (
    <Box
      marginY={3}
      marginX={1}
      border='1px solid'
      borderColor='gray.300'
      borderRadius='8px'
      bg='white'
    >
      <Box>
        {reportId ? (
          <Tooltip label='Delete Bookmark'>
            <IconButton
              aria-label='Delete Bookmark'
              variant='ghost'
              float='right'
              mt='.5rem'
              mr='.5rem'
              width='25px'
              height='25px'
              cursor='pointer'
              onClick={() => {
                deleteBookmark({ id: reportId })
                tracking.removeBookmark({ report: report.title })
              }}
              _hover={{
                backgroundColor: 'none',
              }}
              as={TrashCan}
              color='red'
            />
          </Tooltip>
        ) : (
          <Tooltip label='Bookmark Report' mx='2'>
            <IconButton
              aria-label='Bookmark Report'
              variant='ghost'
              float='right'
              mt='.5rem'
              mr='.5rem'
              width='25px'
              height='25px'
              cursor='pointer'
              onClick={() => {
                bookmarkReport(report.id)
                if (isBookmarked) {
                  tracking.removeBookmark({ report: report.title })
                }
                tracking.createBookmark({ report: report.title })
              }}
              _hover={{
                backgroundColor: 'none',
              }}
              as={BookmarkFilled}
              stroke='gray2'
              fill={isBookmarked ? 'secondary' : 'none'}
            />
          </Tooltip>
        )}
        <Box borderBottom='1px solid' borderColor='gray.300' px={5} py={4}>
          <Heading
            lineHeight='none'
            fontSize='md'
            mb={2}
            fontWeight={600}
            color='legacy-primary.500'
          >
            {report.title}
          </Heading>
          <Box color='gray.700' fontSize='xs' lineHeight='none'>
            Posted on {formatDate(report.date)}
          </Box>
          <TableOfContent onResize={onResize} report={report} />
        </Box>
        <Box px={8} py={5}>
          {report.content && (
            <Box fontSize='sm'>
              {report.cover && (
                <Box mb='1rem'>
                  <Img src={report.cover} />
                </Box>
              )}
              <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                {report.content}
              </ReactMarkdown>
            </Box>
          )}
        </Box>
      </Box>
      {userIsBasicWithinApp(app) ? (
        <Upsell />
      ) : (
        <ReportViewOrDownload report={report} />
      )}
    </Box>
  )
}

export default ReportCard
