import { Box, BoxProps, Button, useDisclosure } from '@chakra-ui/react'
import { useMutation } from '@tanstack/react-query'
import { useCallback, useContext, useMemo } from 'react'

import { useAppRoute } from 'routes/utils'

import { AuthContext } from 'contexts'

import { InlineLoading } from 'components'

import { apps } from 'config/apps'

import { PdfBasicUpsell } from './PdfBasicUpsell'
import PdfVisualizationModal from './pdfModal'
import { usePdfData } from './usePdfData'

export type ViewPdfReportTriggerComponentProps = {
  isLoading: boolean
  disabled: boolean
  handleClick: () => void
  pdfBytes: Uint8Array | null | undefined
}

export type ViewPdfReportPropTypes = {
  url: string
  disabled?: boolean
  onView?: () => void
  triggerComponent?: (
    props: ViewPdfReportTriggerComponentProps
  ) => React.ReactNode
  pageIndex?: number
  searchString?: string
} & BoxProps

export const ViewPdfReport = ({
  url,
  disabled = false,
  onView,
  triggerComponent,
  pageIndex,
  searchString,
  ...props
}: ViewPdfReportPropTypes) => {
  const selectedApp = useAppRoute()
  const { userIsBasicWithinApp } = useContext(AuthContext)
  const { data: pdfBytes, refetch: loadPdf } = usePdfData(url)

  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: upsellIsOpen,
    onOpen: upsellOnOpen,
    onClose: upsellOnClose,
  } = useDisclosure()

  // Here for the loading state
  const { mutate: handleLoadPdf, isLoading } = useMutation(async () => {
    if (!pdfBytes?.length) {
      await loadPdf()
    }
  })

  const handleClick = useCallback(async () => {
    if (userIsBasicWithinApp(apps[selectedApp])) {
      upsellOnOpen()
      return
    }
    await handleLoadPdf()
    onOpen()
    onView?.()
  }, [
    handleLoadPdf,
    onOpen,
    onView,
    selectedApp,
    upsellOnOpen,
    userIsBasicWithinApp,
  ])

  const TriggerComponent = useMemo(
    () =>
      !!triggerComponent &&
      triggerComponent({ isLoading, disabled, handleClick, pdfBytes }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pdfBytes]
  )

  return (
    <Box {...props}>
      {!!triggerComponent ? (
        TriggerComponent
      ) : (
        <Button
          onClick={handleClick}
          disabled={disabled}
          cursor={!isLoading && !disabled ? 'pointer' : 'default'}
          variant='outline'
        >
          {isLoading ? <InlineLoading px={2} /> : 'View Report'}
        </Button>
      )}
      {pdfBytes && (
        <PdfVisualizationModal
          pdfBytes={pdfBytes}
          isOpen={isOpen}
          onClose={onClose}
          pageIndex={pageIndex}
          searchString={searchString}
        />
      )}

      <PdfBasicUpsell isOpen={upsellIsOpen} onClose={upsellOnClose} />
    </Box>
  )
}
