import { Box, Button, Flex } from '@chakra-ui/react'
import { useContext, useEffect, useState } from 'react'
import instance from 'worker'

import { useTablePageData } from 'modules/Tables/TablePageProvider'

import { AuthContext } from 'contexts'

import { Select } from 'components/Select'
import { AdvancedFilterUpdaterProp } from 'components/Table/AdvancedSearch/useAdvancedFilters'

import { ResponseData } from 'api/types'

const QuickFilter = ({
  quickFilter,
}: {
  quickFilter: (e: AdvancedFilterUpdaterProp) => void
}) => {
  const [data, setData] = useState<ResponseData[]>([])
  const [filterValue, setFilter] = useState()
  const { app, model, viewData, filters } = useTablePageData()

  const { userInfo } = useContext(AuthContext)
  const selectedApp = app
  const premiumGroup = selectedApp.premiumGroup || []
  const premiumAccess = userInfo?.groups.includes(premiumGroup[0])
  const endpoint = premiumAccess
    ? selectedApp.premiumSlug || selectedApp.endpoint
    : selectedApp.endpoint

  const advancedFilters = filters

  const column =
    model.schema.columns.find((column) => column.key === model.quickFilter) ||
    ''

  async function getData() {
    if (!column) return
    const res = await instance.getAdvancedFilterMultiOptions({
      appEndpoint: endpoint,
      modelEndpoint: model.endpoint,
      viewData,
      columnName: column?.key || '',
    })
    setData(res)
  }

  useEffect(() => {
    column && getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [column])

  useEffect(() => {
    const defaultFilter = getQuickFilter()
    setFilter(defaultFilter?.[0]?.filterValue?.[0])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advancedFilters])

  function getOtherFilters() {
    if (!Array.isArray(advancedFilters) || !column) return []
    const otherFilters = advancedFilters
      ? advancedFilters.filter((item) => {
          return item.column !== column?.key
        })
      : []
    return otherFilters
  }
  function getQuickFilter() {
    if (!Array.isArray(advancedFilters) || !column) return []
    const otherFilters = advancedFilters
      ? advancedFilters.filter((item) => {
          return item.column === column?.key
        })
      : []
    return otherFilters
  }

  function handleFilter(filterValue: any) {
    setFilter(filterValue)
    column &&
      quickFilter([
        ...getOtherFilters(),
        {
          column: column?.key,
          type: 'includesAny',
          filterValue: [filterValue],
        },
      ])
  }

  function handleClear() {
    setFilter(undefined)
    quickFilter(getOtherFilters())
  }

  return (
    <Flex zIndex={999} alignItems='center' fontSize={'13px'}>
      {column && column?.label}:
      <Box w='200px'>
        <Select
          value={filterValue || ''}
          isMulti={false}
          onChange={handleFilter}
          options={
            data
              ? data.sort().map((x: any) => ({
                  label: x,
                  value: x,
                }))
              : []
          }
          backgroundColor='transparent'
          borderColor='transparent'
          placeholder={`Select an option `}
          fontSize={'13px'}
          noBorder
        />
      </Box>
      {filterValue && (
        <Button
          variant={'clean'}
          onClick={handleClear}
          fontSize='12px'
          height={'auto'}
          padding={'4px 8px'}
        >
          Clear Filter
        </Button>
      )}
    </Flex>
  )
}

export default QuickFilter
