import { ViewFilled, ViewOffFilled } from '@carbon/icons-react'
import { Box, Button, FormControl, List, Spinner, Tag } from '@chakra-ui/react'
import { UseMutationResult } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { useState } from 'react'

import { FormErrorMessage, Input } from 'components'

import { ChangePasswordParam } from 'api/useUserChangePasswordMutation'

type PasswordsTypes = { [key: string]: string }

interface PasswordFormPropTypes {
  handleChangePassword: () => void
  setPassword: (value: any) => void
  apiStatus: UseMutationResult<
    AxiosResponse<any>,
    AxiosError<any>,
    ChangePasswordParam
  >
  passwords: PasswordsTypes
  isFormValid: boolean
}
interface PasswordInputPropTypes {
  setPassword: (value: any) => void
  placeholder: string
  id: string
  passwords: PasswordsTypes
}
const PasswordInput = ({
  id,
  setPassword,
  placeholder,
  passwords,
}: PasswordInputPropTypes) => {
  const [showPassword, setShowPassword] = useState(false)
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword((passwords: PasswordsTypes) => ({
      ...passwords,
      [id]: e.target.value,
    }))
  }
  return (
    <Box position='relative'>
      <Input
        id={id}
        type={showPassword ? 'text' : 'password'}
        placeholder={placeholder}
        onChange={handleChange}
        height='40px'
        name={id}
        value={passwords[id]}
      />
      <Box
        as='button'
        position='absolute'
        top='2px'
        right='16px'
        transform='translateY(10px)'
        onClick={() => setShowPassword((value) => !value)}
      >
        {showPassword ? <ViewOffFilled size={16} /> : <ViewFilled size={16} />}
      </Box>
    </Box>
  )
}

const PasswordsForm = ({
  setPassword,
  handleChangePassword,
  apiStatus,
  passwords,
  isFormValid,
}: PasswordFormPropTypes) => {
  const { isLoading, isError, isSuccess, error } = apiStatus
  const errorMessage = error?.response?.data

  return (
    <FormControl onSubmit={handleChangePassword}>
      <List spacing={3} w='300px' marginBottom={'10px'}>
        <PasswordInput
          id='oldPassword'
          placeholder='Old password'
          setPassword={setPassword}
          passwords={passwords}
        />
        <PasswordInput
          id={'newPassword'}
          placeholder='New password'
          setPassword={setPassword}
          passwords={passwords}
        />
        <PasswordInput
          id={'newRePassword'}
          placeholder='Repeat new password'
          setPassword={setPassword}
          passwords={passwords}
        />
        {isError && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
        {isSuccess && (
          <Tag colorScheme='green' padding={2}>
            Your password has been updated
          </Tag>
        )}
        <Button
          variant='yellow'
          onClick={handleChangePassword}
          size='base'
          width='175px'
          disabled={!isFormValid}
        >
          {isLoading ? <Spinner /> : 'Change Password'}
        </Button>
      </List>
    </FormControl>
  )
}

export default PasswordsForm
