import { User } from '@carbon/icons-react'
import { Box, chakra, Flex } from '@chakra-ui/react'
import { UseQueryResult } from '@tanstack/react-query'
import { useCallback, useEffect } from 'react'
import { TableState } from 'react-table'

import {
  serializeState,
  UserViewConfig,
  UseUserViewsReturn,
} from 'components/Table/useUserViews'

import { UserView } from 'api/types'

import { IBaseView, ViewList } from 'interfaces/navigationPage.interface'

import useTracking from 'tracking/useTracking'

import AddUserViewButton from './UserViews/AddUserViewButton'

type PropTypes = {
  views: ViewList<any>
  selectedView: IBaseView<any>
  handleSetView: (newView: IBaseView<any>) => void
  tableState?: TableState
  userViewConfig?: UserViewConfig
  useUserViewsReturn?: UseUserViewsReturn
}

const TableViewSelector = (props: PropTypes) => {
  return (
    <Box mb='0px'>
      <TableViewSelectorHorizontal {...props} />
    </Box>
  )
}

const BoxBase = chakra(Box, {
  baseStyle: {
    height: '32px',
    px: 3,
    fontWeight: 500,
    fontSize: '12px',
    display: 'inline-flex',
    alignItems: 'center',
    _hover: {
      background: 'yellow.500',
      borderColor: 'yellow.500',
      transition: 'all 0.2s',
    },
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    borderWidth: 1,
    cursor: 'pointer',
    color: 'primary',
    borderRadius: '4px',
  },
})

const TableViewSelectorHorizontal = ({
  views,
  selectedView,
  handleSetView,
  tableState,
  userViewConfig,
  useUserViewsReturn,
}: PropTypes) => {
  const [tracking] = useTracking()

  const {
    userViewQuery: { data: userViews },
    createUserView,
  } = userViewConfig ?? {
    userViewQuery: { data: undefined } as UseQueryResult<UserView[], any>,
    createUserView: () => {},
    updateUserView: () => {},
    deleteUserView: () => {},
  }

  const onCreateNewUserView = useCallback(
    (title: string) => {
      if (!!tableState) {
        const currentUserView = serializeState(tableState)

        createUserView({ name: title, view: currentUserView })
      }
    },
    [tableState, createUserView]
  )

  useEffect(() => {
    const nUserViews = userViews?.length || 0
    if (userViews && nUserViews > 0) {
      const lastView = userViews[nUserViews - 1]
      if (lastView?.id > 0) {
        handleSetView(views[0])
        useUserViewsReturn?.setSelectedUserViewIndex(nUserViews - 1)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userViews])

  return (
    <Flex display='flex' gap='12px' flexWrap='wrap'>
      {views &&
        views.map((view, i) => (
          <BoxBase
            key={i}
            borderColor={
              selectedView.name === view.name &&
              !useUserViewsReturn?.userViewSelected
                ? 'yellow.500'
                : 'primary'
            }
            backgroundColor={
              selectedView.name === view.name &&
              !useUserViewsReturn?.userViewSelected
                ? 'yellow.500'
                : 'white'
            }
            fontWeight={
              selectedView.name === view.name &&
              !useUserViewsReturn?.userViewSelected
                ? 500
                : 400
            }
            onClick={() => {
              tracking.selectDefaultView({
                view: view.name,
              })
              handleSetView(view)
              useUserViewsReturn?.setSelectedUserViewIndex(0)
            }}
          >
            {view.name}
          </BoxBase>
        ))}

      {userViews?.map(
        (userView, i) =>
          // Skip the user view without any state
          i > 0 && (
            <BoxBase
              key={i}
              borderColor={
                selectedView.name === views[0].name &&
                useUserViewsReturn?.selectedUserViewIndex === i
                  ? 'yellow.500'
                  : 'primary'
              }
              backgroundColor={
                selectedView.name === views[0].name &&
                useUserViewsReturn?.selectedUserViewIndex === i
                  ? 'yellow.500'
                  : 'white'
              }
              fontWeight={
                selectedView.name === views[0].name &&
                useUserViewsReturn?.selectedUserViewIndex === i
                  ? 500
                  : 400
              }
              onClick={() => {
                handleSetView(views[0])
                useUserViewsReturn?.setSelectedUserViewIndex(i)
              }}
            >
              <User size={16} />
              <Box mr='6px' />
              {userView.name}
            </BoxBase>
          )
      )}

      {tableState && (
        <AddUserViewButton
          defaultViewName={views[0].name ?? 'Default'}
          onCreateNewUserView={onCreateNewUserView}
        />
      )}
    </Flex>
  )
}

export { TableViewSelector }
