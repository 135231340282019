import { CountriesModel, DealsModel } from 'config/common/baseModel'

import { whiteListColumn, compose, renameColumn } from 'utils/overrideModel'

export const SupplyAndProductionProductionDealsModel = compose<
  typeof DealsModel
>(
  whiteListColumn([
    'lookupVaccineName',
    'afDealType',
    'organisations',
    'countries',
    'afExpectedProduction',
    'mediaUrls',
    'pressUrls',
    'afTypeOfProduction',
    'afExpectedProduction2022',
    'afDateExpectedProductionLastAnnounced',
    'afPublicExpectedProductionNotes',
  ]),
  renameColumn('countries', 'Country source of production')
)(DealsModel)
export const SupplyAndProductionDealsModel = compose<typeof DealsModel>(
  whiteListColumn([
    'lookupVaccineName',
    'afDealType',
    'afPricePerVaccineTreatment',
    'countries',
    'mediaUrls',
    'pressUrls',
    'companyRegions',
  ])
)(DealsModel)

export const DeliveriesCountryModel = compose<typeof CountriesModel>(
  whiteListColumn([
    'name',
    'population',
    'afEconomyIncomeBand',
    'afContinent',
    'fTotalDeliveries',
    'fDeliveriesToDateExcThoseDonated',
    'fBilateralDeliveryToDate',
    'fCovaxDeliveryToDate',
    'fDirectDonationDeliveryToDate',
  ]),
  renameColumn('name', 'Country'),
  renameColumn('afEconomyIncomeBand', 'Income Band')
)(CountriesModel)
