import { Launch } from '@carbon/icons-react'
import { Box } from '@chakra-ui/react'
import Truncate from 'react-truncate'

import { CleanResponseData } from 'api/types'

type PropTypes = {
  value: CleanResponseData
  label?: string
  width?: number
}

function getLink(url: any) {
  if (typeof url === 'string') return url
  if (typeof url.link === 'string') return url.link
  if (typeof url.displayText === 'string') return url.displayText
  return '#'
}

const U = ({ value, label, width, ...rest }: PropTypes) => {
  const v: any[] = Array.isArray(value) ? value : !!value ? [value] : []

  return (
    <Box display='flex' flexWrap='wrap' width={width} {...rest}>
      {v.map((url, i) => (
        <Box
          key={i}
          as='a'
          onClick={(e: any) => e.stopPropagation()}
          target='_blank'
          display='flex'
          alignItems='center'
          href={getLink(url)}
          cursor='pointer'
          width={width}
          whiteSpace='nowrap'
          color='blue.500'
          borderBottom='1px solid'
          borderBottomColor='transparent'
          _hover={{
            borderBottomColor: 'blue.500',
          }}
          mr={1}
          fontSize='12px'
        >
          <Box mr={1}>
            <Launch size={16} />
          </Box>
          <Truncate
            width={width}
            lines={1}
            ellipsis={<span>...</span>}
            trimWhitespace={true}
          >
            {url.title ? url.title : label ? label : 'External link'}
          </Truncate>
        </Box>
      ))}
    </Box>
  )
}

export default U
