import { Flex } from '@chakra-ui/react'

import AppsSelectorPage from 'modules/AppsSelector/AppsSelectorPage'
import Navigation from 'modules/Navigation'

import { Footer } from 'components'

const Base = () => {
  return (
    <>
      <Navigation showSearchBar={false} />

      <Flex flexDir='column' overflow='auto'>
        <AppsSelectorPage />

        <Flex mx='auto' py={5} alignItems='center' justifyContent='center'>
          <Footer />
        </Flex>
      </Flex>
    </>
  )
}

export default Base
