import { INavigationMenu } from 'interfaces/navigationMenu.interface'

export type InfluenzaEpidemiologySlugs =
  | ''
  | 'strain-circulation'
  | 'case-rate-intensity'
  | 'us-severity'
  | 'vaccine-coverage'

const InfluenzaEpidemiologyMenu: Array<
  INavigationMenu<InfluenzaEpidemiologySlugs>[]
> = [
  [
    {
      title: 'Cases',
      slug: '',
      createdAt: new Date(2022, 1, 4),
    },
    {
      title: 'Strain Circulation',
      slug: 'strain-circulation',
      createdAt: new Date(2022, 5, 10),
    },
    {
      title: 'US Severity',
      slug: 'us-severity',
      createdAt: new Date(2022, 8, 1),
    },
    {
      title: 'Vaccine Coverage',
      slug: 'vaccine-coverage',
      createdAt: new Date(2022, 5, 20),
    },
  ],
]

export { InfluenzaEpidemiologyMenu }
