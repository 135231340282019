import { IApp } from 'interfaces/navigationApp.interface'

import { AccessGroups } from 'enums/AccessGroups'

import { RespiratoryTimelineModel } from 'config/common/baseModel/respiratory360/RespiratoryTimeline'

import { InfluenzaCommercialCustomPages } from './InfluenzaCommercialCustomPages'
import {
  InfluenzaCommercialMenu,
  InfluenzaCommercialSlugs,
} from './InfluenzaCommercialMenu'
import { InfluenzaCommercialPages } from './InfluenzaCommercialPages'

const influenzaCommercial: IApp<InfluenzaCommercialSlugs> = {
  name: 'Vaccines',
  slug: 'influenza-commercial',
  accessGroups: [AccessGroups.InfluenzaCommercial],
  endpoint: 'influenza-market-and-production',
  menu: InfluenzaCommercialMenu,
  pages: InfluenzaCommercialPages,
  baseModels: [RespiratoryTimelineModel],
  customPages: InfluenzaCommercialCustomPages,
  createdAt: new Date(2021, 10, 11),
}

export default influenzaCommercial
