import { useQuery } from '@tanstack/react-query'

import useAxios from '../useAxios'

export default function useOpenAPISchema() {
  const axios = useAxios()

  return useQuery(['apiSpec'], async () => {
    const res = await axios('/forecast/api/schema/?format=json')

    return res.data
  })
}
