import { Help, Logout, Menu as MenuIcon } from '@carbon/icons-react'
import {
  Box,
  Collapse,
  Menu,
  MenuButton,
  MenuList,
  IconButton,
} from '@chakra-ui/react'
import { NAV_HEIGHT } from 'constants/misc'
import { useContext, useState } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import { NavLink, NavLinkProps, useLocation } from 'react-router-dom'

import { useAppRoute } from 'routes/utils'

import { AuthContext } from 'contexts'

import { DropdownMenuLink } from 'components'

import useTracking from 'tracking/useTracking'

import useIsMobile from 'utils/useIsMobile'

export default function RightMenu() {
  const { appListUserCanAccess, userCanAccessTutorial } =
    useContext(AuthContext)
  const selectedApp = useAppRoute()
  const isMobile = useIsMobile()
  const [tracking] = useTracking()

  const location = useLocation()

  const [isOpen, setIsOpen] = useState(false)

  const appsWithTutorials = Object.values(appListUserCanAccess).filter(
    (app) => !!app.tutorial
  )
  const appsWithTutorialSlugs = appsWithTutorials.map((app) => app.slug)
  const showTutorials =
    appsWithTutorialSlugs.includes(selectedApp) && userCanAccessTutorial

  if (isMobile) {
    return (
      <OutsideClickHandler
        display='contents'
        onOutsideClick={() => {
          setIsOpen(false)
        }}
      >
        <IconButton
          icon={<MenuIcon size={20} />}
          aria-label='right-menu'
          display='flex'
          alignItems='center'
          overflow='hidden'
          color='white'
          height='100%'
          cursor='pointer'
          onClick={() => setIsOpen((x) => !x)}
          variant='ghost'
          _hover={{
            bg: 'none',
          }}
          _active={{
            bg: 'none',
          }}
          _focus={{
            boxShadow: 'none',
          }}
          ml={3}
          mr={6}
        />
        <Collapse
          in={isOpen}
          style={{
            position: 'absolute',
            left: 0,
            right: 0,
            top: NAV_HEIGHT,
            zIndex: 900,
          }}
          animateOpacity={false}
        >
          <Box left={0} right={0} bgColor='brand'>
            <MenuLink
              to={`/bookmarks?ref=${location.pathname}`}
              onClick={() => tracking.openBookmarks({})}
            >
              Bookmarks
            </MenuLink>
            <MenuLink
              to={`/settings?ref=${location.pathname}`}
              onClick={() => tracking.openSettings({})}
            >
              Settings
            </MenuLink>
            {showTutorials && (
              <MenuLink
                to={`/tutorial/${selectedApp}`}
                onClick={() => tracking.openTutorial({})}
              >
                Tutorials
              </MenuLink>
            )}
            <MenuLink to='/logout'>Logout</MenuLink>
          </Box>
        </Collapse>
      </OutsideClickHandler>
    )
  }

  return (
    <Menu size='sm'>
      <MenuButton
        icon={<MenuIcon size={20} />}
        aria-label='right-menu'
        display='flex'
        color='white'
        alignItems='center'
        variant='ghost'
        overflow='hidden'
        height='100%'
        onClick={() => setIsOpen((x) => !x)}
        as={IconButton}
        _active={{
          bg: 'none',
        }}
        _hover={{
          bg: 'none',
        }}
        _focus={{
          boxShadow: 'none',
        }}
        ml={4}
        mr={6}
      />
      <MenuList
        zIndex={9000000}
        shadow='lg'
        bg='white'
        border='1px solid'
        borderColor='gray.200'
        rounded='sm'
        width='300px'
        color='black'
      >
        {/* <DropdownMenuLink
          icon={Bookmark}
          href={`/bookmarks?ref=${location.pathname}`}
          onClick={() => tracking.openBookmarks({})}
        >
          Bookmarks
        </DropdownMenuLink> */}
        {/* <DropdownMenuLink
          icon={Settings}
          href={`/settings?ref=${location.pathname}`}
          onClick={() => tracking.openSettings({})}
        >
          Settings
        </DropdownMenuLink> */}
        {showTutorials && (
          <DropdownMenuLink
            icon={Help}
            href={`/tutorial/${selectedApp}`}
            onClick={() => tracking.openTutorial({})}
          >
            Tutorials
          </DropdownMenuLink>
        )}
        <DropdownMenuLink icon={Logout} href='/logout'>
          Logout
        </DropdownMenuLink>
      </MenuList>
    </Menu>
  )
}

const MenuLink: React.FC<React.PropsWithChildren<NavLinkProps>> = ({
  to,
  onClick,
  children,
  ...props
}) => {
  return (
    <NavLink to={to} onClick={onClick} {...props}>
      <Box
        as='span'
        px={4}
        py={2}
        display='block'
        fontSize='sm'
        fontWeight={500}
        cursor='pointer'
      >
        {children}
      </Box>
    </NavLink>
  )
}
