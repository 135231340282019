import { Grid } from '@chakra-ui/react'

import TabbedDisplay from 'modules/TabbedDisplay'

import { TableauEmbed } from 'components'

const EmergencyRiskDashboard = () => (
  <TabbedDisplay
    tabListWrapper={(props) => (
      <Grid templateColumns='repeat(4, 1fr)' gap={3} {...props} />
    )}
    tabs={[
      {
        heading: 'Avian Influenza Pandemic Emergence Risk',
        body: (
          <TableauEmbed
            path='/AvianInfluenza/Birdfludashboard'
            height={`calc(100vh - 200px)`}
          />
        ),
      },
    ]}
  />
)

export default EmergencyRiskDashboard
