import { IModel } from 'interfaces/model.interface'
import { ValueType } from 'interfaces/valueType.interface'

export type IDTreatments360NewsPressData =
  | 'id'
  | 'title'
  | 'diseases'
  | 'candidates'
  | 'designationA'
  | 'designationB'
  | 'type'
  | 'subType'
  | 'organisations'
  | 'publishedAt'
  | 'descriptionClean'
  | 'feedCategory'
  | 'indicationIds'
  | 'feedDescription'
  | 'pathogenTypes'

const allowedAirtables = ['ida_vaccines', 'ida_treatments', 'ida_all'] as const

export const IDTreatment360NewsAndPressReleasesModel: IModel<
  Record<IDTreatments360NewsPressData, string>,
  typeof allowedAirtables
> = {
  allowedAirtables,
  displayKey: 'title',
  name: 'News & Press Releases',
  entityName: 'Revenue',
  searchField: 'title',
  endpoint: 'rss-article',
  defaultSortKey: 'title',
  schema: {
    columns: [
      {
        key: 'pathogenTypes',
        label: 'Pathogen Types',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'title',
        label: 'Title',
        type: ValueType.URL,
        width: 400,
      },
      {
        key: 'indicationIds',
        label: 'Indications Id',
        type: ValueType.MULTI,
        width: 120,
      },
      {
        key: 'diseases',
        label: 'Diseases',
        type: ValueType.MULTI,
        width: 120,
      },
      {
        key: 'candidates',
        label: 'Candidate Name',
        type: ValueType.MULTI,
        width: 150,
      },
      {
        key: 'designationA',
        label: 'Vaccine or Treatment',
        type: ValueType.MULTI,
        width: 180,
      },
      {
        key: 'designationB',
        label: 'Candidate Category',
        type: ValueType.MULTI,
        width: 180,
      },
      {
        key: 'type',
        label: 'Candidate Type',
        type: ValueType.MULTI,
        width: 180,
      },
      {
        key: 'subType',
        label: 'Candidate Sub-Type',
        type: ValueType.MULTI,
        width: 180,
      },
      {
        key: 'organisations',
        label: 'Organisation',
        type: ValueType.MULTI,
        width: 250,
      },
      {
        key: 'publishedAt',
        label: 'Published At',
        type: ValueType.DATE,
        width: 150,
      },
      {
        key: 'descriptionClean',
        label: 'Description',
        type: ValueType.LONG,
        width: 300,
      },
      {
        key: 'feedCategory',
        label: 'Category',
        type: ValueType.MULTI,
        width: 120,
      },
      {
        key: 'feedDescription',
        label: 'Feed Source',
        type: ValueType.TEXT,
        width: 300,
      },
    ],
  },
}
