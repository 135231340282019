import { Download, Locked } from '@carbon/icons-react'
import {
  Box,
  ButtonProps,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Text,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Portal,
} from '@chakra-ui/react'
import { memo, useContext } from 'react'
import { ColumnInstance, TableState } from 'react-table'

import { useAppRoute } from 'routes/utils'

import { AuthContext } from 'contexts'

import useTracking from 'tracking/useTracking'

import { apps } from 'config/apps'

import Export from './export'

type PropTypes = {
  state: TableState
  columns: ColumnInstance<any>[]
  exportName: string
  label?: string
  buttonProps?: ButtonProps
}

const TableExport = memo(
  ({
    state,
    columns,
    exportName,
    label = 'Export',
    buttonProps,
  }: PropTypes) => {
    const { userIsBasicWithinApp } = useContext(AuthContext)
    const [tracking] = useTracking()
    const selectedApp = useAppRoute()

    const { isOpen, onOpen, onClose } = useDisclosure()

    if (userIsBasicWithinApp(apps[selectedApp])) {
      return <FeatureLocked />
    }

    return (
      <>
        <Button
          variant='clean'
          data-cy='table-export-button'
          onClick={onOpen}
          {...buttonProps}
        >
          <Box mr={1}>
            <Download size={16} />
          </Box>
          <Text fontSize='12px'>{label}</Text>
        </Button>
        <Modal size='lg' isCentered isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent color='black' data-cy='table-export-modal-content'>
            <ModalHeader>Export Table</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text fontSize='14px' lineHeight='22px' mb='24px'>
                Select a file format for your download.
              </Text>
              <Export
                onClose={onClose}
                state={state}
                columns={columns}
                exportName={exportName}
                exportDataCallback={() => {
                  tracking.downloadCsv({ file: exportName })
                  onClose()
                }}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      </>
    )
  }
)

const FeatureLocked = () => {
  return (
    <Popover placement='top' closeOnBlur>
      <PopoverTrigger>
        <Button color='gray2' variant='clean'>
          <Box mr={1}>
            <Locked size={16} />
          </Box>
          Export
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <Box bg='white' fontSize='sm' fontWeight={400}>
            <Box bg='gray.100' px={4} py={2} fontWeight={500}>
              This feature is locked
            </Box>
            <Box px={4} py={3} fontSize='sm'>
              Data exports are only available to paid subscription tiers. Please
              contact your account manager or email{' '}
              <Link
                href='mailto:support@airfinity.com'
                color='legacy-primary.500'
              >
                support@airfinity.com
              </Link>{' '}
              to unlock this feature.
            </Box>
          </Box>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}

export default TableExport
