import { Box } from '@chakra-ui/react'
import { Transition } from 'react-transition-group'
import { TransitionStatus } from 'react-transition-group/Transition'

interface SlideOverProps {
  show: boolean
  width?: any
  children?: React.ReactNode
  backdrop?: boolean
  handleClose?: any
  title?: string
}

type TransitionStyles = {
  [P in TransitionStatus]: React.CSSProperties
}

const SlideOver = (props: SlideOverProps) => {
  const { show, children, width = 900 } = props

  const defaultStyle = {
    transition: `all 300ms ease-in-out`,
    width: width + 'px',
    transform: 'translateX(200px)',
  }

  const transitionStyles: TransitionStyles = {
    entering: { opacity: 0 },
    entered: { transform: 'translateX(0px)', opacity: 1 },
    exiting: { transform: `translateX(${width}px)` },
    exited: { transform: `translateX(${width}px)` },
    unmounted: { opacity: 0 },
  }

  return (
    <Transition
      in={show}
      unmountOnExit={true}
      timeout={{ enter: 0, exit: 300 }}
    >
      {(state) => (
        <Box
          bg='white'
          opacity={0}
          width={width}
          top={0}
          height='100vh'
          position='fixed'
          right={0}
          shadow='sm'
          zIndex={100}
          display='flex'
          flexDirection='column'
          boxShadow='-1px 1px 10px rgba(0, 0, 0, 0.15)'
          style={{ ...defaultStyle, ...transitionStyles[state] }}
        >
          {children}
        </Box>
      )}
    </Transition>
  )
}

export default SlideOver
