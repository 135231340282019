import GenericTable from 'modules/Tables'

import { IModel } from 'interfaces/model.interface'
import {
  INavigationPages,
  ISingleNavigationPage,
} from 'interfaces/navigationPage.interface'

import { TreatmentsSlugs } from './TreatmentsMenu'
import {
  TreatmentClinicalTrialsModel,
  TreatmentMediaModel,
  TreatmentResultsApprovedCandidatesModel,
  TreatmentTweetsModel,
  TreatmentCandidatePapersModel,
  TreatmentApprovalsOrRejectionsDealsModelByCountry,
  TreatmentCandidateRankingAntiviralsModel,
  TreatmentCandidateRankingModel,
  TreatmentPressReleasesModel,
  TreatmentApprovalsProphylaxis,
  TreatmentApprovalsOrRejectionsDealsModelByTreatment,
  TreatmentCandidateRankingPassiveImmunotherapiesModel,
  TreatmentCandidateLongCovidModel,
  TreatmentResultsLongCovidApprovedCandidatesModel,
} from './TreatmentsModels'

type TreatmentNavigationPage<TModel extends IModel<any>> =
  ISingleNavigationPage<TreatmentsSlugs, TModel>

const Candidates: TreatmentNavigationPage<
  typeof TreatmentCandidateRankingModel
> = {
  key: 'Candidates',
  path: ['candidates', 'table'],
  component: GenericTable,
  model: { ...TreatmentCandidateRankingModel, name: 'All Candidates' },
  views: [
    {
      name: 'All',
      airtableName: 'api_treatments_all',
      airtableBase: 'idtreatments360',
    },
    {
      name: 'Antivirals',
      airtableName: 'api_treatments_antivirals',
      airtableBase: 'idtreatments360',
      overrideModel: TreatmentCandidateRankingAntiviralsModel,
    },
    {
      name: 'Passive Immunotherapies',
      airtableName: 'api_treatments_passive_immunotherapies',
      airtableBase: 'idtreatments360',
      overrideModel: TreatmentCandidateRankingPassiveImmunotherapiesModel,
    },
    {
      name: 'Anti Inflammatory',
      airtableName: 'api_treatments_anti_inflammatory',
      airtableBase: 'idtreatments360',
      overrideModel: {
        ...TreatmentCandidateRankingModel,
        name: 'Anti Inflammatory/Immunomodulatory',
      },
    },
    {
      name: 'Other Candidates',
      airtableName: 'api_treatments_other',
      airtableBase: 'idtreatments360',
      overrideModel: {
        ...TreatmentCandidateRankingModel,
        name: 'Other Candidates',
      },
    },
    {
      name: 'Long Covid',
      airtableName: 'FE Long COVID Candidates table - Correct columns',
      airtableBase: 'idtreatments360',
      overrideModel: {
        ...TreatmentCandidateLongCovidModel,
        name: 'Long Covid',
      },
    },
  ],
}

const CandidatePapers: TreatmentNavigationPage<
  typeof TreatmentCandidatePapersModel
> = {
  key: 'Publications',
  path: ['new-science', 'publications'],
  component: GenericTable,
  model: TreatmentCandidatePapersModel,
  views: [
    {
      name: 'Default',
      airtableName: 'api_app_treatments',
      airtableBase: 'idtreatments360',
    },
  ],
}

const ClinicalTrials: TreatmentNavigationPage<
  typeof TreatmentClinicalTrialsModel
> = {
  key: 'ClinicalTrials',
  path: ['trials', 'table'],
  component: GenericTable,
  model: TreatmentClinicalTrialsModel,
  views: [
    {
      name: 'Default',
      airtableName: 'api_app_treatments',
      airtableBase: 'idtreatments360',
    },
    {
      name: 'Long Covid',
      airtableName: 'FE Long COVID Trials table',
      airtableBase: 'idtreatments360',
    },
  ],
}

const ResultsApprovedCandidates: TreatmentNavigationPage<
  typeof TreatmentResultsApprovedCandidatesModel
> = {
  key: 'ResultsApprovedCandidates',
  path: ['results', 'table'],
  component: GenericTable,
  model: TreatmentResultsApprovedCandidatesModel,
  views: [
    {
      name: 'Default',
      airtableName: 'FE view for COVID trial results',
      airtableBase: 'idtreatments360',
    },
    {
      name: 'Long Covid',
      airtableName: 'FE view for Long COVID results',
      overrideModel: TreatmentResultsLongCovidApprovedCandidatesModel,
      airtableBase: 'idtreatments360',
    },
  ],
}

const ApprovalsOrRejectionsByTreatment: TreatmentNavigationPage<
  typeof TreatmentApprovalsOrRejectionsDealsModelByTreatment
> = {
  key: 'ApprovalsOrRejectionsByTreatment',
  path: ['approvals', 'by-treatment'],
  model: TreatmentApprovalsOrRejectionsDealsModelByTreatment,
  component: GenericTable,
  views: [
    {
      name: 'All',
      airtableName: 'API-treatments-approvals',
      airtableBase: 'idtreatments360',
    },
    {
      name: 'Prophylaxis',
      airtableName: 'API - C-19 Tx Approvals Prophylaxis',
      airtableBase: 'idtreatments360',
      overrideModel: TreatmentApprovalsProphylaxis,
    },
    {
      name: 'Outpatient',
      airtableName: 'API - C-19 Tx Approvals Outpatient',
      airtableBase: 'idtreatments360',
      overrideModel: TreatmentApprovalsProphylaxis,
    },
    {
      name: 'Inpatient',
      airtableName: 'API - C-19 Tx Approvals Inpatient',
      airtableBase: 'idtreatments360',
      overrideModel: TreatmentApprovalsProphylaxis,
    },
  ],
}

const ApprovalsOrRejectionsByCountry: TreatmentNavigationPage<
  typeof TreatmentApprovalsOrRejectionsDealsModelByCountry
> = {
  key: 'ApprovalsOrRejectionsByCountry',
  path: ['approvals', 'by-country'],
  model: TreatmentApprovalsOrRejectionsDealsModelByCountry,
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableName: 'API-treatments-approvals',
      airtableBase: 'idtreatments360',
    },
  ],
}

const PressReleases: TreatmentNavigationPage<
  typeof TreatmentPressReleasesModel
> = {
  key: 'PressReleases',
  model: TreatmentPressReleasesModel,
  path: null,
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableName: 'api_app_treatments',
      airtableBase: 'idtreatments360',
    },
  ],
}

const Media: TreatmentNavigationPage<typeof TreatmentMediaModel> = {
  key: 'Media',
  model: TreatmentMediaModel,
  path: null,
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableName: 'api_app_treatments',
      airtableBase: 'idtreatments360',
    },
  ],
}

const Tweets: TreatmentNavigationPage<typeof TreatmentTweetsModel> = {
  key: 'Tweets',
  path: null,
  component: GenericTable,
  model: TreatmentTweetsModel,
  views: [
    {
      name: 'Default',
      airtableName: 'api_app_treatments',
      airtableBase: 'idtreatments360',
    },
  ],
}

export const TreatmentsPages: INavigationPages<TreatmentsSlugs> = {
  Candidates,
  CandidatePapers,
  ClinicalTrials,
  ResultsApprovedCandidates,
  ApprovalsOrRejectionsByTreatment,
  ApprovalsOrRejectionsByCountry,
  PressReleases,
  Media,
  Tweets,
}
